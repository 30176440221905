import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BackgroundedLabel from '../../../features/common/BackgroundedLabel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CardTitle from '../../../features/common/CardTitle';
import CircularLogo from '../../../features/common/icons/CircularLogo';
import { CommunicationChart } from '../../../features/accountintelligence/CommunicationChart';
import { HStack } from '../../../features/common/structures/Stacks';
import LxCard from '../../../features/common/card/LxCard';
import { lxColors } from '../../../utils/LxColors';
import { capitalize, Stack } from '@mui/material';
import { ThirdPartyInvolved } from './ThirdPartiesInvolvedSectionFetch';

const ThirdPartyInvolvedComponent = ({
  data,
}: {
  data: ThirdPartyInvolved;
}) => {
  function getCommunicationChartData() {
    let dataArray: { x: number; y: number }[] = [];
    let months = data.communicationsByMonth;
    let lastMonthNum = data.communicationsByMonthLastMonth;
    let i = months.length - 1;
    let lastReadMonth = lastMonthNum;
    while (i >= 0) {
      let newObj = { x: lastReadMonth, y: months[i] };
      --lastReadMonth;
      if (lastReadMonth <= 0) {
        lastReadMonth = 12;
      }
      dataArray.unshift(newObj);
      --i;
    }
    return dataArray;
  }

  const { sparkLine } = lxColors;
  return (
    <LxCard>
      <Stack gap={3.5}>
        <HStack justifyContent={'space-between'}>
          <CardTitle
            title={capitalize(data.thirdPartyName)}
            subtitle={data.thirdPartyDomain}
            byline={''}
          />
          <CircularLogo imageUrl={data.thirdPartyIconUrl} />
        </HStack>
        <HStack sx={{ gap: 6 }}>
          <Stack gap={1.5}>
            <BackgroundedLabel
              label={data.numEmails + ' Emails'}
              Icon={AlternateEmailIcon}
            />
            <BackgroundedLabel
              label={data.numMeetings + ' Meetings'}
              Icon={CalendarTodayIcon}
            />
          </Stack>
          <CommunicationChart
            lineColor={sparkLine.line.secondary.color}
            backgroundColor={sparkLine.background.secondary.color}
            data={getCommunicationChartData()}
          />
        </HStack>
      </Stack>
    </LxCard>
  );
};

export default ThirdPartyInvolvedComponent;
