import Header from '../../features/header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import MenuBar from '../../components/menubar/MenuBar';
import { AppPages } from '../../routes/Pages';

const AppRoot = (): JSX.Element => {
  const navigate = useNavigate();

  const menuButtons = AppPages.map((appPage) => {
    return {
      ...appPage,
      onClick: () => navigate(appPage.path),
    };
  });

  return (
    <Container maxWidth={'lg'} sx={{ height: 1 }}>
      <Header />
      <MenuBar menuButtons={menuButtons} />
      <Outlet />
    </Container>
  );
};
export default AppRoot;
