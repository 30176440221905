import { Box, Stack, Typography } from '@mui/material';
import { HStack } from './structures/Stacks';
import { lxColors } from '../../utils/LxColors';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

type RatingSectionProps = {
  title: string;
  trendIcon: typeof SvgIcon;
  trendText: string;
  ratings: JSX.Element;
  color?: 'orange' | 'green';
};

const RatingSection = ({
  title,
  trendIcon: TrendIconA,
  trendText,
  ratings,
  color = 'orange',
}: RatingSectionProps) => {
  return (
    <Stack spacing={0}>
      <Typography variant={'h3'} sx={{ fontSize: 14 }}>
        {title}
      </Typography>
      <Box pt={1}>{ratings}</Box>
      <HStack spacing={0.5}>
        <TrendIconA
          sx={{
            fontSize: 17,
            color:
              color === 'orange'
                ? lxColors.icons.defaultStyle.color
                : lxColors.icons.up.color,
          }}
        />
        <Typography
          sx={{
            color:
              color === 'orange'
                ? lxColors.font.iconDescription.color
                : lxColors.icons.up.color,
          }}
        >
          {trendText}
        </Typography>
      </HStack>
    </Stack>
  );
};
export default RatingSection;
