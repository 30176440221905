import MetricsTable from '../../metricstable/MetricsTable';
import useConvDataTableData from './ConvDataTableData';

type ConvDataTableProps = {
  orgId: number;
  wfTenantId: string;
};

const ConvDataTable = ({
  orgId,
  wfTenantId,
}: ConvDataTableProps): JSX.Element => {
  const {
    colDef,
    data,
    isLoading,
    page,
    setPage,
    pageSize,
    setPageSize,
    isError,
    errorMessage,
  } = useConvDataTableData(orgId, wfTenantId);

  return (
    <MetricsTable
      colDef={colDef}
      loading={isLoading}
      rows={data}
      rowCount={data?.length || 0}
      error={isError}
      errorMessage={errorMessage}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default ConvDataTable;
