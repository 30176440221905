import CompanyLogoIcon from '../assets/companyLogo.svg';
import CompanyHeader from '../components/companyheader/CompanyHeader';
import DocumentSection from '../components/documentsection/DocumentSection';
import UpcomingTasks from '../components/upcomingtasks/UpcomingTasks';
import InternalContacts from '../components/InternalContacts';
import TimelineSection from '../components/TimelineSection';
import moment from 'moment';
import { Platforms } from '../features/common/Constants';

const companyHeaderProps = {
  companyName: 'Wonka Industries Inc.',
  companyLogo: CompanyLogoIcon,
  companyWebsite: 'wonkaindustries.com',
  churnRisk: 'Low',
  gainSight: true,
  contacts: [
    {
      name: 'Jane',
      avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
    },
    {
      name: 'Jane',
      avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
    },
  ],
};

const columns = [
  {
    title: 'Upcoming Meetings',
    tasks: [
      {
        date: moment().subtract(10, 'days'),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
    ],
  },
  {
    title: 'Open Tasks',
    tasks: [
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
    ],
  },
  {
    title: 'Unreplied Emails',
    tasks: [
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
      {
        date: moment(),
        platform: Platforms.OneDrive,
        avatar: {
          name: 'Jane',
          avatarSrc: 'https://avatars.githubusercontent.com/u/120482615?v=4',
        },
        description:
          'Monthly meeting to discuss project progress and monthly milestones.',
      },
    ],
  },
];

const CustomerHandover = () => {
  return (
    <>
      <CompanyHeader {...companyHeaderProps} />
      <TimelineSection />
      <DocumentSection />
      <UpcomingTasks columns={columns} />
      <InternalContacts />
    </>
  );
};
export default CustomerHandover;
