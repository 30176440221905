import { useState } from 'react';
import { Org, OrgSettings } from '../../utils/types/Entities';
import { OrgSchema, OrgSettingsSchema } from '../../utils/data/jsonSchema';
import {
  AdminTable,
  GetAdminTableRowsProps,
  useAlertMessage,
} from './components/AdminTable';
import { AdminOrgsGridColumns } from './components/AdminOrgsData';
import { GridSortModel } from '@mui/x-data-grid';
import { rowData } from '../../components/metricstable/MetricsTableTypes';
import EditOrgDialog from './components/dialog/EditOrgDialog';
import useFetch from '../../utils/data/useFetch';
import { postData } from '../../utils/data/fetchUtils';
import EditOrgSettingsDialog from './components/dialog/EditOrgSettingsDialog';

const ADMIN_ORGS_HEADING = 'All Orgs';
const ORGS_URL = '/api/debug/orgcrud/orgs';
const ORG_SETTINGS_URL = '/api/debug/orgcrud/orgsettings';
const ORGS_SCHEMA_URL = '/api/debug/orgcrud/orgs/schema';
const ORG_SETTINGS_SCHEMA_URL = '/api/debug/orgcrud/orgsettings/schema';
const DEFAULT_SORT: GridSortModel = [{ field: 'orgid', sort: 'asc' }];

const getOrgRows = ({
  data: orgs,
  filterBy,
}: GetAdminTableRowsProps<Org>): rowData[] => {
  return orgs
    .map((orgs) => {
      return {
        ...orgs,
        id: orgs.orgid ?? 0,
      };
    })
    .filter(
      (org) =>
        filterBy.trim() === '' ||
        org.orgid === Number.parseInt(filterBy) ||
        org.orghandle.indexOf(filterBy) !== -1
    );
};

const AdminOrgs = () => {
  const [org, setOrg] = useState<Org>();
  const [isAddingOrg, setAddingOrg] = useState(false);
  const [isEditingOrg, setEditingOrg] = useState(false);
  const [isEditingOrgSettings, setEditingOrgSettings] = useState(false);
  const [numSaves, setNumSaves] = useState(0);
  const {
    alertMessage,
    alertMessageType,
    alertMessageHideDelay,
    showAlertMessage,
    clearAlertMessage,
  } = useAlertMessage();

  const { data: orgSchema } = useFetch<OrgSchema>(ORGS_SCHEMA_URL);
  const { data: orgSettingsSchema } = useFetch<OrgSettingsSchema>(
    ORG_SETTINGS_SCHEMA_URL
  );
  const { data: orgSettings, isLoading: isOrgSettingsLoading } =
    useFetch<OrgSettings>(`${ORG_SETTINGS_URL}/${org?.orgid ?? 0}`, [org]);

  const onAddOrg = () => {
    clearAlertMessage();
    setAddingOrg(true);
  };

  const onEditOrg = (org: Org) => {
    clearAlertMessage();
    setOrg(org);
    setEditingOrg(true);
  };

  const onEditOrgSettings = (org: Org) => {
    clearAlertMessage();
    setOrg(org);
    setEditingOrgSettings(true);
  };

  const saveOrg = async (org: Org) => {
    const response = await postData(ORGS_URL, org);

    if (response.ok) {
      showAlertMessage(`Saved org ${org.orghandle}.`, 'success');
      setNumSaves(numSaves + 1);
    } else {
      let error = `Error ${response.status} saving org ${org.orghandle}. `;

      switch (response.status) {
        case 400:
          error += 'Check that all required fields are filled.';
          break;
        case 409:
          error += `Org with same orghandle already exists.`;
          break;
        case 500:
          error += 'Server error occurred.';
          break;
        default:
          break;
      }

      throw error;
    }
  };

  const saveOrgSettings = async (orgSettings: OrgSettings) => {
    if (org) {
      const response = await postData(
        `${ORG_SETTINGS_URL}/${org.orgid}`,
        orgSettings,
        'PUT'
      );

      if (response.ok) {
        showAlertMessage(`Saved org ${org.orghandle} settings.`, 'success');
        setNumSaves(numSaves + 1);
      } else {
        let error = `Error ${response.status} saving org ${org.orghandle} settings. `;

        switch (response.status) {
          case 400:
            error += 'Check that all required fields are filled.';
            break;
          case 500:
            error += 'Server error occurred.';
            break;
          default:
            break;
        }

        throw error;
      }
    }
  };

  return (
    <>
      <AdminTable
        heading={ADMIN_ORGS_HEADING}
        dataUrl={ORGS_URL}
        columns={AdminOrgsGridColumns({
          refresh: numSaves,
          onEdit: onEditOrg,
          onEditSettings: onEditOrgSettings,
        })}
        getRows={getOrgRows}
        refresh={numSaves}
        onAdd={onAddOrg}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        alertMessageHideDelay={alertMessageHideDelay}
        initialSort={DEFAULT_SORT}
        rowHeight={120}
      />
      {isAddingOrg && (
        <EditOrgDialog
          mode="add"
          orgSchema={orgSchema}
          onSave={saveOrg}
          onClose={() => setAddingOrg(false)}
        />
      )}
      {isEditingOrg && (
        <EditOrgDialog
          mode="edit"
          org={org}
          orgSchema={orgSchema}
          onSave={saveOrg}
          onClose={() => setEditingOrg(false)}
        />
      )}
      {isEditingOrgSettings && !isOrgSettingsLoading && (
        <EditOrgSettingsDialog
          orgSettings={orgSettings}
          orgSettingsSchema={orgSettingsSchema}
          onSave={saveOrgSettings}
          onClose={() => setEditingOrgSettings(false)}
        />
      )}
    </>
  );
};

export default AdminOrgs;
