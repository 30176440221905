import { Link } from 'react-router-dom';

const Dashboard = (): JSX.Element => {
  return (
    <div className="dashboard">
      <div>Dashboard Page</div>
      <div>
        <Link to={'/about'}>Go to About</Link>
      </div>
    </div>
  );
};
export default Dashboard;
