import MetricsTable from '../../metricstable/MetricsTable';
import useMetaDataTableData, { MetaDataTypes } from './MetaDataTableData';

type MetaDataTableProps = {
  orgId: number;
  metaDataType: MetaDataTypes;
};

const MetaDataTable = ({
  orgId,
  metaDataType,
}: MetaDataTableProps): JSX.Element => {
  const {
    colDef,
    data,
    isLoading,
    page,
    setPage,
    pageSize,
    setPageSize,
    isError,
    errorMessage,
  } = useMetaDataTableData(orgId, metaDataType);

  return (
    <MetricsTable
      colDef={colDef}
      loading={isLoading}
      rows={data}
      rowCount={data?.length || 0}
      error={isError}
      errorMessage={errorMessage}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default MetaDataTable;
