import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

type IconAndTextType = {
  icon: ReactNode;
  textElement: ReactNode;
};

type AlignedIconsAndTextProps = {
  iconAndTextArray: IconAndTextType[];
  sx?: SxProps<Theme>;
};

const AlignedIconsAndText = ({
  iconAndTextArray,
  sx = {},
}: AlignedIconsAndTextProps): JSX.Element => {
  return (
    <Grid container direction={'row'} sx={{ rowGap: 1, width: 0.5, ...sx }}>
      {iconAndTextArray.map((iconAndText, index) => {
        return (
          <Grid
            item
            container
            xs={12}
            gap={2}
            key={index}
            sx={{ width: 'auto', flexBasis: 0, flexGrow: 0 }}
          >
            <Grid
              item
              xs={1.5}
              container
              justifyContent={'center'}
              alignItems={'flex-start'}
            >
              {iconAndText.icon}
            </Grid>
            <Grid item xs container>
              {iconAndText.textElement}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default AlignedIconsAndText;
