import SectionHeader from '../../../features/common/SectionHeader';
import { HStack } from '../../../features/common/structures/Stacks';
import AccountIntelligencePeopleConnectionBox from '../../../features/accountintelligence/AccountIntelligencePeopleConnectionBox';
import { useEffect, useState } from 'react';
import {
  PeopleAndConnection,
  peopleAndConnectionSectionData,
} from './PeopleAndConnectionsSectionFetch';

type PeopleAndConnectionsSectionProps = {};

const PeopleAndConnectionsSection = (
  props: PeopleAndConnectionsSectionProps
) => {
  const [peopleAndConnections, setPeopleAndConnections] = useState<
    PeopleAndConnection[]
  >([]);

  useEffect(() => {
    peopleAndConnectionSectionData().then((res) => {
      setPeopleAndConnections(res);
    });
  }, []);

  return (
    <>
      <SectionHeader sectionTitle={'People And Connections'} side={<></>} />
      <HStack justifyContent={'space-between'}>
        {peopleAndConnections.map((peopleAndConnection, idx) => {
          return (
            <AccountIntelligencePeopleConnectionBox
              peopleAndConnectionBox={peopleAndConnection}
              key={`pplNCnt-${idx}`}
            />
          );
        })}
      </HStack>
    </>
  );
};
export default PeopleAndConnectionsSection;
