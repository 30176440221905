import { Box, Grid, Stack, Typography } from '@mui/material';
import PersonCheckingBoard from '../../assets/personCheckingBoard.svg';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import { WhiteLeftArrow } from '../../features/common/icons/Arrows';
import M$ftLogo from '../../assets/msftIcon.svg';
import CalendarIcon from '../../assets/calendarIcon.png';
import OutlookIcon from '../../assets/outlookIcon.png';
import SignUpInfoBox from './common/SignUpInfoBox';
import SignUpSubTitle from './common/SignUpSubTitle';
import SignUpTitle from './common/SignUpTitle';
import AlignedIconsAndText from './common/AlignedIconsAndText';
import { lxColors } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

const alignedIconsAndTextProps = {
  iconAndTextArray: [
    {
      icon: <Box component={'img'} src={M$ftLogo} />,
      textElement: (
        <Typography
          fontSize={28}
          fontFamily={lxFonts.DATA}
          fontWeight={'bold'}
          color={lxColors.font.signup.grey}
        >
          Microsoft 365
        </Typography>
      ),
    },
    {
      icon: (
        <Box
          component={'img'}
          src={OutlookIcon}
          sx={{ width: 30, height: 30 }}
        />
      ),
      textElement: (
        <Typography
          fontFamily={lxFonts.DATA}
          color={lxColors.font.signup.orange}
        >
          Email Address
        </Typography>
      ),
    },
    {
      icon: (
        <Box
          component={'img'}
          src={CalendarIcon}
          sx={{ width: 30, height: 30 }}
        />
      ),
      textElement: (
        <Typography
          fontFamily={lxFonts.DATA}
          color={lxColors.font.signup.orange}
        >
          Calendar
        </Typography>
      ),
    },
  ],
};

const SignUpEmail = (): JSX.Element => {
  return (
    <Grid
      container
      direction={'row'}
      height={1}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        item
        xs={6}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box component={'img'} src={PersonCheckingBoard} />
      </Grid>
      <Grid item xs={6}>
        <Stack
          width={1}
          height={1}
          direction={'column'}
          spacing={5}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <SignUpTitle text={'Authorize'} />
          <SignUpSubTitle text={"Great! Let's connect your email"} />
          <SignUpInfoBox text={'We see you are using Microsoft 365'} />
          <AlignedIconsAndText {...alignedIconsAndTextProps} />
          <OrangeButton
            prefixIcon={<WhiteLeftArrow />}
            text={'One-Click Authorize'}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SignUpEmail;
