import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import TabPanel from '../../../../../components/TabPanel';
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { OrgSettingsTabPanelProps } from './OrgSettings';
import {
  DefaultColumnStyle,
  MetricsTableCol,
  rowData,
} from '../../../../../components/metricstable/MetricsTableTypes';
import {
  HStack,
  VStack,
} from '../../../../../features/common/structures/Stacks';
import MetricsTable from '../../../../../components/metricstable/MetricsTable';
import { LxTypographyTypes } from '../../../../../components/lxTypography/lxTypographyStyles';
import LxTypography from '../../../../../components/lxTypography/LxTypography';
import LxChip from '../../../../../features/common/chips/LxChip';
import { Actor, ActorUuidRoleMap } from '../../../../../utils/types/Entities';
import useFetch from '../../../../../utils/data/useFetch';
import { getUrl } from '../../../../../utils/data/fetchUtils';

const ACTOR_BY_EMAIL_URL = '/api/debug/actor';
const ACTOR_BY_UUID_URL = '/api/debug/orgcrud/actors';
const ROLES = ['VIEWER', 'EDITOR', 'MANAGER', 'ADMIN'];

const ActorsGridColumn = ({ actorUuid }: { actorUuid: string }) => {
  const { data: actor, isLoading } = useFetch<Actor>(
    `${ACTOR_BY_UUID_URL}/${actorUuid}`
  );

  return !isLoading ? (
    <LxTypography variant={LxTypographyTypes.DATA_1}>
      {actor?.actorEmail ?? ''}
    </LxTypography>
  ) : (
    <>
      <LxTypography variant={LxTypographyTypes.DATA_1}>
        Loading actor email...
      </LxTypography>
      <CircularProgress size={15} />
    </>
  );
};

type RolesColumnsProps = {
  removeActorRole: (actorUuid: string, roleName: string) => any;
  removeAllActorRoles: (actorUuid: string) => any;
};

const RolesColumns = ({
  removeActorRole,
  removeAllActorRoles,
}: RolesColumnsProps): MetricsTableCol[] => [
  {
    ...DefaultColumnStyle,
    headerName: 'Actor',
    field: 'actorEmail',
    filterable: false,
    hideable: false,
    sortable: true,
    showHover: false,
    renderCell: ({ row: actorRoles }: { row: ActorRolesRow }) => {
      return <ActorsGridColumn actorUuid={actorRoles.actorUuid} />;
    },
  },
  {
    ...DefaultColumnStyle,
    headerName: 'Roles',
    field: 'roles',
    filterable: false,
    hideable: false,
    sortable: false,
    showHover: false,
    renderCell: ({ row: actorRoles }: { row: ActorRolesRow }) => {
      return (
        <HStack flexWrap="wrap" rowGap={'0.05em'}>
          {actorRoles.roleNames.map((roleName) => (
            <HStack>
              <LxChip
                key={roleName}
                variant={'primary'}
                label={roleName}
                size={'medium'}
                filled
              />
              <IconButton
                onClick={() => removeActorRole(actorRoles.actorUuid, roleName)}
                size="small"
                color="error"
              >
                <RemoveIcon />
              </IconButton>
            </HStack>
          ))}
        </HStack>
      );
    },
  },
  {
    ...DefaultColumnStyle,
    field: 'edit',
    filterable: false,
    hideable: false,
    sortable: false,
    showHover: false,
    renderHeader: () => <></>,
    renderCell: ({ row: actorRoles }: { row: ActorRolesRow }) => {
      return (
        <VStack alignItems="center" fullWidth>
          <Button
            onClick={() => removeAllActorRoles(actorRoles.actorUuid)}
            variant="contained"
          >
            Remove
          </Button>
        </VStack>
      );
    },
    maxWidth: 150,
  },
];

/**
 * Convert a map from actor UUID to list of roles into a list of rowData
 * for display in MetricsTable.
 */
const getActorRolesRows = (
  actorRolesMap: ActorUuidRoleMap
): ActorRolesRow[] => {
  let rows: ActorRolesRow[] = [];
  for (let [actorUuid, roles] of Object.entries(actorRolesMap)) {
    rows.push({
      id: actorUuid,
      actorUuid,
      roleNames: roles,
    });
  }
  return rows;
};

type ActorRolesRow = {
  actorUuid: string;
  actorEmail?: string;
  roleNames: string[];
} & rowData;

/**
 * Tab for editing actor roles map property of SettingsJson.
 */
const OrgSettingsRolesTabPanel = ({
  orgSettings,
  orgSettingsSchema,
  tabIndex,
  tabIndexValue,
  onChange,
}: OrgSettingsTabPanelProps) => {
  const [newRoleActorEmail, setNewRoleActorEmail] = useState('');
  const [newRole, setNewRole] = useState(ROLES[0]);
  const [actorRolesMap, setActorRolesMap] = useState<ActorUuidRoleMap>({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (orgSettings) {
      setActorRolesMap(orgSettings?.actorUuidToRoles ?? {});
    }
  }, [orgSettings]);

  const onActorRolesMapChange = (newActorRolesMap: ActorUuidRoleMap) => {
    setActorRolesMap(newActorRolesMap);
    onChange(
      {
        actorUuidToRoles: newActorRolesMap,
      },
      true
    );
  };

  const addActorRole = async (actorEmail: string, roleName: string) => {
    setErrorMessage('');
    try {
      const actor = await getUrl<Actor>(
        `${ACTOR_BY_EMAIL_URL}?email=${actorEmail}`
      );
      if (actor) {
        const newRoles = actorRolesMap[actor.actorId] ?? [];
        newRoles.includes(roleName) || newRoles.push(roleName);
        const actorRoles: ActorUuidRoleMap = {};
        actorRoles[actor.actorId] = newRoles;
        onActorRolesMapChange(Object.assign({}, actorRolesMap, actorRoles));
      } else {
        setErrorMessage(`Actor ${actorEmail} not found.`);
      }
    } catch (error) {
      setErrorMessage(`Error retrieving actor ${actorEmail}: ${error}`);
    }
  };

  const removeActorRole = (actorUuid: string, roleName: string) => {
    const newRoles = (actorRolesMap[actorUuid] ?? []).filter(
      (role) => role !== roleName
    );
    const actorRoles: ActorUuidRoleMap = {};
    if (newRoles.length > 0) {
      actorRoles[actorUuid] = newRoles.filter((role) => role !== roleName);
      onActorRolesMapChange(Object.assign({}, actorRolesMap, actorRoles));
    } else {
      removeAllActorRoles(actorUuid);
    }
  };

  const removeAllActorRoles = (actorUuid: string) => {
    const newActorRolesMap = Object.assign({}, actorRolesMap);
    delete newActorRolesMap[actorUuid];
    onActorRolesMapChange(newActorRolesMap);
  };

  return (
    <TabPanel index={tabIndex} value={tabIndexValue}>
      <VStack alignItems={'flex-start'} sx={{ paddingTop: '1em' }} fullWidth>
        {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <HStack fullWidth>
          <TextField
            label={'Actor'}
            value={newRoleActorEmail}
            onChange={(e) => setNewRoleActorEmail(e.target.value)}
          />
          <Select
            label={'Role'}
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          >
            {ROLES.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant={'contained'}
            onClick={() => {
              const email = newRoleActorEmail.trim();
              email !== '' && addActorRole(email, newRole);
            }}
          >
            Add
            <AddIcon />
          </Button>
        </HStack>
        <MetricsTable
          colDef={RolesColumns({ removeActorRole, removeAllActorRoles })}
          rows={getActorRolesRows(actorRolesMap)}
          rowCount={getActorRolesRows(actorRolesMap).length}
          // initialSort={initialSort}
          rowHeight={75}
          loading={!orgSettings || !orgSettingsSchema}
          error={false}
          errorMessage={''}
          containerSx={{ width: '100%' }}
          gridSx={{ width: '100%' }}
        />
      </VStack>
    </TabPanel>
  );
};

export default OrgSettingsRolesTabPanel;
