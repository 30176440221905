export async function getUrl<T>(url: string) {
  const response = await fetchUrl(url, 'GET');
  if (response.ok) {
    return (await response.json()) as T;
  } else if (response.status !== 404) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
}

export async function postUrl(url: string, method: 'POST' | 'PUT' = 'POST') {
  return fetchUrl(url, method);
}

async function fetchUrl(url: string, method: string) {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
    },
  });
  return response;
}

export async function postData(
  url: string,
  data: object,
  method: 'POST' | 'PUT' = 'POST'
) {
  const json = JSON.stringify(data);
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: json,
  });
  return response;
}
