import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';

export type UnstyledButtonProps = {
  prefixIcon?: ReactNode; // so that the icon can be styled outside the component, e.g. with color and dimensions
  text?: string;
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  children?: ReactNode;
};

const UnstyledButton = ({
  prefixIcon,
  text,
  onClick = () => {},
  children,
  color,
  disabled = false,
  sx,
}: UnstyledButtonProps): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        p: '0 !important',
        color,
        ...sx,
      }}
    >
      {prefixIcon && prefixIcon}
      {text && (
        <LxTypography variant={LxTypographyTypes.DATA_DESCRIPTION}>
          {text}
        </LxTypography>
      )}
      {children}
    </Button>
  );
};

export default UnstyledButton;
