const apiUrlPrefix = '/api/accountintelligence/discussionthreads';

export interface LxRESTAPIReturnType<T> {
  data: T;
  errors: Array<{ message: string }>;
}

export async function lxRESTAPIFetch<R>(
  apiUrl: string,
  init: { method: string; headers?: any; body?: any } | null,
  _: R[]
): Promise<R> {
  let response = null;
  if (init) {
    response = await fetch(apiUrl, init);
  } else {
    response = await fetch(apiUrl);
  }
  const { data, errors }: LxRESTAPIReturnType<R> = await response.json();
  if (response.ok && data) {
    return data;
  }
  return Promise.reject(new Error(errors ? errors.join(', ') : ''));
  // sample init{} json below:
  // let fetchInit = {
  //   method: 'GET',
  //   headers: {
  //     //'content-type': 'application/json;charset=UTF-8',
  //   },
  //   // body: JSON.stringify({}),
  // };
}

interface Participant {
  employeeUuid: string;
  loudness: 1 | 2 | 3 | 4 | 5;
}

export interface DiscussionThreadHighAndLowLights {
  threadDescription: string;
  conversationTrendPercent: number;
  sentimentNegativePercent: number;
  recencyEpochMillis: number;
  positiveSentimentExcerptEpochMillis: number;
  positiveSentimentExcerpt: string;
  negativeSentimentExcerptEpochMillis: number;
  negativeSentimentExcerpt: string;
  participants: Participant[];
}

export interface AccountIntelligenceEmployeeInfo {
  employeeUuid: string;
  name: string;
  email: string;
  publicImageUrl: string;
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export async function discussionThreadSectionData(): Promise<
  DiscussionThreadHighAndLowLights[]
> {
  let apiUrl = `${apiUrlPrefix}`;
  return lxRESTAPIFetch(
    apiUrl,
    null,
    [] as DiscussionThreadHighAndLowLights[][]
  );
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const employeeInfoUrlPrefix = '/api/accountintelligence/employeeuuidInfo';
export async function employeeInfo(
  employeeUuid: string
): Promise<AccountIntelligenceEmployeeInfo> {
  let apiUrl = `${employeeInfoUrlPrefix}/${employeeUuid}`;
  return lxRESTAPIFetch(apiUrl, null, [] as AccountIntelligenceEmployeeInfo[]);
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export default discussionThreadSectionData;
