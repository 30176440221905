import { Stack, Typography } from '@mui/material';

type CardTitleProps = {
  subtitle: string;
  title: string;
  byline: string;
};

/**
 * Used in Card, generally used display headers
 */
const CardTitle = ({ title, subtitle, byline }: CardTitleProps) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant={'h3'}>{title}</Typography>
      <Typography variant={'h4'}>{subtitle}</Typography>
      <Typography variant={'h4'}>{byline}</Typography>
    </Stack>
  );
};
export default CardTitle;
