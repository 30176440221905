import { IconButton, IconButtonProps } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ArrowDownButton = (props: IconButtonProps) => {
  return (
    <IconButton
      color={'info'}
      sx={{
        ml: 'auto !important',
        backgroundColor: '#E6F7FF',
        borderRadius: '100%',
      }}
      {...props}
    >
      <KeyboardArrowDownIcon />
    </IconButton>
  );
};

export default ArrowDownButton;
