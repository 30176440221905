import { Box, ClickAwayListener, Grow, Popper } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';
import { ReactNode } from 'react';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';

type CustomPopperProps = {
  anchorEl: HTMLElement | null;
  children: ReactNode;
  onClickAway: () => void;
  placement?: PopperPlacementType;
};

const CustomPopper = ({
  anchorEl,
  children,
  onClickAway,
  placement = 'left-start',
}: CustomPopperProps) => {
  const open = Boolean(anchorEl);
  const id = open ? 'custom-popper' : undefined;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      sx={{
        zIndex: 999,
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickAway}>
          <Grow in={open} {...TransitionProps}>
            <Box
              sx={{
                padding: 2,
                border: 3,
                borderRadius: 2,
                borderColor: lxColors.popper.border.color,
                bgcolor: 'background.paper',
              }}
            >
              {children}
            </Box>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default CustomPopper;
