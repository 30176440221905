import { styled, Typography } from '@mui/material';
import { lxColors } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

export const DescriptionTitle = styled(Typography)(({ theme }) => ({
  color: lxColors.infoBox.font.title.color,
  fontFamily: lxFonts.DATA,
  fontSize: 12,
}));

export const InfoBoxDescription = styled(DescriptionTitle)(({ theme }) => ({
  color: lxColors.infoBox.font.description.color,
}));
export const SmallDescriptionText = styled(DescriptionTitle)(({ theme }) => ({
  color: lxColors.font.description.color,
  fontSize: 10,
}));

export const SmallDescriptionText2 = styled(DescriptionTitle)(({ theme }) => ({
  color: lxColors.font.iconDescription.color,
  fontSize: 12,
  fontFamily: lxFonts.SYSTEM,
}));

export const SparkLineText = styled(SmallDescriptionText)(({ theme }) => ({
  color: lxColors.font.sparkLine.color,
  fontWeight: '500',
}));
