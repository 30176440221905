import { Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import DocumentPrivacyInfoBlock, {
  DocumentPrivacyInfoBlockProps,
} from './DocumentPrivacyInfoBlock';
import { lxColors } from '../../utils/LxColors';
import { Employee } from '../../utils/types/Entities';

export type DocumentPrivacyInfoTileProps = {
  person?: Employee;
  documentPrivacyInfoBlocks: DocumentPrivacyInfoBlockProps[];
};

const DocumentPrivacyInfoTile = ({
  person,
  documentPrivacyInfoBlocks,
}: DocumentPrivacyInfoTileProps): JSX.Element => {
  return (
    <>
      {person && (
        <Typography
          fontFamily={lxFonts.DATA}
          fontSize={20}
          sx={{ color: lxColors.infoBox.font.description.color }}
        >
          {person.name}
        </Typography>
      )}
      {documentPrivacyInfoBlocks.map((block, index) => (
        <DocumentPrivacyInfoBlock
          key={index}
          accessLevel={block.accessLevel}
          icon={block.icon}
          title={block.title}
          organizations={block.organizations}
          people={block.people}
          tags={block.tags}
          notifications={block.notifications}
          documentType={block.documentType}
          documentInfoLines={block.documentInfoLines}
        />
      ))}
    </>
  );
};

export default DocumentPrivacyInfoTile;
