import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';

type ErrorProps = {
  message: string;
};

const Error = ({ message }: ErrorProps): JSX.Element => {
  return (
    <Alert severity="error" sx={{ width: 0.8 }}>
      <AlertTitle>Error</AlertTitle>
      <Typography
        display={'flex'}
        justifyContent={'center'}
        sx={{
          fontFamily: lxFonts.HEADING,
          fontWeight: 300,
          fontSize: '15px',
          width: 1,
        }}
      >
        {message}
      </Typography>
    </Alert>
  );
};

export default Error;
