import { Box, Typography } from '@mui/material';

type LinkedinIconProps = {
  width?: string;
  height?: string;
};

const LinkedinIcon = ({
  width = '18px',
  height = '18px',
}: LinkedinIconProps) => {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        backgroundColor: '#0288d1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
        p: 0.125,
      }}
    >
      <Typography sx={{ fontWeight: 'bold' }} color={'white'}>
        in
      </Typography>
    </Box>
  );
};
export default LinkedinIcon;
