import { lxRESTAPIFetch } from './DiscussionThreadSectionFetch';

export interface PeopleAndConnection {
  badge: string;
  name: string;
  title: string;
  department: string;
  sinceMs: number;
  recencyMs: number;
  responsiveness: number;
  responsivenessChangePercent: number;
  toneSentimentPercentage: number;
  frequency: number;
  frequencyChangePercent: number;
  collaborators: string[];
  skillTags: string[];
}

const apiUrlPrefix = '/api/accountintelligence/peopleandconnections';

export async function peopleAndConnectionSectionData(): Promise<
  PeopleAndConnection[]
> {
  return lxRESTAPIFetch(apiUrlPrefix, null, [] as PeopleAndConnection[][]);
}
