import { SvgIconComponent } from '@mui/icons-material';
import { HStack } from '../structures/Stacks';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { lxColorsNew } from '../../../utils/LxColors';
import { lxIconFontSize } from '../../../utils/LxFonts';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import UnstyledButton from '../buttons/UnstyledButton';

type LxTileSmallProps = {
  label: string;
  icon: SvgIconComponent;
  onClick?: () => void;
};

const LxTileSmall = ({
  label,
  icon: Icon,
  onClick,
}: LxTileSmallProps): JSX.Element => {
  return (
    <UnstyledButton onClick={onClick} disabled={!onClick}>
      <HStack
        sx={{
          px: 1,
          py: 0.5,
          border: 4,
          borderColor: lxColorsNew.blue.light,
          borderRadius: '4px',
        }}
      >
        <LxTypography variant={LxTypographyTypes.TILE}>{label}</LxTypography>
        <Icon
          sx={{
            color: lxColorsNew.grey.regular,
            fontSize: lxIconFontSize.MEDIUM,
          }}
        />
      </HStack>
    </UnstyledButton>
  );
};

export default LxTileSmall;
