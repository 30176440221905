import Search from '../pages/search';
import Dashboard from '../pages/dashboard';
import About from '../pages/about';
import AccountIntelligence from '../pages/accountIntelligence';
import CalendarView from '../pages/calendarView';
import CustomerHandover from '../pages/customerhandover';
import PrivacyAndSharing from '../pages/privacyAndSharing';
import AdminData from '../pages/internaladmin/AdminData';
import AdminActors from '../pages/internaladmin/AdminActors';
import OrganizationalDirectory from '../pages/organizationalDirectory';
import SignUp from '../pages/signup/signUp';
import SignupEmail from '../pages/signup/signUpEmail';
import SignUpDataHub from '../pages/signup/signUpDataHub';
import SignUpTeamConnect from '../pages/signup/signUpTeamConnect';
import SignUpOnBoarding from '../pages/signup/signUpOnBoarding';
import SignUpUpgrade from '../pages/signup/signUpUpgrade';
import { RoutePaths } from './Routes';
import DesignSystem from '../pages/designsystem';
import AdminOrgs from '../pages/internaladmin/AdminOrgs';

export type RoutePage = {
  path: RoutePaths;
  name: string;
  element: JSX.Element;
};

export const AppPages: RoutePage[] = [
  {
    path: RoutePaths.Search,
    name: 'Search',
    element: <Search />,
  },
  {
    path: RoutePaths.Dashboard,
    name: 'Dashboard',
    element: <Dashboard />,
  },
  {
    path: RoutePaths.About,
    name: 'About',
    element: <About />,
  },
  {
    path: RoutePaths.AccountIntelligence,
    name: 'Account Intelligence',
    element: <AccountIntelligence />,
  },
  {
    path: RoutePaths.CalendarView,
    name: 'Calendar View',
    element: <CalendarView />,
  },
  {
    path: RoutePaths.CustomerHandover,
    name: 'Customer Handover',
    element: <CustomerHandover />,
  },
  {
    path: RoutePaths.PrivacyAndSharing,
    name: 'Privacy and Sharing',
    element: <PrivacyAndSharing />,
  },
  {
    path: RoutePaths.AdminData,
    name: 'Admin Data',
    element: <AdminData />,
  },
  {
    path: RoutePaths.OrganizationalDirectory,
    name: 'Organizational Directory',
    element: <OrganizationalDirectory />,
  },
  {
    path: RoutePaths.AdminActor,
    name: 'Admin Actor',
    element: <AdminActors />,
  },
  {
    path: RoutePaths.AdminOrg,
    name: 'Admin Org',
    element: <AdminOrgs />,
  },
];

export const SignUpPages: RoutePage[] = [
  {
    path: RoutePaths.SignUp,
    name: 'Sign Up',
    element: <SignUp />,
  },
  {
    path: RoutePaths.SignUpEmail,
    name: 'Sign Up Email',
    element: <SignupEmail />,
  },
  {
    path: RoutePaths.SignUpDataHub,
    name: 'Sign Up Data Hub',
    element: <SignUpDataHub />,
  },
  {
    path: RoutePaths.SignUpTeamConnect,
    name: 'Sign Up Team Connect',
    element: <SignUpTeamConnect />,
  },
  {
    path: RoutePaths.SignUpOnBoarding,
    name: 'Sign Up On Boarding',
    element: <SignUpOnBoarding />,
  },
  {
    path: RoutePaths.SignUpUpgrade,
    name: 'Sign Up Upgrade',
    element: <SignUpUpgrade />,
  },
  {
    path: RoutePaths.DesignSystem,
    name: 'Design System',
    element: <DesignSystem />,
  },
];
