import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { lxFonts } from '../../../utils/LxFonts';
import { lxColors } from '../../../utils/LxColors';

type SignUpSubTitleProps = {
  text: string;
  sx?: SxProps<Theme>;
};

const SignUpSubTitle = ({ text, sx }: SignUpSubTitleProps): JSX.Element => {
  return (
    <Typography
      fontSize={28}
      fontFamily={lxFonts.HEADING}
      fontWeight={'medium'}
      color={lxColors.font.signup.grey}
      sx={sx}
    >
      {text}
    </Typography>
  );
};

export default SignUpSubTitle;
