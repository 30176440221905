import moment from 'moment/moment';
import { PlatformChip } from '../../features/common/LxChips';
import { Box, Stack, Typography } from '@mui/material';
import { Platforms, PlatformType } from '../../features/common/Constants';
import { Employee } from '../../utils/types/Entities';
import { LxAvatar } from '../../features/common/avatars/LxAvatar';

export type UpcomingTask = {
  date: moment.Moment;
  platform: PlatformType;
  avatar: Employee;
  description: string;
};

type UpcomingTaskInfoProps = {
  task: UpcomingTask;
};

const UpcomingTaskInfo = ({ task }: UpcomingTaskInfoProps): JSX.Element => {
  return (
    <Stack direction={'column'} spacing={1}>
      <Stack
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        spacing={3}
      >
        <PlatformChip platform={Platforms.OneDrive} sx={{ width: 100 }} />
        <Box>
          <Typography color={'#858585'} fontSize={14}>
            {task.date.format('D MMM')}
          </Typography>
        </Box>
        <LxAvatar
          variant={'small'}
          label={task.avatar.name}
          avatar={task.avatar.avatarSrc}
        />
      </Stack>
      <Typography color={'#858585'} fontSize={13} sx={{ bgcolor: '#fbfff2' }}>
        {task.description}
      </Typography>
    </Stack>
  );
};

export default UpcomingTaskInfo;
