import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import Badge from '@mui/material/Badge';
import CircleIcon from '@mui/icons-material/Circle';
import { IconButton } from '@mui/material';

type NotificationBellProps = {
  onClick?: () => void;
  sx?: SxProps<Theme>;
  notifColor?: string;
  bellColor?: string;
  otherProps?: { [key: string]: any };
};

const NotificationBell = ({
  onClick,
  sx,
  notifColor = 'primary.main',
  bellColor = 'info.main',
  otherProps,
}: NotificationBellProps): JSX.Element => {
  return (
    <IconButton sx={sx} onClick={onClick} {...otherProps}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <CircleIcon
            sx={{
              color: notifColor,
              fontSize: 12,
            }}
          />
        }
      >
        <NotificationsOutlinedIcon
          sx={{
            color: bellColor,
          }}
        />
      </Badge>
    </IconButton>
  );
};

export default NotificationBell;
