import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

type CalendarIconProps = {
  width?: number | string;
  bgcolor?: string;
  color?: string;
  calendarIconWidth?: number | string;
  calendarIconHeight?: number | string;
};

const CalendarIcon = ({
  width = 1,
  bgcolor = '#949494',
  color = '#ffffff',
  calendarIconWidth = 0.6,
  calendarIconHeight = 0.6,
}: CalendarIconProps): JSX.Element => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={width}
      sx={{
        bgcolor,
        borderRadius: '100%',
        aspectRatio: '1/1',
      }}
    >
      <CalendarTodayIcon
        sx={{
          color,
          width: calendarIconWidth,
          height: calendarIconHeight,
          aspectRatio: '1/1',
        }}
      />
    </Box>
  );
};

export default CalendarIcon;
