import { Grid, Stack, Typography } from '@mui/material';
import { lxFonts } from '../utils/LxFonts';
import {
  AngesAvatarObj,
  CindyAvatarObj,
  HenryAvatarObj,
  JoshAvatarObj,
  TrevorAvatarObj,
} from '../utils/TestAvatars';
import ConnectionCard, {
  ConnectionCardProps,
} from '../components/connectioncard/ConnectionCard';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { CubesIcon, WindowsIcon } from '../features/common/icons/SvgIcons';
import MouseIcon from '@mui/icons-material/Mouse';
import { ShareLevelEnum } from '../components/sharelevel/ShareLevelConstants';
import BusinessIcon from '@mui/icons-material/Business';
import { lxColors } from '../utils/LxColors';
import SelectDropdown from '../features/common/dropdown/Dropdown';
import { HStack } from '../features/common/structures/Stacks';

const titleStyle = {
  fontFamily: lxFonts.HEADING,
  fontSize: 28,
  sx: {
    color: lxColors.font.title.grey,
  },
};

const team: ConnectionCardProps[] = [
  {
    person: HenryAvatarObj,
    shareLevel: ShareLevelEnum.TEAM,
    connectionCardDescriptionProps: {
      title: 'meaningful connections',
      position: 'team member',
      icon: PeopleIcon,
      relationships: [
        {
          icon: AccountTreeIcon,
          name: 'Org Chart',
          description: 'Your team',
        },
        {
          icon: WindowsIcon,
          name: 'Active directory',
          description: 'Group: CS',
        },
      ],
    },
  },
  {
    person: CindyAvatarObj,
    shareLevel: ShareLevelEnum.TEAM,
    connectionCardDescriptionProps: {
      title: 'meaningful connections',
      position: 'team member',
      icon: PeopleIcon,
      relationships: [
        {
          icon: WindowsIcon,
          name: 'Active directory',
          description: 'Group: Pre-sales',
        },
        {
          icon: MouseIcon,
          name: 'Interactions',
          description: 'Emails, Meetings',
        },
      ],
    },
  },
];

const collaborators: ConnectionCardProps[] = [
  {
    person: AngesAvatarObj,
    shareLevel: ShareLevelEnum.COLLABORATORS,
    connectionCardDescriptionProps: {
      title: 'meaningful connections',
      position: 'collaborator',
      icon: CubesIcon,
      relationships: [
        {
          icon: MouseIcon,
          name: 'Interactions',
          description: 'Emails, Meetings',
        },
      ],
    },
  },
  {
    person: TrevorAvatarObj,
    shareLevel: ShareLevelEnum.COLLABORATORS,
    connectionCardDescriptionProps: {
      title: 'meaningful connections',
      position: 'collaborator',
      icon: CubesIcon,
      relationships: [
        {
          icon: MouseIcon,
          name: 'Interactions',
          description: 'Emails, Meetings',
        },
      ],
    },
  },
];

const colleagues: ConnectionCardProps[] = [
  {
    person: JoshAvatarObj,
    shareLevel: ShareLevelEnum.ORGANIZATION,
    connectionCardDescriptionProps: {
      title: 'Assigned Organization',
      position: 'Colleague',
      icon: BusinessIcon,
    },
  },
];

const OrganizationalDirectory = () => {
  return (
    <Stack direction={'column'} justifyContent={'stretch'} gap={3}>
      <HStack justifyContent={'flex-end'}>
        <SelectDropdown
          label={'Location'}
          options={['loc1', 'loc2', 'loc3', 'loc4']}
        />
        <SelectDropdown
          label={'Department'}
          options={['Department1', 'Department2', 'Department3', 'Department4']}
        />
        <SelectDropdown
          label={'Title'}
          options={['Title1', 'Title2', 'Title3', 'Title4']}
        />
      </HStack>
      <Typography {...titleStyle}>Your Team</Typography>
      <Grid container direction={'row'} spacing={4}>
        {team.map((teamMember, index) => (
          <Grid item xs={6} key={index}>
            <ConnectionCard {...teamMember} />
          </Grid>
        ))}
      </Grid>
      <Typography {...titleStyle}>Your Collaborators</Typography>
      <Grid container direction={'row'} spacing={4}>
        {collaborators.map((collaborator, index) => (
          <Grid item xs={6} key={index}>
            <ConnectionCard {...collaborator} />
          </Grid>
        ))}
      </Grid>
      <Typography {...titleStyle}>Your Colleagues</Typography>
      <Grid container direction={'row'} spacing={4}>
        {colleagues.map((colleague, index) => (
          <Grid item xs={6} key={index}>
            <ConnectionCard {...colleague} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default OrganizationalDirectory;
