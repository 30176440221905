import { HStack } from '../../../features/common/structures/Stacks';
import { Typography } from '@mui/material';
import { AsteriskThreeIcon } from '../../../features/common/icons/SvgIcons';
import { lxColors } from '../../../utils/LxColors';
import { lxFonts } from '../../../utils/LxFonts';

type SignUpInfoBoxProps = {
  text: string;
  color?: string;
};

const SignUpInfoBox = ({
  text,
  color = 'primary.main',
}: SignUpInfoBoxProps): JSX.Element => {
  return (
    <HStack
      sx={{ bgcolor: lxColors.infoBox.signup.background, p: 2 }}
      spacing={3.5}
    >
      <AsteriskThreeIcon sx={{ color }} />
      <Typography fontSize={18} sx={{ color }} fontFamily={lxFonts.SYSTEM}>
        {text}
      </Typography>
    </HStack>
  );
};

export default SignUpInfoBox;
