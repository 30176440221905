import { lxColors } from '../../utils/LxColors';
import { SmallDescriptionText2 } from './LxTexts';
import { HStack } from './structures/Stacks';
import { SvgIconComponent } from '@mui/icons-material';

type PlainLabelProps = {
  icon: SvgIconComponent;
  label: string;
};

const PlainLabel = ({ icon: Icon, label }: PlainLabelProps) => {
  return (
    <HStack>
      <Icon sx={{ color: lxColors.icons.defaultStyle.color }} />
      <SmallDescriptionText2>{label}</SmallDescriptionText2>
    </HStack>
  );
};
export default PlainLabel;
