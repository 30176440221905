import { HStack } from './structures/Stacks';
import CardTitle from './CardTitle';
import { lxColors } from '../../utils/LxColors';
import { Stack, Typography } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import IconButtonWithPopper from './buttons/IconButtonWithPopper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { lxFonts } from '../../utils/LxFonts';

type PeopleDescriptionProps = {
  peopleInfo: {
    title: string;
    subtitle: string;
    byline: string;
  };
  avatar: JSX.Element;
  avatarBottom?: JSX.Element;
  infoBottom?: JSX.Element;
  infoRightSection?: JSX.Element;
};

const PeopleDescription = ({
  peopleInfo,
  avatar,
  avatarBottom,
  infoBottom,
  infoRightSection,
}: PeopleDescriptionProps) => {
  const { title, subtitle, byline } = peopleInfo;

  return (
    <HStack alignItems={'flex-start'} spacing={3} sx={{ p: 3 }}>
      <Stack justifyContent={'center'} alignItems={'center'} spacing={0.5}>
        {avatar}
        {avatarBottom}
      </Stack>
      <Stack flexGrow={1}>
        <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
          <CardTitle
            title={title}
            subtitle={subtitle || ''}
            byline={byline || ''}
          />
          {infoRightSection ? (
            <HStack
              sx={{
                border: `1px solid ${lxColors.font.iconDescription.color}`,
                p: 1,
              }}
            >
              <EmojiEventsIcon sx={{ color: lxColors.font.iconDescription }} />
              {infoRightSection || <></>}
            </HStack>
          ) : (
            <IconButtonWithPopper
              icon={MoreVertIcon}
              popperContent={
                <Stack spacing={2}>
                  <Typography
                    fontSize={20}
                    fontFamily={lxFonts.SYSTEM}
                    sx={{ color: '#858585' }}
                  >
                    Settings
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontFamily={lxFonts.SYSTEM}
                    sx={{ color: '#858585' }}
                  >
                    Make collaborator
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontFamily={lxFonts.SYSTEM}
                    sx={{ color: '#858585' }}
                  >
                    Remove teammate
                  </Typography>
                </Stack>
              }
            />
          )}
        </HStack>
        {infoBottom}
      </Stack>
    </HStack>
  );
};
export default PeopleDescription;
