import { SvgIconComponent } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';

export type PlatformType = {
  name: string;
  icon: SvgIconComponent;
};

// ? may need to break this down further
export enum PlatformNames {
  // Meetings
  MicrosoftTeams = 'Teams',
  GoogleMeet = 'Google Meet',
  Zoom = 'Zoom',
  // Storage
  GoogleDrive = 'Google Drive',
  OneDrive = 'One Drive',
  // Email
  Gmail = 'Gmail',
  Outlook = 'Outlook',
  // Messaging
  Slack = 'Slack',
  // CRM
  Salesforce = 'Salesforce',
}

export const Platforms: { [key: string]: PlatformType } = {
  OneDrive: {
    name: PlatformNames.OneDrive,
    icon: MailIcon,
  },
  GoogleDrive: {
    name: PlatformNames.GoogleDrive,
    icon: MailIcon,
  },
  Outlook: {
    name: PlatformNames.Outlook,
    icon: MailIcon,
  },
  Gmail: {
    name: PlatformNames.Gmail,
    icon: MailIcon,
  },
  MicrosoftTeams: {
    name: PlatformNames.MicrosoftTeams,
    icon: MailIcon,
  },
  GoogleMeet: {
    name: PlatformNames.GoogleMeet,
    icon: MailIcon,
  },
  Zoom: {
    name: PlatformNames.Zoom,
    icon: MailIcon,
  },
  SalesForce: {
    name: PlatformNames.Salesforce,
    icon: MailIcon,
  },
  Slack: {
    name: PlatformNames.Slack,
    icon: MailIcon,
  },
};

export const UnknownError = 'An unknown error has occurred';
