import { IconButton, IconButtonProps } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MoreOptionsVertButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <MoreVertIcon color={'info'} />
    </IconButton>
  );
};

export default MoreOptionsVertButton;
