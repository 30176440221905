import { SvgIconComponent } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { HStack } from '../../features/common/structures/Stacks';
import PersonIcon from '@mui/icons-material/Person';
import { lxFonts } from '../../utils/LxFonts';
import ConnectionInteraction from './ConnectionInteraction';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { lxColors } from '../../utils/LxColors';

export type ConnectionCardDescriptionProps = {
  title: string;
  position: string;
  icon: SvgIconComponent;
  relationships?: {
    icon: SvgIconComponent;
    name: string;
    description: string;
  }[];
  sx?: SxProps<Theme>;
};

const ConnectionCardDescription = ({
  title,
  icon: Icon,
  position,
  relationships,
  sx,
}: ConnectionCardDescriptionProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      width={1}
      bgcolor={lxColors.background.orange}
      borderRadius={2}
      sx={sx}
    >
      <Stack m={2} gap={1}>
        <HStack>
          <PersonIcon
            sx={{ color: lxColors.font.title.grey, height: 16, width: 16 }}
          />
          <Typography
            textTransform={'uppercase'}
            fontFamily={lxFonts.HEADING}
            fontSize={12}
            sx={{ color: lxColors.font.title.darkGrey }}
          >
            {title}
          </Typography>
        </HStack>

        <Grid container ml={4}>
          <Grid
            item
            xs={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Icon sx={{ color: 'primary.main', fontSize: 14 }} />
          </Grid>

          <Grid item xs={11}>
            <Typography
              fontFamily={lxFonts.SYSTEM}
              sx={{ color: 'primary.main', fontSize: 14 }}
              textTransform={'capitalize'}
            >
              {position}
            </Typography>
          </Grid>

          <Grid
            item
            xs={1}
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'center'}
          >
            <Divider
              orientation={'vertical'}
              sx={{
                borderColor: 'primary.main',
                borderStyle: 'dashed',
                height: `calc(100% - ${theme.spacing(3)} - 14px)`,
              }}
            />
          </Grid>

          {relationships && (
            <Grid item xs={11} mt={1}>
              {relationships.map(({ icon, name, description }, index) => (
                <ConnectionInteraction
                  key={index}
                  icon={icon}
                  connectionName={name}
                  connectionDescription={description}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default ConnectionCardDescription;
