import { alpha, Avatar, Box, Grid, useTheme } from '@mui/material';
import { blueBoxBorder } from '../features/common/styles/BoxSx';
import SectionHeader from '../features/common/SectionHeader';
import PeopleDescription from '../features/common/PeopleDescription';
import { HStack } from '../features/common/structures/Stacks';
import HistoryIcon from '@mui/icons-material/History';
import { lxColors } from '../utils/LxColors';
import { SmallDescriptionText2 } from '../features/common/LxTexts';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ReplyIcon from '@mui/icons-material/Reply';

const InternalContacts = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...blueBoxBorder,
        borderColor: alpha(theme.palette.info.light, 0.1),
      }}
      mb={10}
    >
      <SectionHeader sectionTitle={'Internal Experts'} />
      <Grid
        container
        columns={2}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {Array.from({ length: 2 }).map((_, i) => {
          return (
            <PeopleDescription
              key={i}
              avatar={
                <Avatar
                  sx={{ width: 68, height: 68 }}
                  src={'https://avatars.githubusercontent.com/u/2603730?v=4'}
                />
              }
              peopleInfo={{
                title: 'Samuel',
                subtitle: 'Senior Manager',
                byline: 'Marketing',
              }}
              infoBottom={
                <>
                  <HStack spacing={4}>
                    <HStack>
                      <HistoryIcon
                        sx={{ color: lxColors.icons.defaultStyle.color }}
                      />
                      <SmallDescriptionText2>
                        Since Jun 2021
                      </SmallDescriptionText2>
                    </HStack>
                    <HStack>
                      <ChatOutlinedIcon
                        sx={{ color: lxColors.icons.defaultStyle.color }}
                      />
                      <SmallDescriptionText2>3 d ago</SmallDescriptionText2>
                    </HStack>
                    <HStack>
                      <ReplyIcon
                        sx={{ color: lxColors.icons.defaultStyle.color }}
                      />
                      <SmallDescriptionText2>3 days ago</SmallDescriptionText2>
                    </HStack>
                  </HStack>
                </>
              }
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default InternalContacts;
