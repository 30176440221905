import { LxSize } from '../../../utils/LxGenericTypes';
import LxTileLarge from './LxTileLarge';
import { SvgIconComponent } from '@mui/icons-material';
import LxTileMedium from './LxTileMedium';
import LxTileSmall from './LxTileSmall';

type LxTileProps = {
  variant: LxSize;
  label: string;
  icon: SvgIconComponent;
  value?: number;
  selected?: boolean;
};

// Each tile has a different layout so each have their own component
const LxTile = ({
  variant,
  label,
  icon,
  value,
  selected,
}: LxTileProps): JSX.Element => {
  switch (variant) {
    case 'large':
      return (
        <LxTileLarge
          icon={icon}
          label={label}
          count={value}
          selected={selected}
        />
      );
    case 'medium':
      return <LxTileMedium selected={selected} icon={icon} label={label} />;
    case 'small':
      return <LxTileSmall icon={icon} label={label} />;
  }
};

export default LxTile;
