import { Grid, Stack } from '@mui/material';
import YearChart from './YearChat';
import { CalendarYearData } from './CalendarDataTypes';

const calendarYearDataArr: CalendarYearData[] = [
  {
    year: 2020,
    months: {
      1: {
        12: {
          sales: 2,
          legal: 1,
          clientReports: 3,
        },
        16: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        19: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        20: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
      },
      2: {
        12: {
          clientReports: 1,
        },
        13: {
          sales: 1,
        },
      },
      3: {
        14: {
          sales: 10,
        },
        21: {
          legal: 3,
        },
        22: {
          clientReports: 5,
        },
        28: {
          clientReports: 5,
        },
      },
      4: {
        2: {
          sales: 4,
          legal: 10,
        },
        10: {
          clientReports: 4,
        },
        13: {
          sales: 3,
        },
        24: {
          sales: 3,
        },
        30: {
          clientReports: 5,
        },
      },
      5: {
        1: {
          sales: 4,
        },
        15: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        25: {
          sales: 4,
        },
      },
      7: {
        23: {
          sales: 4,
        },
        24: {
          sales: 4,
        },
      },
      9: {
        8: {
          sales: 4,
        },
        10: {
          sales: 4,
        },
        11: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
      },
      11: {
        12: {
          sales: 4,
        },
        18: {
          sales: 4,
        },
        26: {
          sales: 4,
        },
        27: {
          sales: 4,
        },
      },
      12: {
        6: {
          sales: 4,
        },
        12: {
          sales: 4,
        },
        13: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        20: {
          sales: 4,
        },
      },
    },
  },
  {
    year: 2021,
    months: {
      1: {
        12: {
          sales: 2,
          legal: 1,
          clientReports: 3,
        },
        16: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        19: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        20: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
      },
      2: {
        12: {
          clientReports: 1,
        },
        13: {
          sales: 1,
        },
      },
      3: {
        14: {
          sales: 10,
        },
        21: {
          legal: 3,
        },
        22: {
          clientReports: 5,
        },
        28: {
          clientReports: 5,
        },
      },
      4: {
        2: {
          sales: 4,
          legal: 10,
        },
        10: {
          clientReports: 4,
        },
        13: {
          sales: 3,
        },
        24: {
          sales: 3,
        },
        30: {
          clientReports: 5,
        },
      },
      5: {
        1: {
          sales: 4,
        },
        15: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        25: {
          sales: 4,
        },
      },
      7: {
        23: {
          sales: 4,
        },
        24: {
          sales: 4,
        },
      },
      9: {
        8: {
          sales: 4,
        },
        10: {
          sales: 4,
        },
        11: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
      },
      11: {
        12: {
          sales: 4,
        },
        18: {
          sales: 4,
        },
        26: {
          sales: 4,
        },
        27: {
          sales: 4,
        },
      },
      12: {
        6: {
          sales: 4,
        },
        12: {
          sales: 4,
        },
        13: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        20: {
          sales: 4,
        },
      },
    },
  },
  {
    year: 2022,
    months: {
      1: {
        12: {
          sales: 2,
          legal: 1,
          clientReports: 3,
        },
        16: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        19: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
        20: {
          sales: 1,
          legal: 2,
          clientReports: 4,
        },
      },
      2: {
        12: {
          clientReports: 1,
        },
        13: {
          sales: 1,
        },
      },
      3: {
        14: {
          sales: 10,
        },
        21: {
          legal: 3,
        },
        22: {
          clientReports: 5,
        },
        28: {
          clientReports: 5,
        },
      },
      4: {
        2: {
          sales: 4,
          legal: 10,
        },
        10: {
          clientReports: 4,
        },
        13: {
          sales: 3,
        },
        24: {
          sales: 3,
        },
        30: {
          clientReports: 5,
        },
      },
      5: {
        1: {
          sales: 4,
        },
        15: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        25: {
          sales: 4,
        },
      },
      7: {
        23: {
          sales: 4,
        },
        24: {
          sales: 4,
        },
      },
      9: {
        8: {
          sales: 4,
        },
        10: {
          sales: 4,
        },
        11: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
      },
      11: {
        12: {
          sales: 4,
        },
        18: {
          sales: 4,
        },
        26: {
          sales: 4,
        },
        27: {
          sales: 4,
        },
      },
      12: {
        6: {
          sales: 4,
        },
        12: {
          sales: 4,
        },
        13: {
          sales: 4,
        },
        19: {
          sales: 4,
        },
        20: {
          sales: 4,
        },
      },
    },
  },
];

const CalendarChart = (): JSX.Element => {
  return (
    <Stack width={1} direction={'column'}>
      <Grid container direction={'row'} width={1} spacing={4}>
        {Array.from({ length: calendarYearDataArr.length }).map((_, i) => {
          return (
            <Grid item xs={4} key={calendarYearDataArr[i].year}>
              <YearChart calendarYearData={calendarYearDataArr[i]} />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
export default CalendarChart;
