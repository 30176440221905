import { SvgIconComponent } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import { lxColors } from '../../utils/LxColors';

export type ConnectionInteractionProps = {
  icon: SvgIconComponent;
  connectionName: string;
  connectionDescription: string;
};

const ConnectionInteraction = ({
  icon: Icon,
  connectionName,
  connectionDescription,
}: ConnectionInteractionProps) => {
  return (
    <Grid container direction={'row'} sx={{ mb: 1 }}>
      <Grid
        item
        xs={0.5}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        ml={-1}
      >
        <Divider
          sx={{
            width: '200%',
            borderColor: 'primary.main',
            borderStyle: 'dashed',
          }}
        />
      </Grid>
      <Grid item xs={1} container justifyContent={'center'}>
        <Icon sx={{ color: 'primary.main', fontSize: 14, mt: '3px' }} />
      </Grid>
      <Grid item xs={10.5}>
        <Typography
          fontFamily={lxFonts.SYSTEM}
          sx={{ color: 'primary.main', fontSize: 13 }}
        >
          {connectionName}
        </Typography>
      </Grid>
      <Grid item xs={1.5} />
      <Grid item xs={10.5} alignSelf={'flex-end'}>
        <Typography
          fontFamily={lxFonts.SYSTEM}
          sx={{ fontSize: 13, color: lxColors.font.description.color }}
        >
          {connectionDescription}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConnectionInteraction;
