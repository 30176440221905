import { Typography } from '@mui/material';
import { getLxTypographyStyle, LxTypographyTypes } from './lxTypographyStyles';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

// Only the color is overridable, otherwise all the font styles are fixed
type LxTypographyProps = {
  variant: LxTypographyTypes;
  children: string | number | ReactNode;
  sx?: SxProps<Theme>;
};

const LxTypography = ({
  variant,
  children,
  sx = {},
}: LxTypographyProps): JSX.Element => {
  return (
    <Typography
      sx={{
        ...getLxTypographyStyle(variant),
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default LxTypography;
