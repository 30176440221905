import LxCard from '../common/card/LxCard';
import PeopleDescription from '../common/PeopleDescription';
import { lxColors } from '../../utils/LxColors';
import { HStack } from '../common/structures/Stacks';
import ScheduleIcon from '@mui/icons-material/Schedule';
import GroupIcon from '@mui/icons-material/Group';
import SendIcon from '@mui/icons-material/Send';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import BackgroundedIcon from '../common/icons/BackgroundedIcon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PlainLabel from '../common/PlainLabel';
import { CommunicationChart } from './CommunicationChart';
import { Avatar } from '@mui/material';

type InternalPeopleCardProps = {};

const InternalPeopleCard = (props: InternalPeopleCardProps) => {
  const { sparkLine } = lxColors;
  return (
    <LxCard sx={{ p: 0 }}>
      <PeopleDescription
        avatar={
          <Avatar
            sx={{ width: 68, height: 68 }}
            src={'https://avatars.githubusercontent.com/u/2603730?v=4'}
          />
        }
        infoRightSection={
          <HStack>
            <BackgroundedIcon icon={ScheduleIcon} />
            <BackgroundedIcon icon={SwapCallsIcon} />
            <BackgroundedIcon icon={GroupIcon} />
            <BackgroundedIcon icon={SendIcon} />
          </HStack>
        }
        infoBottom={
          <>
            <HStack>
              <PlainLabel icon={EventAvailableIcon} label={'Since Jan 2021'} />
              <PlainLabel icon={ReplyAllIcon} label={'1 hr ago'} />
              <PlainLabel icon={QuestionAnswerIcon} label={'1 day'} />
            </HStack>
            <CommunicationChart
              lineColor={sparkLine.line.primary.color}
              backgroundColor={sparkLine.background.primary.color}
            />
          </>
        }
        peopleInfo={{
          title: 'Henry',
          subtitle: 'Sales Representative',
          byline: 'Sales',
        }}
      />
    </LxCard>
  );
};
export default InternalPeopleCard;
