import { Stack } from '@mui/material';
import SectionHeader from '../../features/common/SectionHeader';
import UpcomingTaskInfo, { UpcomingTask } from './UpcomingTaskInfo';

export type UpcomingTasksColumnProps = {
  title: string;
  tasks: UpcomingTask[];
};

const UpcomingTasksColumn = ({
  title,
  tasks,
}: UpcomingTasksColumnProps): JSX.Element => {
  return (
    <Stack direction={'column'} spacing={3}>
      <SectionHeader sectionTitle={title} />
      {tasks.map((task) => (
        <UpcomingTaskInfo task={task} />
      ))}
    </Stack>
  );
};

export default UpcomingTasksColumn;
