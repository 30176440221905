import { Link } from 'react-router-dom';

const Error = (): JSX.Element => {
  return (
    <div className="error">
      <div>Error</div>
      <div>--Cannot find what you are looking for--</div>
      <div>
        <Link to={'/'}>Go back to root "/"</Link>
      </div>
    </div>
  );
};
export default Error;
