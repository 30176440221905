import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export type TabPanelProps = {
  value: number;
  index: number;
} & PropsWithChildren;

const TabPanel = ({ index, value, children }: TabPanelProps) => {
  const sx = index !== value ? { display: 'none' } : {};

  return <Box sx={{ ...sx, width: '100%' }}>{children}</Box>;
};

export default TabPanel;
