import { lxRESTAPIFetch } from './DiscussionThreadSectionFetch';

////////////////////////////////////////////////////////////////////////////////
/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2023-05-01 21:59:41.

export interface ThirdPartyInvolved {
  thirdPartyName: string;
  thirdPartyDomain: string;
  thirdPartyIconUrl: string;
  numEmails: number;
  numMeetings: number;
  communicationsByMonth: number[];

  communicationsByMonthLastMonth: number;
}
////////////////////////////////////////////////////////////////////////////////
const apiUrlPrefix = '/api/accountintelligence/thirdpartiesinvolved';
export async function thirdPartiesInvolvedData(): Promise<
  ThirdPartyInvolved[]
> {
  return lxRESTAPIFetch(apiUrlPrefix, null, [] as ThirdPartyInvolved[][]);
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export default thirdPartiesInvolvedData;
