import { SvgIconComponent } from '@mui/icons-material';
import { lxColorsNew } from '../../../utils/LxColors';
import { lxIconFontSize } from '../../../utils/LxFonts';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import { HStack } from '../structures/Stacks';
import LxTypography from '../../../components/lxTypography/LxTypography';
import UnstyledButton from '../buttons/UnstyledButton';

type LxTileMediumProps = {
  label: string;
  icon: SvgIconComponent;
  selected?: boolean;
  onClick?: () => void;
};

// Better not use the chip component since it will mean that we have to struggle
// to override typography compared to just using the LxTypography component
// to keep everything consistent.

const LxTileMedium = ({
  label,
  icon: Icon,
  selected,
  onClick,
}: LxTileMediumProps): JSX.Element => {
  return (
    <UnstyledButton onClick={onClick} disabled={!onClick}>
      <HStack
        sx={{
          color: selected
            ? lxColorsNew.orange.regular
            : lxColorsNew.grey.regular,
          cursor: 'pointer',
          px: 2,
          py: 1,
          border: 4,
          borderColor: lxColorsNew.blue.light,
          borderRadius: '4px',
          boxShadow: selected
            ? `inset 0px 0px 2px ${lxColorsNew.blue.regular}`
            : '',
        }}
      >
        <Icon
          sx={{
            color: `${
              selected ? lxColorsNew.orange.regular : lxColorsNew.grey.regular
            } !important`,
            fontSize: lxIconFontSize.MEDIUM,
          }}
        />
        <LxTypography
          variant={LxTypographyTypes.TILE}
          sx={{
            color: selected
              ? lxColorsNew.orange.regular
              : lxColorsNew.grey.regular,
          }}
        >
          {label}
        </LxTypography>
      </HStack>
    </UnstyledButton>
  );
};

export default LxTileMedium;
