import { RelationshipProps, SentimentProps, WorkProps } from './AccountHealth';

const apiUrlPrefix = '/api/accountintelligence/accounthealth';

type AccountHealthData = {
  orgid: number;
  customer: string;
  relationship?: RelationshipProps;
  work?: WorkProps;
  sentiment?: SentimentProps;
  // more AccountHealthData fields here:
};

type AccountHealthFetchData = {
  data: AccountHealthData;
  errors: Array<{ message: string }>;
};

export async function accountHealthSentimentData(): Promise<AccountHealthData> {
  const response = await fetch(`${apiUrlPrefix}/sentiment`, {
    method: 'GET',
    headers: {
      //'content-type': 'application/json;charset=UTF-8',
    },
    // body: JSON.stringify({}),
  });
  const { data, errors }: AccountHealthFetchData = await response.json();
  if (response.ok && data) {
    return data;
  }
  return Promise.reject(new Error(errors ? errors.join(', ') : ''));
}

export async function accountHealthWorkData(): Promise<AccountHealthData> {
  const response = await fetch(`${apiUrlPrefix}/work`, {
    method: 'GET',
    headers: {
      //'content-type': 'application/json;charset=UTF-8',
    },
    // body: JSON.stringify({}),
  });
  const { data, errors }: AccountHealthFetchData = await response.json();
  if (response.ok && data) {
    return data;
  }
  return Promise.reject(new Error(errors ? errors.join(', ') : ''));
}

async function accountHealthRelationshipData(): Promise<AccountHealthData> {
  const response = await fetch(`${apiUrlPrefix}/relationship`, {
    method: 'GET',
    headers: {
      //'content-type': 'application/json;charset=UTF-8',
    },
    // body: JSON.stringify({}),
  });
  const { data, errors }: AccountHealthFetchData = await response.json();
  if (response.ok && data) {
    return data;
  }
  return Promise.reject(
    new Error(errors != null && errors.length > 0 ? errors.join(', ') : '')
  );
}

export default accountHealthRelationshipData;
