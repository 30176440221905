import { debounce } from 'lodash';
import { useState } from 'react';
import {
  styled,
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { lxFonts } from '../utils/LxFonts';
import { lxColors } from '../utils/LxColors';

export type NumericFieldProps = {
  value: number;
  onValueChanged: (value: number) => void;
  textFieldProps?: TextFieldProps;
};

export const NumericField = ({
  value,
  onValueChanged,
  textFieldProps,
}: NumericFieldProps) => {
  return (
    <TextField
      {...textFieldProps}
      value={value}
      onChange={(e) => {
        onValueChanged(Number.parseInt(e.target.value));
      }}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
    />
  );
};

export const SignUpTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: `1px 1px 14px ${lxColors.textField.focusedBorderColor}`,
  borderRadius: 4,
  '.MuiInputBase-input': {
    padding: `${theme.spacing(2)}`,
  },
  '.MuiInputBase-root': {
    fontFamily: lxFonts.SYSTEM,
  },
  '&:hover': {
    outlineColor: lxColors.textField.focusedBorderColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: lxColors.textField.borderColor,
    },
    '&:hover fieldset': {
      borderColor: lxColors.textField.focusedBorderColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: lxColors.textField.focusedBorderColor,
    },
  },
}));

export type SearchFieldProps = {
  onSearch: (search: string) => void;
  autoSearch?: boolean;
};

export const SearchField = ({
  onSearch,
  autoSearch = true,
}: SearchFieldProps) => {
  const [search, setSearch] = useState('');

  const doAutoSearch = debounce((searchValue: string) => {
    onSearch(searchValue);
  }, 500);

  const clearSearch = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <TextField
      variant="outlined"
      value={search}
      placeholder="Search"
      onChange={(e) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
        if (autoSearch) {
          doAutoSearch(searchValue);
        }
      }}
      onKeyUp={(e) => {
        if (e.code === 'Enter') {
          doAutoSearch.cancel();
          onSearch(search);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              size="medium"
              onClick={() => {
                doAutoSearch.cancel();
                onSearch(search);
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: search !== '' && (
          <InputAdornment position="end">
            <IconButton size="medium" onClick={clearSearch}>
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoFocus
      fullWidth
    />
  );
};
