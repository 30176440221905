import { Employee } from './types/Entities';

export const HenryAvatarObj: Employee = {
  name: 'Henry C',
  avatarSrc: 'https://mui.com/static/images/avatar/1.jpg',
  title: 'Manager',
  subTitle: 'Customer Success',
};

export const TravisAvatarObj: Employee = {
  name: 'Travis Howard',
  avatarSrc: 'https://mui.com/static/images/avatar/2.jpg',
  title: 'Sales Engineer',
  subTitle: 'Sales',
};

export const CindyAvatarObj: Employee = {
  name: 'Cindy Baker',
  avatarSrc: 'https://mui.com/static/images/avatar/3.jpg',
  title: 'Director',
  subTitle: 'Engineering',
};

export const AngesAvatarObj: Employee = {
  name: 'Agnes Walker',
  avatarSrc: 'https://mui.com/static/images/avatar/4.jpg',
  title: 'Account Manager',
  subTitle: 'Customer Success',
};

export const TrevorAvatarObj: Employee = {
  name: 'Trevor Henderson',
  avatarSrc: 'https://mui.com/static/images/avatar/5.jpg',
  title: 'Manager',
  subTitle: 'Customer Success',
};

export const JoshAvatarObj: Employee = {
  name: 'Josh Cena',
  avatarSrc: 'https://mui.com/static/images/avatar/6.jpg',
  title: 'Account Manager',
  subTitle: 'Finance',
};

export const AmyAvatarObj: Employee = {
  name: 'Amy Nana',
  avatarSrc: 'https://mui.com/static/images/avatar/7.jpg',
  title: 'Manager',
  subTitle: 'Customer Success',
};
