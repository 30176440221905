import { Box, Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import SignUpTitle from './common/SignUpTitle';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import SignUpSubTitle from './common/SignUpSubTitle';
import WorkOrbMinions from '../../assets/workOrbMinions.svg';
import { HStack } from '../../features/common/structures/Stacks';
import CheckIcon from '@mui/icons-material/Check';
import { SignUpTextField } from '../../components/TextFields';
import { lxFonts } from '../../utils/LxFonts';

const TypographyStyled = styled(Typography)(() => ({
  fontFamily: lxFonts.SYSTEM,
  fontSize: 16,
  color: '#666',
}));

const SignUpUpgrade = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={1}
      width={1}
      spacing={6}
    >
      <SignUpTitle text={'upgrade'} />
      <SignUpSubTitle text={'Perfect'} sx={{ ml: 2 }} />
      <TypographyStyled>
        You are now set to start using WorkOrb. Powerful AI engine is on your
        finger tips.{' '}
      </TypographyStyled>
      <TypographyStyled>
        To make Lynx more powerful, ask your IT to upgrade to business account.
      </TypographyStyled>
      <Grid container direction={'row'}>
        <Grid item xs={6}>
          <Box component={'img'} src={WorkOrbMinions} alt={'WorbOrb Minions'} />
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <SignUpSubTitle text={'Get ACME onboard with us'} sx={{ mb: 3 }} />
          <TypographyStyled sx={{ mb: 3 }}>
            To make WorkOrb more powerful, ask your IT to upgrade to business
            account
          </TypographyStyled>
          <SignUpTextField
            size={'small'}
            variant={'outlined'}
            placeholder={'Your work email address'}
            onChange={(e) => console.log(e.target.value)}
            sx={{
              width: 0.9,
            }}
          />
          <HStack sx={{ mt: 3 }}>
            <CheckIcon sx={{ color: 'primary.main' }} />
            <TypographyStyled>
              Send anonymously, don't show your name
            </TypographyStyled>
          </HStack>
          <OrangeButton
            text={'Send email'}
            sx={{
              px: 5,
              mt: `${theme.spacing(10)} !important`,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SignUpUpgrade;
