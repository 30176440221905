import { Stack, Typography, useTheme } from '@mui/material';
import SignUpTitle from './common/SignUpTitle';
import SignUpSubTitle from './common/SignUpSubTitle';
import SignUpInfoBox from './common/SignUpInfoBox';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import { WhiteLeftArrow } from '../../features/common/icons/Arrows';
import GroupAvatars from '../../components/GroupAvatar';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { HStack } from '../../features/common/structures/Stacks';
import { lxFonts } from '../../utils/LxFonts';
import { lxColors } from '../../utils/LxColors';

const groupAvatarProps = {
  people: new Array(38).fill({
    name: 'John Doe',
    avatarSrc: 'https://mui.com/static/images/avatar/1.jpg',
  }),
};

const SignUpTeamConnect = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={1}
      width={1}
      spacing={6}
    >
      <SignUpTitle text={'team connect'} />
      <SignUpSubTitle text={'Your colleagues are already here'} />
      <SignUpInfoBox
        text={'Ah you are not the first one here. Join your organization!'}
      />
      <HStack justifyContent={'center'}>
        <BusinessOutlinedIcon sx={{ color: lxColors.icons.signUpCompany }} />
        <Typography
          fontFamily={lxFonts.DATA}
          fontSize={20}
          sx={{ color: lxColors.font.signup.blue }}
        >
          ACME Industries
        </Typography>
      </HStack>
      <GroupAvatars {...groupAvatarProps} />
      <OrangeButton
        prefixIcon={<WhiteLeftArrow />}
        text={'Connect with ACME'}
        sx={{
          mt: `${theme.spacing(10)} !important`,
        }}
      />
    </Stack>
  );
};

export default SignUpTeamConnect;
