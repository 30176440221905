import { Outlet } from 'react-router-dom';
import MuiTheme from '../../features/muitheme/MuiTheme';
import { Box } from '@mui/material';
import RootFooter from '../../components/footer';

const Root = (): JSX.Element => {
  return (
    <Box>
      <MuiTheme>
        <Outlet />
        <RootFooter />
      </MuiTheme>
    </Box>
  );
};
export default Root;
