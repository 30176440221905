import { MouseEvent, useState } from 'react';
import {
  ColumnType,
  DESERIALIZED_DATA_KEY,
  MetricsTableCol,
  rowData,
} from './MetricsTableTypes';
import moment from 'moment';

type UseMetricsTablePopper = {
  anchorEl: HTMLElement | null;
  headerName: string;
  value: string;
  usePre: boolean;
  handlePopperOpen: (event: MouseEvent<HTMLElement>, row: rowData) => void;
  handlePopperClose: () => void;
};

const useMetricsTablePopper = (
  colDef: MetricsTableCol[],
  rows: rowData[] | undefined | null
): UseMetricsTablePopper => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [headerName, setHeaderName] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [usePre, setUsePre] = useState(false);

  const handlePopperOpen = (event: MouseEvent<HTMLElement>) => {
    const fieldName = event.currentTarget.dataset.field!;
    const colIndex = parseInt(event.currentTarget.dataset.colindex!);
    const dataHeaderName = colDef[colIndex].headerName;
    const showHover = colDef[colIndex].showHover;
    if (!showHover) return;

    const id = event.currentTarget.parentElement!.dataset.id!;
    const row = rows?.find((r) => r.id.toString() === id)!;

    setHeaderName(dataHeaderName || '');
    setAnchorEl(event.currentTarget);

    switch (colDef[colIndex].columnType) {
      case ColumnType.EPOCH_TIME:
        setValue(moment(row[fieldName]).format('DD-MM-YYYY hh:mm:ss'));
        setUsePre(false);
        break;
      case ColumnType.SERIALIZED_JSON:
        setUsePre(true);
        let deserializedJson = row[DESERIALIZED_DATA_KEY];

        if (deserializedJson) {
          let parsedJson = JSON.parse(deserializedJson);
          setValue(JSON.stringify(parsedJson, null, 2));
          return;
        }

        setValue('Empty JSON');
        break;
      case ColumnType.NUMBER:
      case ColumnType.STRING:
        setValue(row[fieldName]);
        setUsePre(false);
        break;
    }
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    headerName,
    value,
    usePre,
    handlePopperOpen,
    handlePopperClose,
  };
};

export default useMetricsTablePopper;
