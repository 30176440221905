import MeetingInfo from '../components/meetinginfo/MeetingInfo';
import moment from 'moment';
import { PlatformNames } from '../features/common/Constants';
import { Grid } from '@mui/material';

const MeetingInfoProps = {
  title: 'Discuss Implementation Discrepancy',
  startTime: moment('2022-08-09T17:30:00'),
  endTime: moment('2022-08-09T18:00:00'),
  meetingPlatform: PlatformNames.MicrosoftTeams,
  members: [
    { name: 'John Doe', avatarSrc: 'https://i.pravatar.cc/300' },
    { name: 'John Doe', avatarSrc: 'https://i.pravatar.cc/300' },
    { name: 'John Doe', avatarSrc: 'https://i.pravatar.cc/300' },
    { name: 'John Doe', avatarSrc: 'https://i.pravatar.cc/300' },
  ],
};

const CalendarView = (): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <MeetingInfo {...MeetingInfoProps} />
      </Grid>
    </Grid>
  );
};

export default CalendarView;
