import {
  ColumnType,
  DefaultColumnStyle,
  MetricsTableCol,
  UseMetricsTableData,
} from '../../metricstable/MetricsTableTypes';
import { addIdToDataArray } from '../../../utils/data/DataUtils';
import useFetch from '../../../utils/data/useFetch';

type ConvDataRowEl = {
  id: string;
  stub: string;
  enabled: number;
  data: string;
  dbInsertTimeEpochMs: number;
  extraShort: number;
  extraInt: number;
  extraBin: string;
  dbUpdateBy: string;
};

const MetaDataColDef: MetricsTableCol[] = [
  // conv fields
  {
    ...DefaultColumnStyle,
    field: 'convid',
    headerName: 'Conv ID',
  },
  {
    ...DefaultColumnStyle,
    field: 'wfCoord',
    headerName: 'WF Coord',
  },
  {
    ...DefaultColumnStyle,
    field: 'wfPublishMs',
    headerName: 'WF Publish Ms',
    columnType: ColumnType.EPOCH_TIME,
  },
  {
    ...DefaultColumnStyle,
    field: 'wfEditMs',
    headerName: 'WF Edit MS',
    columnType: ColumnType.EPOCH_TIME,
  },
  {
    ...DefaultColumnStyle,
    field: 'wfCategory',
    headerName: 'WF Category',
  },
  {
    ...DefaultColumnStyle,
    field: 'wfStreamHash64',
    headerName: 'WF Stream Hash 64',
  },
  {
    ...DefaultColumnStyle,
    field: 'employeeUUID',
    headerName: 'Employee UUID',
  },
  // aux fields
  {
    ...DefaultColumnStyle,
    field: 'enabled',
    headerName: 'Enabled',
  },
  {
    ...DefaultColumnStyle,
    field: 'data',
    headerName: 'Data',
    columnType: ColumnType.SERIALIZED_JSON,
  },
  {
    ...DefaultColumnStyle,
    field: 'dbInsertTimeEpochMs',
    headerName: 'DB Insert Time',
    columnType: ColumnType.EPOCH_TIME,
  },
  {
    ...DefaultColumnStyle,
    field: 'extraShort',
    headerName: 'Extra Short',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraInt',
    headerName: 'Extra Integer',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraBin',
    headerName: 'Extra Binary',
  },
  {
    ...DefaultColumnStyle,
    field: 'dbUpdateBy',
    headerName: 'Update DB',
  },
];

type UseMetaDataTableData = UseMetricsTableData & {
  data: ConvDataRowEl[] | undefined | null;
};

const useConvDataTableData = (
  orgId: number,
  wfTenantId: string
): UseMetaDataTableData => {
  const { data, ...states } = useFetch<ConvDataRowEl[]>(
    `/api/debug/conv/${orgId}/${wfTenantId}`,
    [orgId, wfTenantId]
  );

  return {
    colDef: MetaDataColDef,
    data: addIdToDataArray(data),
    ...states,
  };
};

export default useConvDataTableData;
