import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { lxColors } from '../../../utils/LxColors';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

type SelectDropdownProps = {
  label: string;
  options: string[] | number[];
  callback?: (value: string | number) => void;
  defaultOption?: string | number;
  sx?: SxProps<Theme>;
};

const SelectDropdown = ({
  label,
  options,
  callback,
  defaultOption = '',
  sx = {},
}: SelectDropdownProps) => {
  const [selection, setSelection] = React.useState(defaultOption);

  const handleChange = (event: SelectChangeEvent) => {
    setSelection(event.target.value);
    callback && callback(event.target.value);
  };

  return (
    <FormControl size={'small'} sx={{ minWidth: 180, ...sx }}>
      <InputLabel
        id="select-label"
        sx={{ color: lxColors.selectDropdown.label.color }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={selection.toString()}
        label={label}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          bgcolor: lxColors.selectDropdown.background.color,
          borderRadius: 12,
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
