import { Box, Stack, Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import { OrangeChip, PurpleToBlueChip } from '../../features/common/LxChips';
import NotificationBell from '../../features/common/icons/NotificationBell';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DocumentInfoLine, { DocumentInfoLineProps } from './DocumentInfoLine';
import { SvgIconComponent } from '@mui/icons-material';
import { Employee, Organization } from '../../utils/types/Entities';
import LxCard from '../../features/common/card/LxCard';
import { lxColors } from '../../utils/LxColors';
import BordedIcon from '../../features/common/icons/BorderedIcon';
import ArrowDownButton from '../../features/common/buttons/ArrowDownButton';
import MoreOptionsVertButton from '../../features/common/buttons/MoreOptionsVertButton';
import { LxAvatar } from '../../features/common/avatars/LxAvatar';

export type DocumentPrivacyInfoBlockProps = {
  icon: SvgIconComponent;
  title: string;
  organizations: Organization[];
  people: Employee[];
  tags: string[];
  notifications: boolean;
  documentType: string;
  documentInfoLines: DocumentInfoLineProps[];
  accessLevel?: string;
};

const DocumentPrivacyInfoBlock = ({
  accessLevel,
  icon,
  title,
  organizations,
  people,
  tags,
  notifications,
  documentType,
  documentInfoLines,
}: DocumentPrivacyInfoBlockProps): JSX.Element => {
  return (
    <>
      {accessLevel ? (
        <Typography
          fontFamily={lxFonts.DATA}
          fontSize={14}
          textTransform={'uppercase'}
          sx={{ color: lxColors.infoBox.font.description.color }}
        >
          {accessLevel}
        </Typography>
      ) : (
        <Box sx={{ height: 20 }}></Box>
      )}
      <LxCard p={3}>
        <Stack direction={'column'} spacing={1}>
          <Stack direction={'row'} alignItems={'center'}>
            <BordedIcon
              icon={icon}
              sx={{
                alignSelf: 'flex-start',
                mr: 1,
              }}
            />
            <Stack sx={{ flexGrow: 1 }} spacing={0.5}>
              <Typography
                fontFamily={lxFonts.DATA}
                fontSize={16}
                sx={{ color: lxColors.font.title.darkGrey }}
              >
                {title}
              </Typography>
              <Stack direction={'row'} gap={1}>
                {organizations.map((org, index) => (
                  <LxAvatar
                    key={index}
                    variant={'small'}
                    label={org.name}
                    avatar={org.avatar}
                  />
                ))}
                {people.map((person, index) => (
                  <LxAvatar
                    key={index}
                    variant={'small'}
                    label={person.name}
                    avatar={person.avatarSrc}
                  />
                ))}
              </Stack>
              <Stack direction={'row'} spacing={1}>
                {tags.map((tag, index) => (
                  <OrangeChip key={index} label={tag} />
                ))}
                <PurpleToBlueChip
                  label={documentType}
                  sx={{
                    color: lxColors.font.description.color,
                    textTransform: 'uppercase',
                  }}
                />
              </Stack>
            </Stack>
            {notifications && (
              <NotificationBell
                sx={{
                  alignSelf: 'flex-start',
                }}
              />
            )}
            <MoreOptionsVertButton
              onClick={(e) => console.log(e)}
              sx={{
                alignSelf: 'flex-start',
              }}
            />
          </Stack>
          <Stack direction={'column'}>
            {documentInfoLines.map((line, index) => (
              <DocumentInfoLine
                key={index}
                info={line.info}
                date={line.date}
                person={line.person}
                redacted={line.redacted}
                button={line.button}
              />
            ))}
          </Stack>
          <Stack
            direction={'row'}
            spacing={1}
            flexGrow={1}
            alignItems={'center'}
          >
            <AttachFileIcon color={'info'} />
            <Typography color={'info.main'}>2</Typography>
            <PictureAsPdfIcon color={'info'} />
            <HistoryEduIcon color={'info'} />
            <ArrowDownButton />
          </Stack>
        </Stack>
      </LxCard>
    </>
  );
};

export default DocumentPrivacyInfoBlock;
