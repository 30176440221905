import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';
import { ReactNode } from 'react';
import { getLxCardStyle, LxCardTypes } from './LxCardStyles';

type CardProps = BoxProps & {
  variant?: LxCardTypes;
  children?: ReactNode;
  centerChildren?: boolean;
  sx?: SxProps<Theme>;
};

const centerChildrenSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * General purpose card to display information
 */
const LxCard = ({
  variant,
  children,
  centerChildren = false,
  sx,
  ...otherProps
}: CardProps) => {
  return (
    <Box
      border={lxColors.card.border}
      p={0}
      sx={{
        backgroundColor: lxColors.white.default,
        borderRadius: 2,
        ...(centerChildren ? centerChildrenSx : null),
        ...sx,
        ...(variant ? (getLxCardStyle(variant) as {}) : null),
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};
export default LxCard;
