import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { Alert, AlertColor, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LxCard from '../../../features/common/card/LxCard';
import { LxCardTypes } from '../../../features/common/card/LxCardStyles';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import MetricsTable from '../../../components/metricstable/MetricsTable';
import { HStack, VStack } from '../../../features/common/structures/Stacks';
import useFetch from '../../../utils/data/useFetch';
import { SearchField } from '../../../components/TextFields';
import { GridSortModel } from '@mui/x-data-grid';
import {
  MetricsTableCol,
  rowData,
} from '../../../components/metricstable/MetricsTableTypes';

export type GetAdminTableRowsProps<T> = {
  data: T[];
  filterBy: string;
};

export type GetAdminTableRows<T> = (
  props: GetAdminTableRowsProps<T>
) => rowData[];

export type AdminTableProps<T> = {
  alertMessage?: string;
  alertMessageType?: AlertColor;
  alertMessageHideDelay?: number;
  heading?: string;
  dataUrl: string;
  columns: MetricsTableCol[];
  getRows: GetAdminTableRows<T>;
  refresh?: number;
  onAdd?: () => any;
  initialSort?: GridSortModel;
  rowHeight?: number;
};

export const AdminTable = <T,>({
  alertMessage,
  alertMessageType,
  alertMessageHideDelay,
  heading,
  columns,
  dataUrl,
  getRows,
  refresh,
  onAdd,
  initialSort,
  rowHeight,
}: AdminTableProps<T>) => {
  const [filterBy, setFilterBy] = useState('');
  const {
    data,
    isLoading,
    isError: isFetchError,
    errorMessage: fetchErrorMessage,
  } = useFetch<T[]>(dataUrl, [refresh]);
  const rows = getRows({ data: data ?? [], filterBy });

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessageState, setAlertMessage] = useState('');
  const [alertMessageTimer, setAlertMessageTimer] = useState<number>();

  useEffect(() => {
    const message = alertMessage ?? '';
    const hideDelay = alertMessageHideDelay ?? 0;
    const show = message !== '';

    if (message !== alertMessageState) {
      clearTimeout(alertMessageTimer);
      setAlertMessage(message);
      setShowAlertMessage(show);
      if (show && hideDelay > 0) {
        setAlertMessageTimer(
          delay(() => setShowAlertMessage(false), hideDelay)
        );
      }
    }
  }, [
    alertMessage,
    alertMessageState,
    alertMessageHideDelay,
    alertMessageTimer,
  ]);

  return (
    <LxCard variant={LxCardTypes.Data} sx={{ width: '100%', padding: '1em' }}>
      <VStack fullWidth>
        <HStack fullWidth>
          <HStack sx={{ flex: 1 }}>
            <LxTypography variant={LxTypographyTypes.CARD_HEADING}>
              {heading}
            </LxTypography>
            <Box width={'40%'}>
              <SearchField onSearch={(search) => setFilterBy(search)} />
            </Box>
          </HStack>
          <Button variant="contained" onClick={onAdd}>
            Add
            <AddIcon />
          </Button>
        </HStack>
        <VStack fullWidth>
          {showAlertMessage && (
            <Alert severity={alertMessageType}>{alertMessageState}</Alert>
          )}
          <MetricsTable
            colDef={columns}
            rows={rows}
            rowCount={rows.length}
            initialSort={initialSort}
            rowHeight={rowHeight}
            loading={isLoading}
            error={isFetchError}
            errorMessage={fetchErrorMessage}
            containerSx={{ width: '100%' }}
            gridSx={{ width: '100%' }}
          />
        </VStack>
      </VStack>
    </LxCard>
  );
};

const DEFAULT_ALERT_HIDE_DELAY = 5000;

export type AlertMessageProps = {
  alertMessage?: string;
  alertMessageType?: AlertColor;
  alertMessageHideDelay?: number;
  showAlertMessage: (
    message: string,
    type: AlertColor,
    hideDelay?: number
  ) => any;
  clearAlertMessage: () => any;
};

export const useAlertMessage = (): AlertMessageProps => {
  const [alertMessage, setAlertMessage] = useState<string>();
  const [alertMessageType, setAlertMessageType] = useState<AlertColor>();
  const [alertMessageHideDelay, setAlertMessageHideDelay] = useState<number>();

  const showAlertMessage = (
    message: string,
    type: AlertColor,
    hideDelay: number = DEFAULT_ALERT_HIDE_DELAY
  ) => {
    setAlertMessage(message);
    setAlertMessageType(type);
    setAlertMessageHideDelay(hideDelay);
  };

  const clearAlertMessage = () => {
    setAlertMessage('');
  };

  return {
    alertMessage,
    alertMessageType,
    alertMessageHideDelay,
    showAlertMessage,
    clearAlertMessage,
  };
};
