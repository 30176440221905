import { Avatar, Box, Stack } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';

type CircularLogoProps = {
  size?: number;
  imageUrl?: string;
};

const CircularLogo = ({ size = 68, imageUrl }: CircularLogoProps) => {
  return (
    <Box
      sx={{
        borderRadius: 10,
        border: lxColors.circularLogo.border,
      }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ width: size, height: size }}
      >
        <Avatar
          src={
            imageUrl ??
            'https://i.picsum.photos/id/167/200/300.jpg?hmac=ZAuGlRPlSv0i_JnJr4FFW-OPsVz5bTx8mAI_qUYP_bM'
          }
        />
      </Stack>
    </Box>
  );
};
export default CircularLogo;
