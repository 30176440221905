import { useEffect, useState } from 'react';

import { HStack } from '../../../features/common/structures/Stacks';
import SectionHeader from '../../../features/common/SectionHeader';
import thirdPartiesInvolvedData, {
  ThirdPartyInvolved,
} from './ThirdPartiesInvolvedSectionFetch';
import ThirdPartyInvolvedComponent from './ThirdPartyInvolved';

const ThirdPartiesInvolvedSection = () => {
  const [thirdParties, setThirdParties] = useState<ThirdPartyInvolved[]>([]);

  useEffect(() => {
    thirdPartiesInvolvedData().then((responseData) => {
      setThirdParties(responseData);
    });
  }, []);

  return (
    <>
      <SectionHeader sectionTitle={'Third Parties involved'} side={<></>} />
      <HStack>
        {thirdParties.map((v, idx) => {
          return (
            <ThirdPartyInvolvedComponent data={v} key={'thirdPtyInv-' + idx} />
          );
        })}
      </HStack>
    </>
  );
};

export default ThirdPartiesInvolvedSection;
