import {
  ColumnType,
  DefaultColumnStyle,
  MetricsTableCol,
  UseMetricsTableData,
} from '../../metricstable/MetricsTableTypes';
import useFetch from '../../../utils/data/useFetch';
import { addIdToDataArray } from '../../../utils/data/DataUtils';

type OrgEntryDataEl = {
  id: number;
  orgid: number;
  orghandle: string;
  dcRegion: string;
  enabled: number;
  data: string;
  dbInsertTimeEpochMs: number;
  extraShort: number | undefined;
  extraInt: number | undefined;
  extraBin: string | undefined;
  dbUpdateBy: string;
};

const OrgEntryDataColDef: MetricsTableCol[] = [
  {
    ...DefaultColumnStyle,
    field: 'orgid',
    headerName: 'Org ID',
  },
  {
    ...DefaultColumnStyle,
    field: 'orghandle',
    headerName: 'Org Handle',
  },
  {
    ...DefaultColumnStyle,
    field: 'dcRegion',
    headerName: 'DC Region',
  },
  {
    ...DefaultColumnStyle,
    field: 'enabled',
    headerName: 'Enabled',
  },
  {
    ...DefaultColumnStyle,
    field: 'data',
    headerName: 'Data',
    columnType: ColumnType.SERIALIZED_JSON,
  },
  {
    ...DefaultColumnStyle,
    field: 'dbInsertTimeEpochMs',
    headerName: 'DB Insert Time',
    columnType: ColumnType.EPOCH_TIME,
  },
  {
    ...DefaultColumnStyle,
    field: 'extraShort',
    headerName: 'Extra Short',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraInt',
    headerName: 'Extra Integer',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraBin',
    headerName: 'Extra Binary',
  },
  {
    ...DefaultColumnStyle,
    field: 'dbUpdateBy',
    headerName: 'Update DB',
  },
];

export type UseOrgsListTableData = UseMetricsTableData & {
  data: OrgEntryDataEl[] | undefined | null;
};

const useOrgsListTableData = (): UseOrgsListTableData => {
  const { data, ...states } = useFetch<OrgEntryDataEl[]>(
    '/api/debug/orgmeta/org'
  );

  return {
    colDef: OrgEntryDataColDef,
    data: addIdToDataArray(data),
    ...states,
  };
};

export default useOrgsListTableData;
