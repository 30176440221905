export enum lxFonts {
  SYSTEM = 'Poppins',
  HEADING = 'Montserrat',
  DATA = 'Roboto',
}

export enum lxIconFontSize {
  LARGE = '20px',
  MEDIUM = '17px',
  SMALL = '13px',
}
