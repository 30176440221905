import { Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';

type FlexBoxAlignCommonValues =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'start'
  | 'end';
type JustifyContentValues =
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'left'
  | 'right'
  | FlexBoxAlignCommonValues;
type AlignItemsValues =
  | 'stretch'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'self-start'
  | 'self-end'
  | FlexBoxAlignCommonValues;
type FlexWrapValues = 'nowrap' | 'wrap';

type HStackProps = StackProps & {
  children: ReactNode;
  alignItems?: AlignItemsValues;
  justifyContent?: JustifyContentValues;
  flexWrap?: FlexWrapValues;
  columnGap?: string | number;
  rowGap?: string | number;
  fullWidth?: boolean;
};

const makeStack = (direction: 'row' | 'column') => {
  return ({
    children,
    sx,
    spacing = 1,
    alignItems = 'center',
    justifyContent = 'flex-start',
    flexWrap,
    columnGap,
    rowGap,
    fullWidth = false,
  }: HStackProps) => {
    if (fullWidth) {
      sx = {
        width: '100%',
        ...sx,
      };
    }
    return (
      <Stack
        direction={direction}
        alignItems={alignItems}
        spacing={spacing}
        justifyContent={justifyContent}
        flexWrap={flexWrap}
        columnGap={columnGap}
        rowGap={rowGap}
        sx={sx}
      >
        {children}
      </Stack>
    );
  };
};

//stretch
export const HStack = makeStack('row');
export const VStack = makeStack('column');
