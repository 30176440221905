import { materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { LabelledCheckbox } from '../../../../components/Checkboxes';
import {
  AUX_COLUMN_FIELDS,
  EmptyJsonSchema,
  OrgSchema,
  preProcessSchema,
} from '../../../../utils/data/jsonSchema';
import { Org, orgEnabled } from '../../../../utils/types/Entities';
import EditDialog, { EditDialogMode } from './EditDialog';

const ADD_ORG_TITLE = 'Add Org';
const EDIT_ORG_TITLE = 'Edit Org';
const REMOVE_SCHEMA_FIELDS: string[] = ['orgid', 'dcRegion'].concat(
  AUX_COLUMN_FIELDS
);

export type EditOrgDialogProps = {
  mode: EditDialogMode;
  org?: Org;
  orgSchema?: OrgSchema;
  onSave: (org: Org) => Promise<any>;
  onClose: () => any;
};

const EditOrgDialog = ({
  mode,
  org,
  orgSchema,
  onSave,
  onClose,
}: EditOrgDialogProps) => {
  const [orgData, setOrgData] = useState(org ?? {});
  const [isEnabled, setEnabled] = useState(org ? orgEnabled(org) : true);

  const validateForm = () => {
    return true;
  };

  const saveOrg = async () => {
    await onSave({
      ...(orgData as Org),
      enabled: isEnabled ? 1 : 0,
    });
  };

  return (
    <EditDialog
      title={mode === 'edit' ? EDIT_ORG_TITLE : ADD_ORG_TITLE}
      validate={validateForm}
      onSave={saveOrg}
      onClose={onClose}
      maxWidth={'sm'}
    >
      {orgSchema ? (
        <JsonForms
          schema={
            preProcessSchema(
              orgSchema ?? EmptyJsonSchema,
              REMOVE_SCHEMA_FIELDS
            ) ?? {}
          }
          data={orgData}
          renderers={materialRenderers}
          onChange={({ data, errors }) => setOrgData(data)}
        />
      ) : (
        <CircularProgress size={50} />
      )}
      <LabelledCheckbox
        label={'Org Enabled'}
        checked={isEnabled}
        onValueChanged={(checked) => setEnabled(checked)}
      />
    </EditDialog>
  );
};

export default EditOrgDialog;
