import LxAvatar from '../../../features/common/avatars/LxAvatar';
import { HStack, VStack } from '../../../features/common/structures/Stacks';
import RatingSection from '../../../features/common/RatingSection';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  getTrendArrowIcon,
  getTrendColor,
  getTrendText,
} from '../../../features/common/icons/Arrows';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import RatingIcons from '../../../features/common/icons/RatingIcons';

type SankeyModalProps = {};

const SankeyModal = (props: SankeyModalProps) => {
  return (
    <VStack sx={{ alignItems: 'start' }}>
      <HStack sx={{ justifyContent: 'space-between', alignSelf: 'stretch' }}>
        <LxAvatar label={'Samuel'} variant={'medium'} avatar={''} />
        <SwapHorizIcon />
        {/*import SwapHorizIcon from '@mui/icons-material/SwapHoriz';*/}
        <LxAvatar label={'Samuel'} variant={'medium'} avatar={''} />
      </HStack>
      <VStack>
        <LxTypography variant={LxTypographyTypes.CARD_SUB_HEADING_SECONDARY}>
          Communicating
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.DATA_1}>
          Since June 2021
        </LxTypography>
      </VStack>
      <HStack>
        <RatingSection
          title={'Tone'}
          trendIcon={getTrendArrowIcon(50)}
          trendText={'Positive 2%'}
          ratings={<></>}
        />
      </HStack>
      <HStack>
        <RatingSection
          title={'Frequency'}
          color={getTrendColor(24)}
          trendIcon={getTrendArrowIcon(24)}
          trendText={getTrendText(24, true)}
          ratings={<RatingIcons rating={2} variant={'mail'} />}
        />
      </HStack>
      <HStack>
        <RatingSection
          title={'Meetings'}
          color={getTrendColor(-32)}
          trendIcon={getTrendArrowIcon(-32)}
          trendText={getTrendText(-32, true)}
          ratings={<RatingIcons rating={2} variant={'calendar_checkmark'} />}
        />
      </HStack>
    </VStack>
  );
};
export default SankeyModal;
