import {
  Chip,
  SvgIcon,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { lxColors } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

type BackgroundedLabelProps = {
  Icon?: typeof SvgIcon;
  label: string;
  sx?: SxProps<Theme>;
};

const BackgroundedLabel = ({ Icon, label, sx }: BackgroundedLabelProps) => {
  const theme = useTheme();

  return (
    <Chip
      sx={{
        ml: 0,
        borderRadius: 1,
        background: 'linear-gradient(90deg, #f0f4ff 32%, #f0fcff 100% )',
        ...sx,
      }}
      icon={
        Icon ? (
          <Icon sx={{ fontSize: 16, fill: lxColors.icons.label.color }} />
        ) : (
          <></>
        )
      }
      label={
        <Typography
          fontFamily={lxFonts.SYSTEM}
          color={theme.palette.secondary.main}
          fontSize={13}
        >
          {label}
        </Typography>
      }
    />
  );
};
export default BackgroundedLabel;
