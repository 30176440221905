export const addIdToDataArray = (data: any[] | undefined): any[] => {
  if (!data) {
    return [];
  }

  return data.map((el: any, i: number) => {
    el['id'] = i;
    return el;
  });
};
