import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="about">
      <div>About Page</div>
      <div>
        <Link to={'/'}>Go back to root "/"</Link>
      </div>
    </div>
  );
};
export default About;
