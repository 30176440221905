import { lxFonts } from '../utils/LxFonts';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Stack, Typography } from '@mui/material';
import AlignedIconsAndText from '../pages/signup/common/AlignedIconsAndText';
import { lxColors } from '../utils/LxColors';
import LxCard from '../features/common/card/LxCard';

const securityCardStyle = {
  fontFamily: lxFonts.SYSTEM,
  fontSize: 22,
  sx: {
    color: '#858585',
  },
};

type SensitivityCardProps = {
  title: string;
  infoItems: {
    visible: boolean;
    name: string;
  }[];
};

const SensitivityInfoCard = ({ title, infoItems }: SensitivityCardProps) => {
  const alignedIconsAndTextProps = infoItems.map((infoItem) => {
    return {
      icon: infoItem.visible ? (
        <VisibilityIcon sx={{ color: lxColors.icons.visibleOption.color }} />
      ) : (
        <VisibilityOffIcon
          sx={{ color: lxColors.icons.invisibleOption.color }}
        />
      ),
      textElement: (
        <Typography
          sx={{
            color: infoItem.visible
              ? lxColors.icons.visibleOption.color
              : lxColors.infoBox.font.description.color,
          }}
        >
          {infoItem.name}
        </Typography>
      ),
    };
  });

  return (
    <LxCard width={1} minHeight={'250px'} sx={{ p: 3 }}>
      <Stack direction={'column'} width={1}>
        <Typography {...securityCardStyle} mb={2}>
          {title}
        </Typography>
        <AlignedIconsAndText
          iconAndTextArray={alignedIconsAndTextProps}
          sx={{ rowGap: 3, width: 1 }}
        />
      </Stack>
    </LxCard>
  );
};

export default SensitivityInfoCard;
