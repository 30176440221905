import { Grid, Stack, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RoomIcon from '@mui/icons-material/Room';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TabComponent from '../../features/common/structures/tabs/Tabs';
import moment from 'moment';
import { PlatformNames } from '../../features/common/Constants';
import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CalendarIcon from '../../features/common/icons/CalendarIcon';
import { Employee } from '../../utils/types/Entities';

type MeetingInfoProps = {
  title: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  meetingPlatform: PlatformNames;
  members: Employee[];
};

const InfoGridItem = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Grid item xs container alignItems={'center'}>
      {children}
    </Grid>
  );
};

const tabContent = () => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize={14}>
          Hey guys <br />
          <br />
          We recently discovered that the initial implementation, specially for
          our London stores had errors. We need to discuss and resolve this
          ASAP.
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: '#cccccc', mb: 2 }}></Box>
      <Stack direction={'column'}>
        <Typography mb={2} fontSize={25} color={'#0248c2'}>
          Microsoft Teams Meeting
        </Typography>
        <Typography mb={2} fontSize={14}>
          Join on your computer or mobile app
        </Typography>
        <Typography mb={2} fontSize={14}>
          Click here to join the meeting
        </Typography>
        <Typography fontSize={14}>Meeting ID: 293 975 169 120</Typography>
        <Typography fontSize={14}>Passcode: KA6PgS</Typography>
        <Typography fontSize={14}>Download Teams | Join on the web</Typography>
      </Stack>
    </>
  );
};

const TabComponentProps = {
  tabLabels: ['Team Meeting', 'Meeting Details'],
  tabContents: [tabContent(), tabContent()],
};

const MeetingInfo = ({
  title,
  startTime,
  endTime,
  meetingPlatform,
  members,
}: MeetingInfoProps): JSX.Element => {
  return (
    <Grid
      container
      direction={'row'}
      rowSpacing={2}
      sx={{ border: 1, borderColor: '#cccccc', p: 2 }}
    >
      <Grid item container direction={'row'} p={'0 !important'} gap={2}>
        <Grid
          item
          xs={0.5}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CalendarIcon width={'50px'} />
        </Grid>
        <InfoGridItem>
          <Typography fontSize={20}>{title}</Typography>
        </InfoGridItem>
      </Grid>

      <Grid item container pl={'0 !important'} gap={2}>
        <Grid
          item
          xs={0.5}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ScheduleIcon sx={{ color: '#949494' }} />
        </Grid>
        <InfoGridItem>
          {startTime.day() === endTime.day() ? (
            <Typography fontSize={14}>{`${startTime.format(
              'ddd MM/DD/YYYY hh:mm A'
            )} - ${endTime.format('hh:mm A')}`}</Typography>
          ) : (
            <Typography fontSize={14}>{`${startTime.format(
              'ddd MM/DD/YYYY hh:mm A'
            )} - ${endTime.format('ddd MM/DD/YYYY hh:mm A')}`}</Typography>
          )}
        </InfoGridItem>
      </Grid>

      <Grid item container pl={'0 !important'} gap={2}>
        <Grid
          item
          xs={0.5}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <RoomIcon sx={{ color: '#949494' }} />
        </Grid>
        <InfoGridItem>
          <Typography fontSize={14}>{meetingPlatform}</Typography>
        </InfoGridItem>
      </Grid>

      <Grid item container pl={'0 !important'} mb={2} gap={2}>
        <Grid
          item
          xs={0.5}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <PersonOutlineOutlinedIcon sx={{ color: '#949494' }} />
        </Grid>
        <InfoGridItem>
          <Typography fontSize={14}>
            {members.map((member) => member.name).join(', ')}
          </Typography>
        </InfoGridItem>
      </Grid>

      <TabComponent {...TabComponentProps} />
    </Grid>
  );
};

export default MeetingInfo;
