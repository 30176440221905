import { MouseEvent, useState } from 'react';
import { IconButton } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import CustomPopper from './CustomPopper';

type IconButtonWithPopoverProps = {
  icon: SvgIconComponent;
  popperContent: JSX.Element;
};

const IconButtonWithPopper = ({
  icon: Icon,
  popperContent,
}: IconButtonWithPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Icon color={'info'} />
      </IconButton>
      <CustomPopper anchorEl={anchorEl} onClickAway={() => setAnchorEl(null)}>
        {popperContent}
      </CustomPopper>
    </>
  );
};

export default IconButtonWithPopper;
