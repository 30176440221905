import { SvgIconComponent } from '@mui/icons-material';
import { Button } from '@mui/material';
import { LxSize } from '../../../utils/LxGenericTypes';
import { lxColorsNew } from '../../../utils/LxColors';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import { HStack } from '../structures/Stacks';
import { lxIconFontSize } from '../../../utils/LxFonts';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

type LxChipProps = {
  label?: string | ReactNode;
  size: LxSize;
  variant: 'primary' | 'secondary';
  onClick?: () => void;
  prefixLabel?: string;
  postfixLabel?: string;
  icon?: SvgIconComponent;
  value?: number;
  transparent?: boolean;
  outlined?: boolean;
  filled?: boolean;
};

type LxChipStyles = {
  buttonStyles: SxProps<Theme>;
  iconStyles: SxProps<Theme>;
  typographyStyles: SxProps<Theme>;
};

const getLxChipsStyles = (lxChipProps: LxChipProps): LxChipStyles => {
  const { size, variant, transparent, outlined, filled } = lxChipProps,
    isPrimary = variant === 'primary';

  const height = (() => {
    switch (size) {
      case 'large':
        return '34px';
      case 'medium':
        return '27px';
      case 'small':
        return '20px';
    }
  })();

  const backgroundColor = (() => {
    if (transparent) {
      return 'transparent';
    } else if (!isPrimary) {
      return lxColorsNew.blue.dark;
    } else if (filled) {
      return lxColorsNew.orange.dark;
    } else {
      return lxColorsNew.orange.light;
    }
  })();

  const iconColor = (() => {
    if (!isPrimary) {
      return lxColorsNew.blue.dark;
    } else if (filled) {
      return lxColorsNew.white;
    } else {
      return lxColorsNew.orange.dark;
    }
  })();

  const typographyColor = (() => {
    if (!isPrimary) {
      return lxColorsNew.blue.dark;
    } else if (filled) {
      return lxColorsNew.white;
    } else {
      return lxColorsNew.orange.dark;
    }
  })();

  return {
    buttonStyles: {
      height,
      backgroundColor,
      backgroundImage: isPrimary
        ? undefined
        : lxColorsNew.chip.secondary.backgroundImage,
      border: outlined ? `1px solid ${lxColorsNew.orange.dark}` : 'none',
      px: size === 'small' ? 0.5 : 2,
      width: 'fit-content',
      borderRadius: '4px',
    },
    iconStyles: {
      color: iconColor,
      fontSize: `${lxIconFontSize.SMALL} !important`,
    },
    typographyStyles: {
      color: typographyColor,
    },
  };
};

const LxChip = (lxChipProps: LxChipProps): JSX.Element => {
  const {
    label,
    prefixLabel,
    postfixLabel,
    icon: Icon,
    value,
    onClick,
  } = lxChipProps;
  const styles = getLxChipsStyles(lxChipProps);
  return (
    <Button onClick={onClick} disabled={!onClick} sx={styles.buttonStyles}>
      <HStack spacing={1}>
        {prefixLabel && (
          <LxTypography variant={LxTypographyTypes.CHIP_LABEL}>
            {prefixLabel}
          </LxTypography>
        )}

        {Icon && <Icon sx={styles.iconStyles} />}

        {label && (
          <LxTypography
            variant={LxTypographyTypes.CHIP_PRIMARY}
            sx={styles.typographyStyles}
          >
            {label}
          </LxTypography>
        )}

        {postfixLabel && (
          <LxTypography variant={LxTypographyTypes.CHIP_LABEL}>
            {postfixLabel}
          </LxTypography>
        )}

        {value && (
          <LxTypography
            variant={LxTypographyTypes.CHIP_VALUE}
            sx={{ color: lxColorsNew.orange.regular }}
          >
            {value}
          </LxTypography>
        )}
      </HStack>
    </Button>
  );
};

export default LxChip;
