import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuButton from './MenuButton';

type MenuBarProps = {
  menuButtons: {
    name: string;
    onClick: () => void;
  }[];
};

const MenuBar = ({ menuButtons }: MenuBarProps): JSX.Element => {
  const [selectedButtonI, setSelectedButtonI] = useState(0);

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 0,
        borderBottom: 2,
        borderBottomColor: 'primary.main',
        bgcolor: 'white',
        mb: 4,
      }}
    >
      {/*todo: change the hard coding here, the 100% is not working because of the default min height properties*/}
      <Toolbar
        sx={{
          alignItems: 'center',
          height: '45px !important',
          minHeight: '45px !important',
          p: '0 !important',
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        {menuButtons.map((button, index) => {
          return (
            <MenuButton
              key={index}
              text={button.name}
              selected={index === selectedButtonI}
              onClick={() => {
                button.onClick();
                setSelectedButtonI(index);
              }}
              sx={{
                fontSize: 13,
              }}
            />
          );
        })}
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
