import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Breakpoint,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const DEFAULT_VALIDATION_ERROR_MESSAGE: string =
  'Form has validation errors. Check that all required fields are filled.';

export type EditDialogMode = 'add' | 'edit';

export type EditDialogProps = {
  maxWidth?: Breakpoint;
  title?: string;
  validate: () => boolean;
  validationErrorMessage?: string;
  onSave: () => Promise<any>;
  onClose: () => any;
} & PropsWithChildren;

const EditDialog = ({
  maxWidth,
  title,
  children,
  validate,
  validationErrorMessage,
  onSave,
  onClose,
}: EditDialogProps) => {
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const save = useCallback(async () => {
    try {
      await onSave();
      setSaving(false);
      onClose();
    } catch (error) {
      setErrorMessage('' + error);
      setSaving(false);
    }
  }, [onSave, onClose]);

  useEffect(() => {
    if (isSaving) {
      save();
    }
  }, [validate, save, isSaving]);

  return (
    <Dialog open onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {errorMessage !== '' && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {isSaving && <CircularProgress size={20} />}
        <Button
          onClick={() => {
            if (validate()) {
              setErrorMessage('');
              setSaving(true);
            } else {
              setErrorMessage(
                validationErrorMessage ?? DEFAULT_VALIDATION_ERROR_MESSAGE
              );
            }
          }}
          disabled={isSaving}
        >
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
