import { useState } from 'react';
import { Actor, BaseActor } from '../../utils/types/Entities';
import { ActorSchema } from '../../utils/data/jsonSchema';
import {
  AdminTable,
  GetAdminTableRowsProps,
  useAlertMessage,
} from './components/AdminTable';
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog';
import EditActorDialog from './components/dialog/EditActorDialog';
import { AdminActorsGridColumns } from './components/AdminActorsData';
import useFetch from '../../utils/data/useFetch';
import { postUrl, postData } from '../../utils/data/fetchUtils';
import { GridSortModel } from '@mui/x-data-grid';
import { rowData } from '../../components/metricstable/MetricsTableTypes';

const ADMIN_ACTORS_HEADING = 'All Actors';
const ACTORS_URL = '/api/debug/orgcrud/actors';
const ACTORS_SCHEMA_URL = '/api/debug/orgcrud/actors/schema';
const RESET_PASSWORD_URL = '/api/debug/actor/password/reset';
const DEFAULT_SORT: GridSortModel = [{ field: 'actorEmail', sort: 'asc' }];

const getActorRows = ({
  data: actors,
  filterBy,
}: GetAdminTableRowsProps<Actor>): rowData[] => {
  return actors
    .map((actor) => {
      return {
        ...actor,
        id: actor.actorId ?? '',
      };
    })
    .filter(
      (actor) =>
        filterBy.trim() === '' ||
        actor.actorEmail.indexOf(filterBy.trim()) !== -1
    );
};

const AdminActors = () => {
  const [actor, setActor] = useState<Actor>();
  const [isAddingActor, setAddingActor] = useState(false);
  const [isEditingActor, setEditingActor] = useState(false);
  const [isResettingPassword, setResettingPassword] = useState(false);
  const [numSaves, setNumSaves] = useState(0);
  const {
    alertMessage,
    alertMessageType,
    alertMessageHideDelay,
    showAlertMessage,
    clearAlertMessage,
  } = useAlertMessage();

  const { data: actorSchema } = useFetch<ActorSchema>(ACTORS_SCHEMA_URL);

  const onActorDialogSaved = async (actor: BaseActor) => {
    const response = await postData(ACTORS_URL, actor);
    if (response.ok) {
      showAlertMessage(`Saved actor ${actor.actorEmail}. `, 'success');
      setNumSaves(numSaves + 1);
    } else {
      let error = `Error ${response.status} saving actor ${actor.actorEmail}. `;

      switch (response.status) {
        case 400:
          error += 'Check that all required fields are filled.';
          break;
        case 409:
          error += `Actor with same email address already exists.`;
          break;
        case 500:
          error += 'Server error occurred.';
          break;
        default:
          break;
      }

      throw error;
    }
  };

  const onAddActor = () => {
    clearAlertMessage();
    setAddingActor(true);
  };

  const onEditActor = (actor: Actor) => {
    clearAlertMessage();
    setActor(actor);
    setEditingActor(true);
  };

  const onResetPassword = (actor: Actor) => {
    clearAlertMessage();
    setActor(actor);
    setResettingPassword(true);
  };

  const onResetPasswordConfirm = async (actor: Actor) => {
    const response = await postUrl(
      `${RESET_PASSWORD_URL}?email=${actor.actorEmail}`
    );
    if (response.ok) {
      const password = ((await response.json()) as { password: string })
        .password;
      // Alert message won't be hidden after delay to allow user to copy password freely.
      showAlertMessage(
        `Reset actor ${actor.actorEmail} password to "${password}" successfully.`,
        'success',
        0
      );
    } else {
      showAlertMessage(
        `Error ${response.status} resetting actor ${actor.actorEmail} password.`,
        'error'
      );
    }
  };

  return (
    <>
      <AdminTable
        heading={ADMIN_ACTORS_HEADING}
        dataUrl={ACTORS_URL}
        columns={AdminActorsGridColumns({
          onEdit: onEditActor,
          onResetPassword: onResetPassword,
        })}
        getRows={getActorRows}
        refresh={numSaves}
        onAdd={onAddActor}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        alertMessageHideDelay={alertMessageHideDelay}
        initialSort={DEFAULT_SORT}
        rowHeight={120}
      />
      {isAddingActor && (
        <EditActorDialog
          mode="add"
          actorSchema={actorSchema}
          onSave={onActorDialogSaved}
          onClose={() => setAddingActor(false)}
        />
      )}
      {isEditingActor && (
        <EditActorDialog
          mode="edit"
          actor={actor}
          actorSchema={actorSchema}
          onSave={onActorDialogSaved}
          onClose={() => setEditingActor(false)}
        />
      )}
      {isResettingPassword && (
        <ConfirmationDialog
          title="Reset Password"
          text={`Are you sure you want to reset user ${actor?.actorEmail} password?`}
          onConfirm={() => {
            actor && onResetPasswordConfirm(actor);
            setResettingPassword(false);
          }}
          onCancel={() => setResettingPassword(false)}
        />
      )}
    </>
  );
};

export default AdminActors;
