import { alpha, Grid, useTheme } from '@mui/material';
import { blueBoxBorder } from '../../features/common/styles/BoxSx';
import UpcomingTasksColumn, {
  UpcomingTasksColumnProps,
} from './UpcomingTasksColumn';

export type UpcomingTasksProps = {
  columns: UpcomingTasksColumnProps[];
};

const UpcomingTasks = ({ columns }: UpcomingTasksProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Grid
      container
      columns={3}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        ...blueBoxBorder,
        borderColor: alpha(theme.palette.info.light, 0.1),
      }}
    >
      {columns.map((column) => (
        <Grid item direction={'column'} xs={1} px={3}>
          <UpcomingTasksColumn title={column.title} tasks={column.tasks} />
        </Grid>
      ))}
    </Grid>
  );
};

export default UpcomingTasks;
