export enum ShareLevelEnum {
  USER = 'You',
  TEAM = 'Your team',
  COLLABORATORS = 'Your Collaborators',
  ORGANIZATION = 'Your Organization',
}

export enum AccessLevelEnum {
  EVERYTHING = 'Everything',
  NONSENSITIVE = 'Non-Sensitive',
  REDACTED = 'Redacted',
  NOTHING = 'Nothing',
}
