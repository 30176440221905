import { Box, Divider, Stack, Typography } from '@mui/material';
import LxCard from '../features/common/card/LxCard';
import { HStack } from '../features/common/structures/Stacks';
import LxTile from '../features/common/tiles/LxTile';
import GridViewIcon from '@mui/icons-material/GridView';
import LxTypography from '../components/lxTypography/LxTypography';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LxTypographyTypes } from '../components/lxTypography/lxTypographyStyles';
import { lxColorsNew } from '../utils/LxColors';
import LxChip from '../features/common/chips/LxChip';
import DescriptionIcon from '@mui/icons-material/Description';
import MovingIcon from '@mui/icons-material/Moving';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {
  AsteriskIcon,
  WindowsIcon,
  WonkaIcon,
} from '../features/common/icons/SvgIcons';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EmailIcon from '@mui/icons-material/Email';
import { LxAvatar } from '../features/common/avatars/LxAvatar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import { LxCardTypes } from '../features/common/card/LxCardStyles';

// Temporary shared styles just for this page
const tempHeaderSx = {
  fontFamily: 'Poppins',
  color: '#3599D4',
  fontWeight: 500,
  fontSize: '20px',
  mb: '20px',
};

const dividerSx = {
  mt: '20px !important',
  mb: '20px !important',
};

const lxCardStyles = {
  height: '100px',
  width: '200px',
};

const avatarSrc = 'https://avatars.githubusercontent.com/u/120482615?v=4';

const DesignSystem = (): JSX.Element => {
  return (
    <>
      <Stack spacing={1}>
        <Typography sx={tempHeaderSx}>Headings</Typography>
        <LxTypography variant={LxTypographyTypes.HEADING}>
          Discussion Threads
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.SUBHEADING}>
          Discussion Threads
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.CARD_HEADING}>
          Messages
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.CARD_SUB_HEADING_PRIMARY}>
          Smart Filter
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.CARD_SUB_HEADING_SECONDARY}>
          Type
        </LxTypography>
        <Divider sx={dividerSx} />
        <Typography sx={tempHeaderSx}>Titles</Typography>

        <LxTypography variant={LxTypographyTypes.TITLE_1}>
          Wonka Industries
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.TITLE_2}>Samuel</LxTypography>
        <LxTypography variant={LxTypographyTypes.TITLE_3}>
          Compliance
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.TITLE_4}>
          Responsiveness
        </LxTypography>
        <Divider sx={dividerSx} />
        <Typography sx={tempHeaderSx}>Data</Typography>

        <LxTypography variant={LxTypographyTypes.DATA_1}>
          Business Operations
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.DATA_2}>
          Communication
        </LxTypography>
        <LxTypography variant={LxTypographyTypes.DATA_3}>down 15%</LxTypography>
        <LxTypography variant={LxTypographyTypes.DATA_DESCRIPTION}>
          Thanks for the kick-off call. Please find attached all the creatives
          and requirements.
        </LxTypography>
        <Divider sx={dividerSx} />
        <Typography sx={tempHeaderSx}>Primary Chip - Large</Typography>
        <LxChip variant={'primary'} label="Legal" size={'large'} value={18} />
        <LxChip
          variant={'primary'}
          icon={MovingIcon}
          label="Decision Maker"
          size={'large'}
          outlined
          transparent
        />
        <LxChip
          variant={'primary'}
          label="Legal"
          postfixLabel={'Contract. NDA. EULA. Service'}
          size={'large'}
        />
        <LxChip
          variant={'primary'}
          icon={MovingIcon}
          label="Decision Maker"
          size={'large'}
          value={18}
          outlined
        />
        <Divider sx={dividerSx} />
        <Typography sx={tempHeaderSx}>Primary Chip - Medium</Typography>
        <LxChip variant={'primary'} label="Legal" size={'medium'} value={18} />
        <LxChip
          variant={'primary'}
          icon={DescriptionIcon}
          label="Invoices"
          size={'medium'}
          value={18}
          transparent
        />
        <LxChip
          variant={'primary'}
          icon={DescriptionIcon}
          label="Legal"
          size={'medium'}
          value={18}
        />
        <LxChip
          variant={'primary'}
          label="Legal"
          size={'medium'}
          value={18}
          outlined
          transparent
        />
        <LxChip
          variant={'primary'}
          icon={DescriptionIcon}
          label="Invoices"
          size={'medium'}
          value={18}
          transparent
        />
        <LxChip
          variant={'primary'}
          icon={DescriptionIcon}
          label="Legal"
          size={'medium'}
          value={18}
          outlined
        />
        <LxChip variant={'primary'} label="Legal GDPR" size={'medium'} filled />
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Primary Chip - Small</Typography>
        <LxChip variant={'primary'} label="Legal" size={'small'} />
        <LxChip
          variant={'primary'}
          icon={AsteriskIcon}
          label="Document"
          size={'small'}
        />
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Secondary Chip - Large</Typography>

        <LxChip variant={'secondary'} label="Email" size={'large'} />
        <LxChip
          variant={'secondary'}
          icon={AlternateEmailIcon}
          label="Email"
          size={'large'}
        />
        <LxChip
          variant={'secondary'}
          label="Document"
          postfixLabel={'Contract. NDA. EULA.'}
          size={'large'}
        />
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Secondary Chip - Medium</Typography>

        <LxChip
          variant={'secondary'}
          label={
            <LxAvatar
              variant={'small'}
              label={'Samuel'}
              postLabel={'Wonka'}
              avatar={avatarSrc}
            />
          }
          size={'medium'}
        />
        <LxChip
          variant={'secondary'}
          prefixLabel={'WITH'}
          label={
            <LxAvatar
              variant={'small'}
              label={'Samuel Reily'}
              avatar={avatarSrc}
            />
          }
          size={'medium'}
        />

        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Secondary Chip - Small</Typography>

        <LxChip
          variant={'secondary'}
          icon={EmailIcon}
          label="Email"
          size={'small'}
        />

        <LxChip variant={'secondary'} label="Email" size={'small'} />

        <HStack>
          <LxChip
            variant={'secondary'}
            icon={PictureAsPdfIcon}
            postfixLabel={'PO'}
            size={'small'}
          />
          <LxChip
            variant={'secondary'}
            icon={ArticleIcon}
            postfixLabel={'RFP'}
            size={'small'}
          />
        </HStack>

        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Avatars - Title</Typography>
        <LxAvatar
          label={'Wonka Industries'}
          variant={'title'}
          avatar={WonkaIcon}
        />
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Avatars - Large</Typography>

        <LxAvatar
          label={'Wonka'}
          variant={'large'}
          avatar={'https://avatars.githubusercontent.com/u/2603730?v=4'}
        />

        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Avatars - Medium</Typography>

        <LxAvatar
          label={'Wonka Industries'}
          variant={'medium'}
          avatar={WindowsIcon}
        />

        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Avatars - Small</Typography>
        <HStack>
          <LxAvatar
            variant={'small'}
            label={'Samuel'}
            postLabel={'Wonka'}
            avatar={'https://avatars.githubusercontent.com/u/120482615?v=4'}
          />
          <LxAvatar
            label={'Bob R'}
            variant={'bordered'}
            sizePercentage={0.5}
            avatar={
              'https://i.picsum.photos/id/167/200/300.jpg?hmac=ZAuGlRPlSv0i_JnJr4FFW-OPsVz5bTx8mAI_qUYP_bM'
            }
          />
        </HStack>
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Status</Typography>
        <HStack>
          <Box
            sx={{
              borderRadius: '100%',
              backgroundColor: lxColorsNew.status.green,
              height: '30px',
              width: '30px',
            }}
          >
            &nbsp;
          </Box>
          <Box
            sx={{
              borderRadius: '100%',
              backgroundColor: lxColorsNew.status.orange,
              height: '30px',
              width: '30px',
            }}
          >
            &nbsp;
          </Box>
          <Box
            sx={{
              borderRadius: '100%',
              backgroundColor: lxColorsNew.status.red,
              height: '30px',
              width: '30px',
            }}
          >
            &nbsp;
          </Box>
          <Box
            sx={{
              borderRadius: '100%',
              backgroundColor: lxColorsNew.status.blue,
              height: '30px',
              width: '30px',
            }}
          >
            &nbsp;
          </Box>
          <Box
            sx={{
              borderRadius: '100%',
              backgroundColor: lxColorsNew.status.yellow,
              height: '30px',
              width: '30px',
            }}
          >
            &nbsp;
          </Box>
        </HStack>
        <Divider sx={dividerSx} />
        <Typography sx={tempHeaderSx}>Tiles</Typography>
        <HStack>
          <LxTile
            variant={'large'}
            label={'NDA'}
            icon={VisibilityOffIcon}
            value={2}
            selected
          />
          <LxTile
            variant={'large'}
            label={'MSA'}
            icon={GridViewIcon}
            value={2}
          />
        </HStack>
        <HStack>
          <LxTile
            selected={true}
            variant={'medium'}
            label={'All'}
            icon={GridViewIcon}
          />
          <LxTile variant={'medium'} label={'Files'} icon={GridViewIcon} />
          <LxTile variant={'medium'} label={'Analytics'} icon={GridViewIcon} />
        </HStack>
        <HStack>
          <LxTile
            variant={'small'}
            label={'90 days'}
            icon={CalendarMonthIcon}
          />
        </HStack>
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Cards</Typography>
        <HStack>
          <LxCard variant={LxCardTypes.Navigation} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.SearchResult} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.Data} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.DataGreen} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.DataRed} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.Orange} sx={lxCardStyles} />
          <LxCard variant={LxCardTypes.ModalOverlay} sx={lxCardStyles} />
        </HStack>
        <Divider sx={dividerSx} />

        <Typography sx={tempHeaderSx}>Date Range Picker</Typography>
        <HStack>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRangePicker
              localeText={{ start: 'Start Time', end: 'End Time' }}
            />
          </LocalizationProvider>
        </HStack>
        <Divider sx={dividerSx} />
      </Stack>
    </>
  );
};

export default DesignSystem;
