import { Button, CircularProgress } from '@mui/material';
import ResetIcon from '@mui/icons-material/RestartAlt';
import {
  BaseDefaultColumnStyle,
  MetricsTableCol,
} from '../../../components/metricstable/MetricsTableTypes';
import { HStack, VStack } from '../../../features/common/structures/Stacks';
import {
  Actor,
  actorEnabled,
  actorIsDebug,
  OrgRoleMap,
} from '../../../utils/types/Entities';
import LxChip from '../../../features/common/chips/LxChip';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import { UpdatedMetricsTableCol } from './tablecolumn/UpdatedGridColumn';
import useFetch from '../../../utils/data/useFetch';

const ACTOR_ORGS_URL = '/api/debug/actor/orgs';

type ActorCallback = (actor: Actor) => void;

const EmailGridColumn = ({
  emailAddress,
  loginType,
}: {
  emailAddress: string;
  loginType: string;
}) => {
  const { data: orgs, isLoading } = useFetch<OrgRoleMap>(
    `${ACTOR_ORGS_URL}?email=${emailAddress}`
  );

  return (
    <VStack>
      <LxTypography variant={LxTypographyTypes.DATA_1}>
        {emailAddress}
      </LxTypography>
      <LxTypography
        variant={LxTypographyTypes.DATA_2}
      >{`ActorLoginEnum.${loginType}`}</LxTypography>
      <LxTypography variant={LxTypographyTypes.DATA_3}>
        {isLoading ? (
          <>
            {'Loading orgs ...'}
            <CircularProgress size={15} />
          </>
        ) : (
          `Access to ${Object.keys(orgs ?? {}).length} orgs ${Object.keys(
            orgs ?? {}
          ).join(', ')}`
        )}
      </LxTypography>
    </VStack>
  );
};

const StatusGridColumn = ({
  enabled,
  hasDebugAuth,
}: {
  enabled: boolean;
  hasDebugAuth: boolean;
}) => {
  return (
    <VStack>
      <LxChip
        variant={'primary'}
        label={enabled ? 'ENABLED' : 'DISABLED'}
        size={'small'}
        outlined
      />
      {hasDebugAuth && (
        <LxChip variant={'primary'} label="DEBUG" size={'small'} filled />
      )}
    </VStack>
  );
};

const EditGridColumn = ({
  actor,
  onEdit,
  onResetPassword,
}: {
  actor: Actor;
  onEdit: ActorCallback;
  onResetPassword: ActorCallback;
}) => {
  return (
    <>
      <VStack>
        <HStack>
          <Button variant="contained" onClick={() => onResetPassword(actor)}>
            <ResetIcon />
            Reset Password
          </Button>
          <Button variant="contained" onClick={() => onEdit(actor)}>
            Edit
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export const AdminActorsGridColumns = ({
  onEdit,
  onResetPassword,
}: {
  onEdit: ActorCallback;
  onResetPassword: ActorCallback;
}): MetricsTableCol[] => [
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Email Address',
    field: 'actorEmail',
    sortable: true,
    renderCell: ({ row }: { row: Actor }) => {
      const actor: Actor = row;
      const loginType: string = actor.settings?.loginEnum ?? 'PASSWORD_ONLY';

      return (
        <EmailGridColumn
          emailAddress={actor.actorEmail}
          loginType={loginType}
        />
      );
    },
    align: 'center',
  },
  UpdatedMetricsTableCol,
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Status',
    field: 'enabled',
    sortable: true,
    valueGetter: ({ row }: { row: Actor }) => {
      const actor: Actor = row;
      // Map to a number for sorting.
      let value = 0;
      value += actorEnabled(actor) ? 1 : 0;
      value += actorIsDebug(actor) ? 2 : 0;
      return value;
    },
    renderCell: ({ row }: { row: Actor }) => {
      const actor: Actor = row;
      const enabled = actorEnabled(actor);
      const hasDebugAuth = actorIsDebug(actor);
      return <StatusGridColumn enabled={enabled} hasDebugAuth={hasDebugAuth} />;
    },
    maxWidth: 150,
    align: 'center',
  },
  {
    ...BaseDefaultColumnStyle,
    field: 'edit',
    renderHeader: () => <></>,
    renderCell: (params) => (
      <EditGridColumn
        actor={params.row}
        onEdit={onEdit}
        onResetPassword={onResetPassword}
      />
    ),
    align: 'center',
  },
];
