import { Stack } from '@mui/material';
import SectionHeader from '../../../features/common/SectionHeader';
import DiscussionThread from './DiscussionThread';
import { useEffect, useState } from 'react';
import discussionThreadSectionData, {
  DiscussionThreadHighAndLowLights,
} from './DiscussionThreadSectionFetch';

const DiscussionThreadSection = () => {
  const [discussionThreads, setDiscussionThreads] = useState<
    DiscussionThreadHighAndLowLights[] | null
  >();

  useEffect(() => {
    discussionThreadSectionData().then((responseData) => {
      setDiscussionThreads(responseData);
    });
  }, []);

  function getDiscussionThreadJsx() {
    if (!discussionThreads || discussionThreads.length === 0) {
      return null;
    }
    return discussionThreads.map((v, idx) => {
      return <DiscussionThread data={v} key={'discussionThread-' + idx} />;
    });
  }

  return (
    <Stack mt={3} spacing={2.5}>
      <SectionHeader
        sectionTitle={'XXX Discussion Threads: Highlights and Lowlights'}
        side={<></>}
      />
      {getDiscussionThreadJsx()}
    </Stack>
  );
};

export default DiscussionThreadSection;
