import { Box, SvgIcon } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';

type BackgroundedIconProps = {
  icon: typeof SvgIcon;
};

const BackgroundedIcon = ({ icon: Icon }: BackgroundedIconProps) => {
  const { background, secondaryStyle } = lxColors.icons;
  return (
    <Box sx={{ backgroundColor: background.color }}>
      <Icon sx={{ color: secondaryStyle.color }} />
    </Box>
  );
};
export default BackgroundedIcon;
