import { alpha, Stack, useTheme } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { lxColorsNew } from '../../../utils/LxColors';
import { lxIconFontSize } from '../../../utils/LxFonts';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import UnstyledButton from '../buttons/UnstyledButton';

type LxTileLargeProps = {
  label: string;
  icon: SvgIconComponent;
  selected?: boolean;
  onClick?: () => void;
  count?: number;
};

const LxTileLarge = ({
  icon: Icon,
  selected = false,
  onClick,
  label,
  count,
}: LxTileLargeProps): JSX.Element => {
  const theme = useTheme();

  const orangeColor = alpha(lxColorsNew.orange.regular, selected ? 1 : 0.65);

  return (
    <UnstyledButton onClick={onClick} disabled={!onClick}>
      <Stack
        direction={'column'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        spacing={0.5}
        sx={{
          border: 1,
          borderColor: orangeColor,
          boxShadow: `0 0 5px ${lxColorsNew.blue.light}`,
          borderRadius: '2px',
          aspectRatio: '90/64',
          height: '64px',
          width: '88px',
          p: 0.7,
        }}
      >
        {/*Has to be like this in case of 0*/}
        {count !== undefined && (
          <LxTypography
            variant={LxTypographyTypes.TILE}
            sx={{ ml: 'auto', color: lxColorsNew.orange.regular }}
          >
            {count}
          </LxTypography>
        )}
        <Icon
          sx={{
            color: lxColorsNew.orange.regular,
            fontSize: lxIconFontSize.LARGE,
            my: `${theme.spacing(0.8)} !important`,
          }}
        />
        <LxTypography
          variant={LxTypographyTypes.TILE}
          sx={{ color: lxColorsNew.orange.regular }}
        >
          {label}
        </LxTypography>
      </Stack>
    </UnstyledButton>
  );
};

export default LxTileLarge;
