import { useState } from 'react';
import { CircularProgress, Divider, TextField } from '@mui/material';
import {
  BaseActor,
  Actor,
  NewActor,
  ActorSettings,
  actorEnabled,
} from '../../../../utils/types/Entities';
import {
  ActorSchema,
  EmptyJsonSchema,
  preProcessSchema,
} from '../../../../utils/data/jsonSchema';
import EditDialog, { EditDialogMode } from './EditDialog';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';
import { validateEmailAddress } from '../../../../utils/data/validation';
import { LabelledCheckbox } from '../../../../components/Checkboxes';

const ADD_ACTOR_TITLE = 'Add Actor';
const EDIT_ACTOR_TITLE = 'Edit Actor';

type EditActorDialogProps = {
  mode: EditDialogMode;
  actor?: Actor;
  actorSchema?: ActorSchema;
  onSave: (actor: BaseActor) => Promise<any>;
  onClose: () => any;
};

const EditActorDialog = ({
  mode,
  actor,
  actorSchema,
  onSave,
  onClose,
}: EditActorDialogProps) => {
  const [emailAddress, setEmailAddress] = useState(actor?.actorEmail ?? '');
  const [validEmailAddress, setValidEmailAddress] = useState(true);
  const [actorSettings, setActorSettings] = useState<ActorSettings>(
    actor?.settings ?? {}
  );
  const [isEnabled, setEnabled] = useState(actor ? actorEnabled(actor) : true);

  function validateForm() {
    let isValid = emailAddress !== '' && validateEmailAddress(emailAddress);
    setValidEmailAddress(isValid);
    return isValid;
  }

  const saveActor = async () => {
    if (mode === 'edit') {
      // Specify the id of the actor to update.
      await onSave({
        actorId: actor?.actorId,
        actorEmail: emailAddress,
        settings: actorSettings,
        enabled: isEnabled ? 1 : 0,
      } as Actor);
    } else {
      // Actor id is unspecified indicating to the API that a new actor should be created.
      await onSave({
        actorEmail: emailAddress,
        settings: actorSettings,
        enabled: isEnabled ? 1 : 0,
      } as NewActor);
    }
  };

  return (
    <EditDialog
      title={mode === 'edit' ? EDIT_ACTOR_TITLE : ADD_ACTOR_TITLE}
      validate={validateForm}
      onSave={saveActor}
      onClose={onClose}
      maxWidth={'md'}
    >
      <TextField
        variant="filled"
        label="Email Address"
        value={emailAddress}
        error={!validEmailAddress}
        onChange={(e) => {
          setEmailAddress(e.target.value);
          validateForm();
        }}
        placeholder="adam@company.com"
        fullWidth
      />
      <Divider sx={{ marginTop: '1.5em' }}>Settings</Divider>
      {actorSchema ? (
        <JsonForms
          schema={
            preProcessSchema(
              actorSchema?.properties?.settings ?? EmptyJsonSchema
            ) ?? {}
          }
          data={actorSettings}
          renderers={materialRenderers}
          onChange={({ data, errors }) => setActorSettings(data)}
        />
      ) : (
        <CircularProgress size={50} />
      )}
      <LabelledCheckbox
        label={'User Enabled'}
        checked={isEnabled}
        onValueChanged={(checked) => setEnabled(checked)}
      />
    </EditDialog>
  );
};

export default EditActorDialog;
