import { Typography } from '@mui/material';
import { lxFonts } from '../../../utils/LxFonts';
import { lxColors } from '../../../utils/LxColors';

type SignUpTitleProps = {
  text: string;
};

const SignUpTitle = ({ text }: SignUpTitleProps): JSX.Element => {
  return (
    <Typography
      textTransform={'uppercase'}
      fontSize={18}
      fontFamily={lxFonts.HEADING}
      color={lxColors.font.signup.grey}
    >
      {text}
    </Typography>
  );
};

export default SignUpTitle;
