import { styled } from '@mui/material';
const Bar = ({
  containerHeight,
  animationProps: {
    barHeight,
    opacity,
    maxBarHeightAcrossSeries,
    showBarSpaceRight,
    barColor,
  },
}: {
  containerHeight: number;
  animationProps: {
    barHeight: number;
    opacity: number;
    maxBarHeightAcrossSeries: number;
    showBarSpaceRight: boolean;
    barColor: string;
  };
}) => {
  const bgColor = barColor;
  // barHeight is data.value , a number
  // we need to remap this number into a value in [0, containerHeight)
  let newBoundedHeight: number =
    containerHeight * (barHeight / maxBarHeightAcrossSeries);
  let paddingRight = '0';
  let borderRadius = 0;
  if (showBarSpaceRight) {
    paddingRight = '1px';
    borderRadius = 3;
  }

  let barStyleObj = {
    borderRadius: borderRadius,
    marginRight: paddingRight,
    backgroundColor: bgColor,
    opacity: opacity,
    /*width: barWidth,*/
    height: newBoundedHeight,
    transform: `translate(0px, ${
      (containerHeight - newBoundedHeight) * 0.5
    }px)`,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    /*experimenting with transition*/
    transition: 'background-color 0.5s ease',

    '&:hover': {
      backgroundColor: '#111',
      cursor: 'pointer',
    },
  };
  let B = styled('div')(barStyleObj);
  return <B />;
};
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

// chartData: [{id, value, name}]
export const ConversationVolumeBarChart = ({
  chartData,
  backgroundColor,
  chartHeight,
  barColor,
  gap = '0px',
}: {
  chartData: any[];
  backgroundColor: string;
  chartHeight: number;
  barColor: string;
  gap?: string;
}) => {
  const containerHeight = chartHeight;
  let maxBarHeightAcrossSeries: number = Math.max(
    ...chartData.map((v) => v.value as number)
  );
  let showBarSpaceRight = chartData.length < 500;
  //console.log("Max in series is " + maxBarHeightAcrossSeries);
  return (
    <div
      style={{
        width: '100%',
        height: containerHeight + 'px',
        backgroundColor: backgroundColor,
      }}
    >
      <div
        style={{
          display: 'flex',
          transform: 'translate(0px, 0px)',
          flexDirection: 'row',
          gap: gap,
        }}
      >
        {chartData.map((data, idx) => {
          return (
            <Bar
              key={'bar-idx-' + idx}
              animationProps={{
                barHeight: data.value,
                opacity: 1,
                maxBarHeightAcrossSeries: maxBarHeightAcrossSeries,
                showBarSpaceRight: showBarSpaceRight,
                barColor: barColor,
              }}
              containerHeight={containerHeight}
            />
          );
        })}
      </div>
    </div>
  );
};
