import { Stack, Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import { lxColors } from '../../utils/LxColors';
import { HStack } from '../../features/common/structures/Stacks';
import Dropdown from '../../features/common/dropdown/Dropdown';
import OrgListTable from '../../components/tables/orglisttable/OrgListTable';
import MetaDataTable from '../../components/tables/metadatatable/MetaDataTable';
import useMetaDataTableData, {
  MetaDataTypes,
} from '../../components/tables/metadatatable/MetaDataTableData';
import useOrgsListTableData from '../../components/tables/orglisttable/OrgListTableData';
import { useState } from 'react';
import ConvDataTable from '../../components/tables/convtable/ConvDataTable';

// Styles
const titleStyle = {
  fontFamily: lxFonts.HEADING,
  fontSize: 28,
  fontWeight: 'bold',
  sx: {
    color: lxColors.font.title.grey,
  },
};

const subTitleStyle = {
  fontFamily: lxFonts.DATA,
  fontSize: 20,
  sx: { color: lxColors.font.title.grey },
};

const AdminData = (): JSX.Element => {
  const orgsListTableData = useOrgsListTableData();
  const orgIds = orgsListTableData.data?.map((org) => org.orgid);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(3);

  // making another api call to get all the tenants -> then getting conv data
  // this data should be stored in context/redux as it's used by multiple components
  // right now keep it simple so that we don't have to change a lot of code later
  // and make the same request twice from the custom hook
  const { data: tenantData, isLoading: tenantDataLoading } =
    useMetaDataTableData(selectedOrgId, MetaDataTypes.WF_TENANTS);

  return (
    <Stack spacing={4} pb={10}>
      <Typography {...titleStyle}>Federated Database</Typography>
      <Typography {...subTitleStyle}>Orgs List Table</Typography>
      <OrgListTable useOrgsListTableData={orgsListTableData} />
      <HStack spacing={0}>
        <Typography {...titleStyle}>MetaData Database</Typography>
        <Dropdown
          label={'Organization ID'}
          options={orgIds || []}
          defaultOption={selectedOrgId}
          callback={(value) => setSelectedOrgId(Number(value))}
          sx={{ ml: 'auto' }}
        />
      </HStack>
      <Typography {...subTitleStyle}>WFTenants Table</Typography>
      <MetaDataTable
        orgId={selectedOrgId}
        metaDataType={MetaDataTypes.WF_TENANTS}
      />
      <Typography {...subTitleStyle}>WFEmployees Table</Typography>
      <MetaDataTable
        orgId={selectedOrgId}
        metaDataType={MetaDataTypes.WF_EMPLOYEE}
      />
      <Typography {...subTitleStyle}>WFMap Table</Typography>
      <MetaDataTable
        orgId={selectedOrgId}
        metaDataType={MetaDataTypes.WF_MAPPING}
      />
      <Typography {...subTitleStyle}>Props Table</Typography>
      <MetaDataTable orgId={selectedOrgId} metaDataType={MetaDataTypes.PROPS} />
      <Typography {...titleStyle}>Conv Database</Typography>
      {tenantDataLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        tenantData?.map((tenantRow) => (
          <Stack spacing={4} key={tenantRow.id}>
            <Typography {...subTitleStyle}>
              Tenant ID: {tenantRow.id}
            </Typography>
            <ConvDataTable orgId={selectedOrgId} wfTenantId={tenantRow.id} />
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default AdminData;
