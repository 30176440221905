import { SvgIconComponent } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';

export type ActorSettings = object & {
  loginEnum?: string;
  debugActor?: boolean;
};

export type BaseActor = {
  actorEmail: string;
  settings: ActorSettings;
  enabled: number;
};

export type Actor = {
  actorId: string;
} & BaseActor;

export type NewActor = {
  actorId?: null; // New actor has id either unspecified or null.
} & BaseActor;

export function actorEnabled(actor: BaseActor) {
  return actor.enabled === 1;
}

export function actorIsDebug(actor: BaseActor) {
  return actor.settings?.debugActor ?? false;
}

export type Employee = {
  name: string;
  avatarSrc: string;
  title?: string;
  subTitle?: string;
};

export type Org = {
  orgid: number;
  orghandle: string;
  dcRegion: string;
  enabled: number;
};

export function orgEnabled(org: Org) {
  return org.enabled === 1;
}

export type OrgSettings = { [key: string]: object } & {
  actorUuidToRoles?: ActorUuidRoleMap;
  googleIntegrationInfoMap?: { [key: string]: object };
  msftIntegrationInfoMap?: { [key: string]: object };
  slackIntegrationInfoMap?: { [key: string]: object };
  workForumPrivacyRulesMap?: {
    [key: string]: object[];
  };
};

export function numGoogleIntegrationInfos(orgSettings: OrgSettings) {
  return Object.keys(orgSettings?.googleIntegrationInfoMap ?? {}).length;
}

export function numMsftIntegrationInfos(orgSettings: OrgSettings) {
  return Object.keys(orgSettings?.msftIntegrationInfoMap ?? {}).length;
}

export function numSlackIntegrationInfos(orgSettings: OrgSettings) {
  return Object.keys(orgSettings?.slackIntegrationInfoMap ?? {}).length;
}

export function numPrivacyRules(orgSettings: OrgSettings) {
  return Object.values(orgSettings?.workForumPrivacyRulesMap ?? {})
    .map((rules) => rules.length)
    .reduce((a, b) => a + b, 0);
}

export type Organization = {
  name: string;
  avatar: string | SvgIconComponent;
};

/**
 * For a given org, map from actor (by email address) to a set of roles.
 */
export type ActorRoleMap = {
  [actorEmail: string]: string[];
};

export type ActorUuidRoleMap = {
  [actorUuid: string]: string[];
};

/**
 * For a given actor, map from org (by orgid) to a set of roles.
 */
export type OrgRoleMap = {
  [orgid: string]: string[];
};

export type PlatformType = {
  name: string;
  icon: SvgIconComponent;
};

export enum Platform {
  ONE_DRIVE = 'ONE_DRIVE',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  OUTLOOK = 'OUTLOOK',
  GMAIL = 'GMAIL',
}

export const PlatformProperties: { [key in Platform]: PlatformType } = {
  [Platform.ONE_DRIVE]: {
    name: 'One Drive',
    icon: MailIcon,
  },
  [Platform.GOOGLE_DRIVE]: {
    name: 'Google Drive',
    icon: MailIcon,
  },
  [Platform.OUTLOOK]: {
    name: 'Outlook',
    icon: MailIcon,
  },
  [Platform.GMAIL]: {
    name: 'Gmail',
    icon: MailIcon,
  },
};
