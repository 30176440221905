import { Box, Grid, Stack, Typography } from '@mui/material';
import PersonPressingButton from '../../assets/personPressingButton.svg';
import Logo from '../../features/logo/Logo';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import { WhiteLeftArrow } from '../../features/common/icons/Arrows';
import { SignUpTextField } from '../../components/TextFields';
import { lxColors } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

const textDefaultColors = lxColors.font.signup.grey;

const SignUp = (): JSX.Element => {
  return (
    <Grid
      container
      direction={'row'}
      height={1}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        item
        xs={6}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box component={'img'} src={PersonPressingButton} />
      </Grid>
      <Grid item xs={6}>
        <Stack
          width={1}
          height={1}
          direction={'column'}
          spacing={5}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo />
          <Typography
            textTransform={'uppercase'}
            fontSize={18}
            fontFamily={lxFonts.HEADING}
            color={textDefaultColors}
          >
            Sign Up
          </Typography>
          <Typography
            fontSize={28}
            fontFamily={lxFonts.HEADING}
            fontWeight={'medium'}
            color={textDefaultColors}
          >
            Let's get started
          </Typography>
          <Typography
            fontSize={16}
            fontFamily={lxFonts.SYSTEM}
            color={textDefaultColors}
          >
            Just a few steps to get onboard with AI way of working
          </Typography>
          <SignUpTextField
            size={'small'}
            variant={'outlined'}
            placeholder={'Your work email address'}
            onChange={(e) => console.log(e.target.value)}
            sx={{
              width: 0.9,
            }}
          />
          <OrangeButton
            prefixIcon={<WhiteLeftArrow />}
            text={'One-Click Authorize'}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SignUp;
