import { Checkbox, FormControlLabel } from '@mui/material';

export type LabelledCheckboxProps = {
  label: string;
  checked: boolean;
  onValueChanged: (checked: boolean) => any;
};

export const LabelledCheckbox = ({
  label,
  checked,
  onValueChanged,
}: LabelledCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e, checked) => onValueChanged(checked)}
        />
      }
      label={label}
    />
  );
};
