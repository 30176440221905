import Logo from '../logo/Logo';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Header = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Grid container py={theme.spacing(3)}>
      <Grid item xs={8}>
        <Logo />
      </Grid>
      <Grid
        item
        container
        xs={4}
        direction={'row'}
        spacing={4}
        justifyContent={'flex-end'}
      >
        <Grid item>
          <Link to={'/customerhandover'}>
            <AnnouncementIcon color="secondary" />
          </Link>
        </Grid>
        <Grid item>
          <Link to={'/about'}>
            <SettingsIcon color="secondary" />
          </Link>
        </Grid>
        <Grid item>
          <Link to={'/accountintelligence'}>
            <PersonIcon color="secondary" />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
