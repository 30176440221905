import { alpha } from '@mui/material';

export const lxColorsNew = {
  grey: {
    light: '#b1b1b1',
    regular: '#858585',
    dark: '#666666',
  },
  white: '#ffffff',
  black: '#424242',
  blue: {
    light: '#E6F7FF',
    regular: '#00AAFF',
    dark: '#4A96BD',
  },
  orange: {
    light: '#fff8f2',
    regular: '#FFB67A',
    medium: '#ffb06b',
    dark: '#ff9500',
  },
  red: {
    regular: '#ff0000',
  },
  green: {
    regular: '#009c46',
  },
  background:
    'linear-gradient(to top, #f6f6fe, #f4f7ff, #f3f8ff, #f2f9ff, #f2fafe)',
  status: {
    green: '#04BD51',
    blue: '#00AAFF',
    orange: '#edaa00',
    yellow: '#ede100',
    red: '#ED2020',
  },
  chip: {
    secondary: {
      backgroundImage: 'linear-gradient(90deg, #f0f4ff, #f0fcff)',
    },
  },
  box: {
    navigation: {
      boxShadow: '0 0 6px #AAAAF2',
    },
    searchResult: {
      border: '#E6F7FF',
      boxShadow: '1px 1px 2px #C9EDFF',
    },
    data: {
      border: '#DCF0FA',
      boxShadow: '1px 1px 2px #C9EDFF',
    },
    dataGreen: {
      border: '#C9F2DC',
      backgroundColor: '#FBFFF2',
    },
    dataRed: {
      border: '#FFD4D4',
      backgroundColor: '#FFFAFA',
    },
    orange: {
      border: '#FFF8F2',
      backgroundColor: '#fff8f2',
    },
    modalOverlay: {
      border: '#E1E1FC',
      boxShadow: '0 0 4px #9494EB',
    },
  },
};

export const lxColors = {
  white: {
    default: 'white',
  },
  orange: {
    default: '#ffb06b',
  },
  menubar: {
    button: {
      color: '#404040',
      bgSelected: '#ffebd9',
    },
  },
  box: {
    defaultStyle: {
      backgroundColor: '#b9e4fa',
    },
  },
  icons: {
    defaultStyle: {
      color: '#ff9036',
    },
    secondaryStyle: {
      color: '#ff9500',
    },
    label: {
      color: '#4a96bd',
    },
    up: {
      color: '#009c46',
    },
    down: {
      color: '#ff9036',
    },
    background: {
      color: '#fff8f2',
    },
    border: {
      color: '#d5ddf5',
    },
    signUpCompany: {
      color: '#4c00ff',
    },
    visibleOption: {
      color: '#79C3E8',
    },
    invisibleOption: {
      color: '#c1cdd4',
    },
  },
  sparkLine: {
    background: {
      primary: {
        color: '#fff8f2',
      },
      secondary: {
        color: alpha('#f0f4ff', 0.5),
      },
    },
    line: {
      primary: {
        color: '#ff9036',
      },
      secondary: {
        color: '#00aaff',
      },
    },
  },
  font: {
    title: {
      darkGrey: '#424242',
      grey: '#858585',
      lightGrey: '#BABABA',
    },
    description: {
      color: '#616161',
    },
    iconDescription: {
      color: '#ff9036',
    },
    sparkLine: {
      color: '#858585',
    },
    signup: {
      blue: '#4c00ff',
      orange: '#ff9036',
      grey: '#666666',
    },
  },
  infoBox: {
    font: {
      title: {
        color: '#666666',
      },
      description: {
        color: '#858585',
      },
    },
    primary: {
      border: '1px solid #c9f2dc',
      background: '#fbfff2',
      icon: {
        fill: '#009c46',
      },
    },
    secondary: {
      border: '1px solid #ffd4d4',
      background: '#fffafa',
      icon: {
        fill: '#ff0000',
      },
    },
    signup: {
      background: '#fff8f2',
    },
  },
  card: {
    border: '1px solid #dcf0fa',
  },
  circularLogo: {
    border: '1px solid #cfcfcf',
  },
  textField: {
    borderColor: '#E6F7FF',
    focusedBorderColor: '#80B2D1',
  },
  button: {
    lightOrange: '#ffb06b',
    orange: '#ff9036',
  },
  selectDropdown: {
    label: {
      color: '#424242',
    },
    background: {
      color: '#ffebd9',
    },
  },
  redacted: {
    backgroundImage:
      'linear-gradient(90deg, #f0f4ff 99999999999999%, rgba(176, 197, 255, 0) 100% )',
  },
  background: {
    orange: '#fff8f2',
  },
  popper: {
    border: {
      color: '#e1e1fc',
    },
  },
};
