// Currently the Actor API does not provide these fields. Use placeholders for

import moment from 'moment';
import LxTypography from '../../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../../components/lxTypography/lxTypographyStyles';
import {
  BaseDefaultColumnStyle,
  MetricsTableCol,
} from '../../../../components/metricstable/MetricsTableTypes';
import { VStack } from '../../../../features/common/structures/Stacks';
import { Actor } from '../../../../utils/types/Entities';

// now.
const CREATE_TIME_PLACEHOLDER = moment('2023-01-09T11:34:54Z').toDate();
const CREATE_USER = 'samuel@workorb.com';
const UPDATE_TIME_PLACEHOLDER = moment('2023-03-12T14:53:01Z').toDate();
const UPDATE_USER = 'nilesh@workorb.com';

export const UpdatedGridColumn = ({
  updateTime,
  updateUser,
  createTime,
  createUser,
}: {
  updateTime: Date;
  updateUser: string;
  createTime: Date;
  createUser: string;
}) => {
  return (
    <VStack>
      {updateTime && (
        <LxTypography
          variant={LxTypographyTypes.DATA_1}
        >{`Updated ${updateTime.toUTCString()}`}</LxTypography>
      )}
      {updateUser && (
        <LxTypography
          variant={LxTypographyTypes.DATA_1}
        >{`by ${updateUser}`}</LxTypography>
      )}
      <LxTypography
        variant={LxTypographyTypes.DATA_1}
      >{`Created ${createTime.toUTCString()}`}</LxTypography>
      <LxTypography
        variant={LxTypographyTypes.DATA_1}
      >{`by ${createUser}`}</LxTypography>
    </VStack>
  );
};

export const UpdatedMetricsTableCol: MetricsTableCol = {
  ...BaseDefaultColumnStyle,
  headerName: 'Updated',
  field: 'updated',
  sortable: true,
  valueGetter: ({ row }: { row: Actor }): Date => {
    // TODO: Api is not providing this right now.
    return UPDATE_TIME_PLACEHOLDER;
  },
  renderCell: (params) => {
    // TODO: Api is not providing this right now.
    return (
      <UpdatedGridColumn
        createTime={CREATE_TIME_PLACEHOLDER}
        createUser={CREATE_USER}
        updateTime={UPDATE_TIME_PLACEHOLDER}
        updateUser={UPDATE_USER}
      />
    );
  },
  align: 'center',
};
