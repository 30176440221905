import { Stack, Typography, useTheme } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import { lxColors } from '../../utils/LxColors';
import { AccessLevelEnum, ShareLevelEnum } from './ShareLevelConstants';
import LxCard from '../../features/common/card/LxCard';
import ShareLevelAvatar from './ShareLevelAvatar';
import { Employee } from '../../utils/types/Entities';

type ShareLevelProps = {
  shareLevel: ShareLevelEnum;
  accessLevel: AccessLevelEnum;
  people: Employee[];
};

const ShareLevelCard = ({
  shareLevel,
  people,
  accessLevel,
}: ShareLevelProps) => {
  const theme = useTheme();

  return (
    <LxCard py={2} width={1} flexGrow={1}>
      <Stack
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={1}
        width={1}
        spacing={3}
      >
        <Typography
          fontFamily={lxFonts.SYSTEM}
          justifySelf={'flex-start'}
          sx={{ color: lxColors.infoBox.font.title.color }}
          fontSize={18}
        >
          {shareLevel}
        </Typography>

        <ShareLevelAvatar shareLevel={shareLevel} people={people} />

        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={1}
        >
          <Typography
            textTransform={'uppercase'}
            fontFamily={lxFonts.HEADING}
            justifySelf={'flex-end'}
            fontSize={14}
            sx={{ color: lxColors.infoBox.font.description.color }}
          >
            access to:
          </Typography>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            justifySelf={'flex-end'}
            color={lxColors.infoBox.font.title.color}
            sx={{ mt: `${theme.spacing(1)} !important` }}
          >
            {accessLevel}
          </Typography>
        </Stack>
      </Stack>
    </LxCard>
  );
};

export default ShareLevelCard;
