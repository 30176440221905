import moment from 'moment/moment';
import { Typography } from '@mui/material';
import { lxFonts } from '../../utils/LxFonts';
import ColoredBox from '../../features/common/ColoredBox';
import GreyButton from '../../features/common/buttons/GreyButton';
import { ReactNode } from 'react';
import { lxColors } from '../../utils/LxColors';
import { Employee } from '../../utils/types/Entities';
import { HStack } from '../../features/common/structures/Stacks';
import { LxAvatar } from '../../features/common/avatars/LxAvatar';

export type DocumentInfoLineProps = {
  date: moment.Moment;
  person: Employee;
  redacted?: boolean;
  info?: string;
  button?: {
    icon: ReactNode;
    text: string;
    onClick: () => void;
  };
};

const DocumentInfoLine = ({
  date,
  person,
  info,
  redacted = false,
  button,
}: DocumentInfoLineProps): JSX.Element => {
  return (
    <HStack alignItems={'center'} gap={1}>
      <Typography
        fontSize={12}
        fontFamily={lxFonts.DATA}
        sx={{ color: lxColors.infoBox.font.description.color }}
      >
        {date.format('DD MMM')}
      </Typography>
      <LxAvatar
        variant={'small'}
        avatar={person.avatarSrc}
        label={person.name}
        sx={{
          color: lxColors.infoBox.font.description.color,
        }}
      />
      {redacted ? (
        <ColoredBox
          width={0.4}
          sx={{
            borderRadius: 1,
            backgroundImage: lxColors.redacted.backgroundImage,
          }}
        />
      ) : (
        <Typography
          fontSize={12}
          fontFamily={lxFonts.DATA}
          sx={{ color: lxColors.infoBox.font.description.color }}
        >
          {info}
        </Typography>
      )}
      {button && (
        <GreyButton
          text={button.text}
          prefixIcon={button.icon}
          onClick={button.onClick}
          sx={{
            fontSize: '12px !important',
          }}
        />
      )}
    </HStack>
  );
};

export default DocumentInfoLine;
