import { SvgIconComponent } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { SvgIconProps } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';

type BordedIconProps = SvgIconProps & {
  icon: SvgIconComponent;
  sx?: SxProps<Theme>;
};

const BordedIcon = ({ icon: Icon, sx, ...otherProps }: BordedIconProps) => {
  return (
    <Icon
      color={'info'}
      sx={{
        border: 4,
        p: 1,
        borderRadius: '100%',
        aspectRatio: '1/1',
        borderColor: lxColors.icons.border.color,
        ...sx,
      }}
      {...otherProps}
    />
  );
};
export default BordedIcon;
