import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { OrgSchema } from '../../../../utils/data/jsonSchema';
import { OrgSettings } from '../../../../utils/types/Entities';
import EditDialog from './EditDialog';
import OrgSettingsTabPanel from './tabpanel/OrgSettings';
import OrgSettingsIntegrationInfoTabPanel from './tabpanel/OrgSettingsIntegrationInfo';
import OrgSettingsRolesTabPanel from './tabpanel/OrgSettingsRoles';

const EDIT_ORG_SETTINGS_TITLE = 'Edit Org Settings';
const SETTINGS_TAB_INDEX = 0;
const ROLES_TAB_INDEX = 1;
const INTEGRATION_INFO_TAB_INDEX = 2;

export type EditOrgSettingDialogProps = {
  orgSettings?: OrgSettings;
  orgSettingsSchema?: OrgSchema;
  onSave: (orgSettings: OrgSettings) => Promise<any>;
  onClose: () => any;
};

const EditOrgSettingsDialog = ({
  orgSettings: initialOrgSettings,
  orgSettingsSchema,
  onSave,
  onClose,
}: EditOrgSettingDialogProps) => {
  const [editedOrgSettings, setEditedOrgSettings] = useState<OrgSettings>({});
  const [tabIndexValue, setTabIndexValue] = useState(0);
  const [isEdited, setEdited] = useState(false);
  const [isSettingsTabValid, setSettingsTabValid] = useState(true);
  const [isIntegrationInfoTabValid, setIntegrationInfoTabValid] =
    useState(true);

  const onOrgSettingsChange = (orgSettings: OrgSettings) => {
    // Edited org settings will contain a subset of the SettingsJson properties
    // since this event is generated separately by each tab. Merge together with
    // the previous edits.
    setEditedOrgSettings(Object.assign({}, editedOrgSettings, orgSettings));
    setEdited(true);
  };

  const onSettingsTabChange = (orgSettings: OrgSettings, isValid: boolean) => {
    onOrgSettingsChange(orgSettings);
    setSettingsTabValid(isValid);
  };

  const onRoleTabChange = (orgSettings: OrgSettings, isValid: boolean) => {
    onOrgSettingsChange(orgSettings);
  };

  const onIntegrationInfoTabChange = (
    orgSettings: OrgSettings,
    isValid: boolean
  ) => {
    onOrgSettingsChange(orgSettings);
    setIntegrationInfoTabValid(isValid);
  };

  const validateForm = () => {
    return isSettingsTabValid && isIntegrationInfoTabValid;
  };

  const saveOrg = async () => {
    if (isEdited) {
      // Set any edited properties of SettingsJson and save.
      await onSave(Object.assign({}, initialOrgSettings, editedOrgSettings));
    }
  };

  return (
    <EditDialog
      title={EDIT_ORG_SETTINGS_TITLE}
      validate={validateForm}
      onSave={saveOrg}
      onClose={onClose}
      maxWidth={tabIndexValue !== INTEGRATION_INFO_TAB_INDEX ? 'md' : 'xl'}
    >
      <Tabs value={tabIndexValue} onChange={(e, val) => setTabIndexValue(val)}>
        <Tab label="Settings" />
        <Tab label="Roles" />
        <Tab label="Integration Info" />
      </Tabs>
      <OrgSettingsTabPanel
        orgSettings={initialOrgSettings}
        orgSettingsSchema={orgSettingsSchema}
        tabIndex={SETTINGS_TAB_INDEX}
        tabIndexValue={tabIndexValue}
        onChange={onSettingsTabChange}
      />
      <OrgSettingsRolesTabPanel
        orgSettings={initialOrgSettings}
        orgSettingsSchema={orgSettingsSchema}
        tabIndex={ROLES_TAB_INDEX}
        tabIndexValue={tabIndexValue}
        onChange={onRoleTabChange}
      />
      <OrgSettingsIntegrationInfoTabPanel
        orgSettings={initialOrgSettings}
        orgSettingsSchema={orgSettingsSchema}
        tabIndex={INTEGRATION_INFO_TAB_INDEX}
        tabIndexValue={tabIndexValue}
        onChange={onIntegrationInfoTabChange}
      />
    </EditDialog>
  );
};

export default EditOrgSettingsDialog;
