import { ConversationVolumeBarChart } from '../../../components/ConversationVolumeBarChart';
import { alpha, Box } from '@mui/material';
import { useState } from 'react';
import { IdValueName } from '../../../components/TimelineSection';
import { lxColorsNew } from '../../../utils/LxColors';
import SectionHeader from '../../../features/common/SectionHeader';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { HStack } from '../../../features/common/structures/Stacks';

type SentimentConversationVolumeSectionProps = {};

const array: IdValueName[] = [
  {
    id: 'id985',
    value: 2,
    name: 'name985',
  },
  {
    id: 'id986',
    value: 2,
    name: 'name986',
  },
  {
    id: 'id987',
    value: 2,
    name: 'name987',
  },
  {
    id: 'id988',
    value: 2,
    name: 'name988',
  },
  {
    id: 'id989',
    value: 2,
    name: 'name989',
  },
  {
    id: 'id990',
    value: 81.36946163515562,
    name: 'name990',
  },
  {
    id: 'id991',
    value: 34.47230690149299,
    name: 'name991',
  },
  {
    id: 'id992',
    value: 24.763530061484666,
    name: 'name992',
  },
  {
    id: 'id993',
    value: 104.24143092001019,
    name: 'name993',
  },
  {
    id: 'id994',
    value: 91.2305073982737,
    name: 'name994',
  },
  {
    id: 'id995',
    value: 35.179871932101705,
    name: 'name995',
  },
  {
    id: 'id996',
    value: 21.024729071006174,
    name: 'name996',
  },
  {
    id: 'id997',
    value: 9.62886384254856,
    name: 'name997',
  },
  {
    id: 'id998',
    value: 70.56998582708286,
    name: 'name998',
  },
  {
    id: 'id999',
    value: 8.940597955799532,
    name: 'name999',
  },
  {
    id: 'id1000',
    value: 69.70105649734577,
    name: 'name1000',
  },
  {
    id: 'id1001',
    value: 1.2389473879492732,
    name: 'name1001',
  },
  {
    id: 'id1002',
    value: 92.64423361387188,
    name: 'name1002',
  },
  {
    id: 'id1003',
    value: 82.509124974522,
    name: 'name1003',
  },
  {
    id: 'id1004',
    value: 49.40533460699565,
    name: 'name1004',
  },
  {
    id: 'id1005',
    value: 51.25891580325806,
    name: 'name1005',
  },
  {
    id: 'id1006',
    value: 60.85891808426832,
    name: 'name1006',
  },
  {
    id: 'id1007',
    value: 19.414873289940253,
    name: 'name1007',
  },
  {
    id: 'id1008',
    value: 107.19638194006116,
    name: 'name1008',
  },
  {
    id: 'id1009',
    value: 92.49544570753862,
    name: 'name1009',
  },
  {
    id: 'id1010',
    value: 56.56030258028584,
    name: 'name1010',
  },
  {
    id: 'id1011',
    value: 10.83390912285365,
    name: 'name1011',
  },
  {
    id: 'id1012',
    value: 42.07774853612958,
    name: 'name1012',
  },
  {
    id: 'id1013',
    value: 81.40297261327079,
    name: 'name1013',
  },
  {
    id: 'id1014',
    value: 22.35638759358058,
    name: 'name1014',
  },
  {
    id: 'id1015',
    value: 92.32675201816488,
    name: 'name1015',
  },
  {
    id: 'id1016',
    value: 76.32465933324238,
    name: 'name1016',
  },
  {
    id: 'id1017',
    value: 53.19220815272691,
    name: 'name1017',
  },
  {
    id: 'id1018',
    value: 64.9370868232964,
    name: 'name1018',
  },
  {
    id: 'id1019',
    value: 54.579772230110656,
    name: 'name1019',
  },
  {
    id: 'id1020',
    value: 2,
    name: 'name1020',
  },
  {
    id: 'id1021',
    value: 2,
    name: 'name1021',
  },
  {
    id: 'id1022',
    value: 2,
    name: 'name1022',
  },
  {
    id: 'id1023',
    value: 2,
    name: 'name1023',
  },
  {
    id: 'id1024',
    value: 2,
    name: 'name1024',
  },
  {
    id: 'id1025',
    value: 2,
    name: 'name1025',
  },
  {
    id: 'id1026',
    value: 2,
    name: 'name1026',
  },
  {
    id: 'id1027',
    value: 2,
    name: 'name1027',
  },
  {
    id: 'id1028',
    value: 2,
    name: 'name1028',
  },
  {
    id: 'id1029',
    value: 2,
    name: 'name1029',
  },
  {
    id: 'id1030',
    value: 2,
    name: 'name1030',
  },
  {
    id: 'id1031',
    value: 2,
    name: 'name1031',
  },
  {
    id: 'id1032',
    value: 2,
    name: 'name1032',
  },
  {
    id: 'id1033',
    value: 2,
    name: 'name1033',
  },
  {
    id: 'id1034',
    value: 2,
    name: 'name1034',
  },
  {
    id: 'id1035',
    value: 2,
    name: 'name1035',
  },
  {
    id: 'id1036',
    value: 2,
    name: 'name1036',
  },
  {
    id: 'id1037',
    value: 2,
    name: 'name1037',
  },
  {
    id: 'id1038',
    value: 2,
    name: 'name1038',
  },
  {
    id: 'id1039',
    value: 2,
    name: 'name1039',
  },
];

const SentimentConversationVolumeSection = (
  props: SentimentConversationVolumeSectionProps
) => {
  const [zoomedConversationVolumeData] = useState<IdValueName[]>([
    ...array,
    ...array,
  ]);

  /**
   * Displayed as a flex container that has each element serves as placeholder for each bar
   * each element is position relative so children can be positioned absolutely
   * for each location if index is to be displayed, we use display the index with position absolute
   */
  const getYearsTitle = () => {
    return (
      <HStack
        sx={{
          width: '100%',
          height: '24px',
        }}
      >
        {zoomedConversationVolumeData.map((data, index) => {
          return (
            <div
              key={index}
              style={{
                position: 'relative',
                width: '1px',
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              {(index === 1 || index === 5 || index === 10) && (
                <div style={{ position: 'absolute' }}>{index}</div>
              )}
            </div>
          );
        })}
      </HStack>
    );
  };

  /**
   * Displayed as a flex container that has each element serves as placeholder for each bar
   * each element is position relative so children can be positioned absolutely
   * for each location if index is to be displayed, we use display the index with position absolute
   */
  const getSentimentTitle = () => {
    return (
      <HStack
        sx={{
          alignItems: 'start',
          justifyContent: 'center',
          width: '100%',
          height: '20px',
        }}
      >
        {zoomedConversationVolumeData.map((data, index) => {
          const random = Math.random();
          return (
            <div
              key={index}
              style={{
                position: 'relative',
                width: '1px',
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              {(index === 10 || index === 50 || index === 100) && (
                <div style={{ position: 'absolute' }}>
                  {random < 0.333 && (
                    <InsertEmoticonIcon
                      sx={{ color: lxColorsNew.green.regular, flexGrow: 1 }}
                    />
                  )}
                  {random > 0.333 && random < 0.66 && (
                    <MoodBadIcon
                      sx={{ color: lxColorsNew.red.regular, flexGrow: 1 }}
                    />
                  )}
                  {random > 0.66 && (
                    <SentimentNeutralIcon
                      sx={{ color: lxColorsNew.orange.dark, flexGrow: 1 }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </HStack>
    );
  };

  return (
    <>
      <SectionHeader sectionTitle={'TImeline'} side={<></>} />
      <Box
        sx={{
          backgroundColor: lxColorsNew.orange.light,
        }}
      >
        <Box sx={{ paddingTop: 2 }}>{getYearsTitle()}</Box>
        <Box sx={{ paddingTop: 4 }}>{getSentimentTitle()}</Box>

        <Box sx={{ paddingTop: 4 }}>
          <ConversationVolumeBarChart
            gap={'3px'}
            chartData={zoomedConversationVolumeData}
            backgroundColor={lxColorsNew.orange.light}
            barColor={alpha('#758B96', 0.6)}
            chartHeight={80}
          />
        </Box>
      </Box>
    </>
  );
};
export default SentimentConversationVolumeSection;
