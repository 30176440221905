import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { PlatformChip } from '../../features/common/LxChips';
import { HStack } from '../../features/common/structures/Stacks';
import { PlatformType } from '../../features/common/Constants';
import { Employee } from '../../utils/types/Entities';
import { LxAvatar } from '../../features/common/avatars/LxAvatar';

type DocumentIconProps = {
  fileName: string;
  date: moment.Moment;
  platform: PlatformType;
  avatar: Employee;
};

const DocumentInfo = ({
  fileName,
  date,
  platform,
  avatar,
}: DocumentIconProps) => {
  return (
    <Stack direction={'column'}>
      <HStack alignItems={'center'}>
        <DescriptionOutlinedIcon htmlColor={'#7a7a7a'} />
        <Typography fontSize={14} variant={'subtitle1'}>
          {fileName}
        </Typography>
      </HStack>
      <HStack justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
        <PlatformChip platform={platform} />
        <Box>
          <Typography color={'#858585'} fontSize={14}>
            {moment().diff(date, 'days')} d ago
          </Typography>
        </Box>
        <LxAvatar
          variant={'small'}
          label={avatar.name}
          avatar={avatar.avatarSrc}
        />
      </HStack>
    </Stack>
  );
};

export default DocumentInfo;
