import { SparkLineText } from '../common/LxTexts';
import { SparkLineChart } from '../common/charts/SparkLineChart';
import { Box } from '@mui/material';

type CommunicationChartProps = {
  backgroundColor: string;
  lineColor: string;

  // data: x is 1 ~ 12 , 1 means January, 12 means December
  // y : the number of communications for that month
  data?: { x: number; y: number }[];
};

export const CommunicationChart = ({
  lineColor,
  backgroundColor,
  data,
}: CommunicationChartProps) => {
  return (
    <Box
      sx={{
        width: '312px',
        height: '66px',
        py: 1,
        backgroundColor: backgroundColor,
      }}
    >
      <Box sx={{ pl: 2 }}>
        <SparkLineText>Communication</SparkLineText>
      </Box>
      <Box sx={{ height: 45 }}>
        <SparkLineChart
          lineColor={lineColor}
          data={[
            {
              id: 'Communication',
              data: data || [],
            },
          ]}
        />
      </Box>
    </Box>
  );
};
