import * as React from 'react';
import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { lxColors } from '../../utils/LxColors';

const defaultSx: SxProps<Theme> = {
  color: lxColors.menubar.button.color,
  ':hover': {
    bgcolor: lxColors.menubar.button.bgSelected,
  },
  fontSize: '21px', // ? should this be dynamic?
  fontWeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  height: 1,
  width: 1,
};

const defaultClickedSx: SxProps<Theme> = {
  borderTop: 4,
  height: 1,
  borderTopColor: 'primary.main',
  bgcolor: lxColors.menubar.button.bgSelected,
};

type MenuButtonProps = {
  text: string;
  onClick: () => void;
  selected?: boolean;
  sx?: SxProps<Theme>;
};

const MenuButton = ({
  text,
  onClick,
  selected = false,
  sx = {},
}: MenuButtonProps): JSX.Element => {
  return (
    <Button
      sx={{
        ...defaultSx,
        ...(selected ? defaultClickedSx : null),
        ...sx,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default MenuButton;
