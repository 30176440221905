import { Box } from '@mui/material';
import React from 'react';

type DayBoxProps = {
  color: string;
};

const DayBox = ({ color }: DayBoxProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: 1,
        backgroundColor: `${color}`,
        aspectRatio: '1/1',
      }}
    />
  );
};

export default DayBox;
