import { Stack, Typography, useTheme } from '@mui/material';
import SignUpTitle from './common/SignUpTitle';
import SignUpSubTitle from './common/SignUpSubTitle';
import SignUpInfoBox from './common/SignUpInfoBox';
import AlignedIconsAndText from './common/AlignedIconsAndText';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import { HStack } from '../../features/common/structures/Stacks';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GroupAvatars from '../../components/GroupAvatar';
import { CubesIcon } from '../../features/common/icons/SvgIcons';
import { lxFonts } from '../../utils/LxFonts';
import { lxColors } from '../../utils/LxColors';

const groupAvatarProps = {
  people: new Array(38).fill({
    name: 'John Doe',
    avatarSrc: 'https://mui.com/static/images/avatar/1.jpg',
  }),
};

const iconSx = {
  color: 'primary.main',
  p: 1.5,
  border: 4,
  borderRadius: '100%',
  borderColor: lxColors.icons.border.color,
};

const alignedIconsAndTextProps = {
  iconAndTextArray: [
    {
      icon: <PersonIcon sx={iconSx} />,
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: lxColors.font.signup.orange }}
          >
            Your team
          </Typography>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            sx={{ color: lxColors.font.signup.grey, mb: 1 }}
          >
            WorkOrb has identified your closest 12 colleagues
          </Typography>
          <GroupAvatars {...groupAvatarProps} />
        </>
      ),
    },
    {
      icon: <PeopleIcon sx={iconSx} />,
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: lxColors.font.signup.orange }}
          >
            Your collaborators
          </Typography>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            sx={{ color: lxColors.font.signup.grey }}
          >
            WorkOrb has identified your 40 close collaborators based on your org
            chart, Active Directory groups and meaningful interactions on email,
            calendar, messages and more
          </Typography>
        </>
      ),
    },
    {
      icon: <CubesIcon sx={{ ...iconSx, color: 'primary.main' }} />,
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: lxColors.font.signup.orange }}
          >
            Your organization
          </Typography>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            sx={{ color: lxColors.font.signup.grey }}
          >
            WorkOrb has 800 people from your organization
          </Typography>
        </>
      ),
    },
    {
      icon: <PrivacyTipIcon sx={iconSx} />,
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: lxColors.font.signup.orange }}
          >
            Privacy & sharing
          </Typography>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            sx={{ color: lxColors.font.signup.grey }}
          >
            WorkOrb has extensive controls in privacy and sharing that you can
            use to control your data and exposure to others in your
            organization.
          </Typography>
        </>
      ),
    },
  ],
};

const SignUpOnBoarding = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={1}
      width={1}
      spacing={3}
    >
      <SignUpTitle text={'onboarding'} />
      <HStack>
        <Typography
          fontWeight={'bold'}
          fontFamily={lxFonts.HEADING}
          fontStyle={'italic'}
          fontSize={28}
          sx={{ color: lxColors.font.signup.grey }}
        >
          Hello James,
        </Typography>
        <SignUpSubTitle text={'You are all set'} sx={{ ml: 2 }} />
      </HStack>
      <SignUpInfoBox
        text={'WorkOrb has identified your closest 12 colleagues'}
      />
      <AlignedIconsAndText {...alignedIconsAndTextProps} sx={{ rowGap: 4 }} />
      <OrangeButton
        text={'All done'}
        sx={{
          px: 5,
          mt: `${theme.spacing(10)} !important`,
        }}
      />
    </Stack>
  );
};

export default SignUpOnBoarding;
