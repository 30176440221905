import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import LxTypography from '../lxTypography/LxTypography';
import { LxTypographyTypes } from '../lxTypography/lxTypographyStyles';

export default function ConfirmationDialog({
  title,
  text,
  okButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}: {
  title: string;
  text: string;
  okButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}) {
  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        <LxTypography variant={LxTypographyTypes.CARD_HEADING}>
          {title}
        </LxTypography>
      </DialogTitle>
      <DialogContent>
        <LxTypography variant={LxTypographyTypes.DATA_1}>{text}</LxTypography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>{okButtonText || 'Confirm'}</Button>
        <Button onClick={onCancel}>{cancelButtonText || 'Cancel'}</Button>
      </DialogActions>
    </Dialog>
  );
}
