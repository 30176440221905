import { Typography } from '@mui/material';
import { HStack } from './structures/Stacks';

type SectionHeaderProps = {
  sectionTitle: string;
  textTransform?:
    | 'uppercase'
    | 'capitalize'
    | 'none'
    | 'inherit'
    | 'initial'
    | 'lowercase';
  side?: JSX.Element;
};

const SectionHeader = ({
  sectionTitle,
  textTransform = 'uppercase',
  side,
}: SectionHeaderProps) => {
  return (
    <HStack justifyContent={'space-between'} sx={{ pb: 2 }}>
      <Typography variant={'h5'} textTransform={textTransform}>
        {sectionTitle}
      </Typography>
      {side ? side : <></>}
    </HStack>
  );
};
export default SectionHeader;
