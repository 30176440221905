import { Box, BoxProps } from '@mui/material';

/**
 * To be used when an area needs to be colored with no content, e.g. redacted text.
 */

const ColoredBox = (props: BoxProps): JSX.Element => {
  return (
    <Box {...props}>
      {/*
            This is needed because there needs to be content inside the div for
            color backgrounds to show with percentage dimensions.
            This is a space character so it has no color.
            Please feel free to change it if there is a better solution:)
            */}
      &nbsp;
    </Box>
  );
};

export default ColoredBox;
