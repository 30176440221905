import MetricsTable from '../../metricstable/MetricsTable';
import { UseOrgsListTableData } from './OrgListTableData';

type OrgListTableProps = {
  useOrgsListTableData: UseOrgsListTableData;
};

const OrgListTable = ({
  useOrgsListTableData,
}: OrgListTableProps): JSX.Element => {
  const { colDef, data, isLoading, isError, errorMessage } =
    useOrgsListTableData;

  return (
    <MetricsTable
      colDef={colDef}
      rows={data}
      rowCount={data?.length}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};

export default OrgListTable;
