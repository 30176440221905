import { createTheme, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { lxColorsNew } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

type MuiThemeProps = {
  children: ReactNode;
};

const theme = createTheme({
  palette: {
    primary: {
      light: lxColorsNew.orange.light,
      main: lxColorsNew.orange.regular,
      dark: lxColorsNew.orange.dark,
    },
    secondary: {
      light: lxColorsNew.blue.light,
      main: lxColorsNew.blue.regular,
      dark: lxColorsNew.blue.dark,
    },
    success: {
      main: lxColorsNew.status.green,
    },
    error: {
      main: lxColorsNew.status.red,
    },
    warning: {
      main: lxColorsNew.status.orange,
    },
    info: {
      main: lxColorsNew.status.blue,
    },
  },
  spacing: 8, // same as default; making it explicit
  typography: {
    button: {
      textTransform: 'none',
    },
    // todo remove - just here to make the UI look good for now
    h3: {
      color: '#858585',
      fontSize: 18,
      fontFamily: lxFonts.DATA,
      fontWeight: 500,
    },
    h4: {
      color: '#858585',
      fontSize: 14,
      fontFamily: lxFonts.DATA,
    },
    h5: {
      color: '#858585',
      fontFamily: lxFonts.HEADING,
    },
    body2: {
      fontFamily: lxFonts.HEADING,
    },
    fontFamily: [lxFonts.DATA].join(','),
  },
});

const MuiTheme = ({ children }: MuiThemeProps): JSX.Element => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
