import { Box, Stack, Typography, useTheme } from '@mui/material';
import SignUpTitle from './common/SignUpTitle';
import SignUpSubTitle from './common/SignUpSubTitle';
import SignUpInfoBox from './common/SignUpInfoBox';
import OrangeButton from '../../features/common/buttons/OrangeButton';
import { WhiteLeftArrow } from '../../features/common/icons/Arrows';
import AlignedIconsAndText from './common/AlignedIconsAndText';
import MessagingIcon from '../../assets/messagingIcon.png';
import SalesforceIcon from '../../assets/salesforceIcon.png';
import { lxColors } from '../../utils/LxColors';
import { lxFonts } from '../../utils/LxFonts';

const signUpCommentProps = {
  text: 'WorkOrb sees that you are using these services regularly',
  color: 'orange',
};

const signUpOrange = lxColors.font.signup.orange;
const signUpGrey = lxColors.font.signup.grey;

const alignedIconsAndTextProps = {
  iconAndTextArray: [
    {
      icon: (
        <Box
          component={'img'}
          src={SalesforceIcon}
          alt={'Salesforce Icon'}
          width={1}
        />
      ),
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: signUpOrange }}
          >
            Salesforce
          </Typography>
          <Typography fontFamily={lxFonts.SYSTEM} sx={{ color: signUpGrey }}>
            Align customer, sales and accounts teams, with a view made for you
          </Typography>
        </>
      ),
    },
    {
      icon: (
        <Box
          component={'img'}
          src={MessagingIcon}
          alt={'Messaging Icon'}
          width={1}
        />
      ),
      textElement: (
        <>
          <Typography
            fontFamily={lxFonts.SYSTEM}
            fontSize={22}
            sx={{ color: signUpOrange }}
          >
            Slack
          </Typography>
          <Typography fontFamily={lxFonts.SYSTEM} sx={{ color: signUpGrey }}>
            Bring all messages into one automated working platform
          </Typography>
        </>
      ),
    },
  ],
};

const SignUpDataHub = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      height={1}
      width={1}
      spacing={5}
    >
      <SignUpTitle text={'Authorize'} />
      <SignUpSubTitle text={'Create your data hub'} />
      <SignUpInfoBox {...signUpCommentProps} />
      <AlignedIconsAndText {...alignedIconsAndTextProps} />
      <OrangeButton
        prefixIcon={<WhiteLeftArrow />}
        text={'One-Click Authorize'}
        sx={{
          mt: `${theme.spacing(10)} !important`,
        }}
      />
    </Stack>
  );
};

export default SignUpDataHub;
