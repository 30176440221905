export enum RoutePaths {
  Search = '/',
  Dashboard = '/dashboard',
  About = '/about',
  AccountIntelligence = '/accountintelligence/:orgid?/:customerId?',
  CalendarView = '/calendarview',
  CustomerHandover = '/customerhandover',
  PrivacyAndSharing = '/privacyandsharing',
  AdminData = '/admindata',
  AdminActor = '/adminactor',
  AdminOrg = '/adminorg',
  OrganizationalDirectory = '/organizationaldirectory',
  SignUp = '/signup',
  SignUpEmail = '/signupemail',
  SignUpDataHub = '/signupdatahub',
  SignUpTeamConnect = '/signupteamconnect',
  SignUpOnBoarding = '/signuponboarding',
  SignUpUpgrade = '/signupupgrade',
  DesignSystem = '/designsystem',
}
