import { alpha, Box, Grid, useTheme } from '@mui/material';
import { blueBoxBorder } from '../../features/common/styles/BoxSx';
import SectionHeader from '../../features/common/SectionHeader';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import DocumentInfo from '../documenticon/DocumentInfo';
import moment from 'moment/moment';
import CalendarChart from '../calendarchart/CalendarChart';
import { Platforms } from '../../features/common/Constants';
import LxTile from '../../features/common/tiles/LxTile';

const DocumentSection = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...blueBoxBorder,
        borderColor: alpha(theme.palette.info.light, 0.1),
      }}
    >
      <SectionHeader sectionTitle={'Documents'} />
      <Grid container direction={'row'} mb={5} spacing={2}>
        <Grid
          item
          container
          xs={1.5}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={2}
        >
          <Grid item sx={{ width: 1 }}>
            <LxTile
              variant={'large'}
              icon={CreateOutlinedIcon}
              label={'Legal'}
            />
          </Grid>
          <Grid item sx={{ width: 1 }}>
            <LxTile
              variant={'large'}
              icon={DescriptionOutlinedIcon}
              label={'Client Reports'}
            />
          </Grid>
          <Grid item sx={{ width: 1 }}>
            <LxTile
              variant={'large'}
              icon={LocalAtmOutlinedIcon}
              label={'Sales'}
            />
          </Grid>
        </Grid>
        <Grid item xs={10.5}>
          <Grid
            container
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            columns={3}
            sx={{ width: 1, height: 1 }}
          >
            {Array.from({ length: 9 }).map((_, i) => {
              return (
                <Grid item key={i} xs={1}>
                  <DocumentInfo
                    fileName={'Campaign Performance.pdf'}
                    date={moment().subtract(10, 'days')}
                    platform={Platforms.OneDrive}
                    avatar={{
                      name: 'Jane',
                      avatarSrc:
                        'https://avatars.githubusercontent.com/u/120482615?v=4',
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <CalendarChart />
    </Box>
  );
};

export default DocumentSection;
