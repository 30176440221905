import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

const SignUpRoot = () => {
  return (
    <Container sx={{ height: 1, p: 3 }}>
      <Outlet />
    </Container>
  );
};

export default SignUpRoot;
