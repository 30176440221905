import { Avatar, Box, Stack, Typography } from '@mui/material';
import { HStack } from '../common/structures/Stacks';
import { lxColors } from '../../utils/LxColors';
import { SmallDescriptionText } from '../common/LxTexts';
import HistoryIcon from '@mui/icons-material/History';
import ReplyIcon from '@mui/icons-material/Reply';
import LinkedinIcon from '../common/icons/LinkedinIcon';
import EastIcon from '@mui/icons-material/East';
import RatingIcons from '../common/icons/RatingIcons';
import { SentimentNeutral } from '@mui/icons-material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RatingSection from '../common/RatingSection';
import { OrangeChip } from '../common/LxChips';
import PeopleDescription from '../common/PeopleDescription';
import LxCard from '../common/card/LxCard';
import PlainLabel from '../common/PlainLabel';
import { lxFonts } from '../../utils/LxFonts';
import { PeopleAndConnection } from '../../pages/pagecomponents/accountintelligence/PeopleAndConnectionsSectionFetch';
import { useEffect, useState } from 'react';
import {
  AccountIntelligenceEmployeeInfo,
  employeeInfo,
} from '../../pages/pagecomponents/accountintelligence/DiscussionThreadSectionFetch';
import LxAvatar from '../common/avatars/LxAvatar';
import moment from 'moment';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import {
  getTrendArrowIcon,
  getTrendColor,
  getTrendText,
} from '../common/icons/Arrows';

type PeopleBoxProps = {
  peopleAndConnectionBox: PeopleAndConnection;
};

export const Collaborator = ({ employeeUuid }: { employeeUuid: string }) => {
  const [employeeUuidInfo, setEmployeeUuidInfo] =
    useState<AccountIntelligenceEmployeeInfo | null>(null);

  useEffect(() => {
    employeeInfo(employeeUuid).then((responseData) => {
      setEmployeeUuidInfo(responseData);
    });
  }, [employeeUuid]);
  if (employeeUuidInfo == null) {
    return null;
  }
  return (
    <LxAvatar
      label={employeeUuidInfo.name}
      variant={'small'}
      avatar={employeeUuidInfo.publicImageUrl}
    />
  );
};

const ToneDetails = ({
  toneSentimentPercentage,
}: {
  toneSentimentPercentage: number;
}) => {
  const positive = toneSentimentPercentage > 0;
  const color = positive ? lxColors.icons.up.color : lxColors.icons.down.color;
  const iconStyle = {
    ml: 1,
    color,
    fontSize: 17,
  };
  return (
    <HStack spacing={0}>
      <SentimentNeutral
        sx={{
          color: lxColors.icons.defaultStyle.color,
          fontSize: 17,
        }}
      />
      {positive ? (
        <NorthEastIcon sx={iconStyle} />
      ) : (
        <SouthWestIcon sx={iconStyle} />
      )}
      <SmallDescriptionText
        sx={{
          ml: 0.5,
          color,
          fontFamily: lxFonts.DATA,
        }}
      >
        {positive ? 'POSITIVE' : 'NEGATIVE'} {Math.abs(toneSentimentPercentage)}
        %
      </SmallDescriptionText>
    </HStack>
  );
};

const AccountIntelligencePeopleConnectionBox = ({
  peopleAndConnectionBox: {
    title,
    name,
    sinceMs,
    recencyMs,
    responsiveness,
    responsivenessChangePercent,
    toneSentimentPercentage,
    frequency,
    frequencyChangePercent,
    skillTags,
    collaborators,
    badge,
    department,
  },
}: PeopleBoxProps) => {
  return (
    <LxCard sx={{ p: 0 }}>
      <PeopleDescription
        avatar={
          <Avatar
            sx={{ width: 68, height: 68 }}
            src={'https://avatars.githubusercontent.com/u/2603730?v=4'}
          />
        }
        peopleInfo={{
          title,
          subtitle: name,
          byline: department,
        }}
        avatarBottom={
          <>
            <Box sx={{ pt: 2 }}>
              <LinkedinIcon />
            </Box>
            <EastIcon sx={{ fontSize: 14 }} />
          </>
        }
        infoRightSection={
          <Typography sx={{ color: lxColors.font.iconDescription }}>
            {badge}
          </Typography>
        }
        infoBottom={
          <>
            <HStack spacing={4} sx={{ my: 2 }}>
              <PlainLabel
                icon={HistoryIcon}
                label={`Since ${moment(sinceMs).format('MMM YYYY')}`}
              />
              <PlainLabel
                icon={ReplyIcon}
                label={`${Math.round(
                  (Date.now() - recencyMs) / 86400000
                )} days ago`}
              />
            </HStack>
            <HStack spacing={12} alignItems={'start'}>
              <RatingSection
                title={'Responsiveness'}
                color={getTrendColor(responsivenessChangePercent)}
                trendIcon={getTrendArrowIcon(responsivenessChangePercent)}
                trendText={getTrendText(responsivenessChangePercent, false)}
                ratings={
                  <RatingIcons rating={responsiveness} variant={'history'} />
                }
              />
              <Stack>
                <Typography variant={'h3'} sx={{ fontSize: 14 }}>
                  Tone
                </Typography>
                <ToneDetails
                  toneSentimentPercentage={toneSentimentPercentage}
                />
              </Stack>
            </HStack>
            <HStack spacing={8}>
              <RatingSection
                color={getTrendColor(frequencyChangePercent)}
                title={'Frequency'}
                trendIcon={getTrendArrowIcon(frequencyChangePercent)}
                trendText={getTrendText(frequencyChangePercent, true)}
                ratings={<RatingIcons rating={frequency} variant={'mail'} />}
              />
              <HStack spacing={2}>
                <HStack>
                  <Collaborator employeeUuid={collaborators[0]} />
                </HStack>
                <HStack>
                  <Collaborator employeeUuid={collaborators[1]} />
                </HStack>
              </HStack>
            </HStack>
            <HStack>
              {skillTags.map((skill, idx) => {
                return <OrangeChip label={skill} sx={{ p: 0.125 }} key={idx} />;
              })}
            </HStack>
          </>
        }
      />
    </LxCard>
  );
};
export default AccountIntelligencePeopleConnectionBox;
