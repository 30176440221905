import { HStack } from '../../../features/common/structures/Stacks';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import { lxColors } from '../../../utils/LxColors';
import {
  DescriptionTitle,
  InfoBoxDescription,
  SmallDescriptionText,
} from '../../../features/common/LxTexts';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import HistoryIcon from '@mui/icons-material/History';
import LxAvatar from '../../../features/common/avatars/LxAvatar';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
import { Box, Stack, useTheme } from '@mui/material';
import {
  AccountIntelligenceEmployeeInfo,
  DiscussionThreadHighAndLowLights,
  employeeInfo,
} from './DiscussionThreadSectionFetch';
import moment from 'moment';
import { useEffect, useState } from 'react';

const ParticipantInfo = ({
  employeeUuid,
  loudness,
}: {
  employeeUuid: string;
  loudness: 1 | 2 | 3 | 4 | 5;
}) => {
  const [employeeUuidInfo, setEmployeeUuidInfo] =
    useState<AccountIntelligenceEmployeeInfo | null>(null);

  useEffect(() => {
    employeeInfo(employeeUuid).then((responseData) => {
      setEmployeeUuidInfo(responseData);
    });
  }, [employeeUuid]);
  if (employeeUuidInfo == null) {
    return null;
  }
  let sizePercentage = 1.0;
  sizePercentage -= loudness * 0.05;
  return (
    <LxAvatar
      label={employeeUuidInfo.name}
      variant={'bordered'}
      sizePercentage={sizePercentage}
      avatar={employeeUuidInfo.publicImageUrl}
    />
  );
};

const DiscussionThread = ({
  data,
}: {
  data: DiscussionThreadHighAndLowLights;
}) => {
  const theme = useTheme();
  const getCaptionBox = ({
    icon,
    description,
    date,
    boxColor,
    boxBorder,
  }: {
    icon: JSX.Element;
    date: string;
    boxBorder: string;
    boxColor: string;
    description: string;
  }) => {
    return (
      <Stack
        sx={{
          border: boxBorder,
          backgroundColor: boxColor,
          p: 1,
          px: 2,
        }}
        spacing={0.75}
        height={'79px'}
        width={'218px'} // this is something we want to have fixed length
      >
        <HStack spacing={0.5}>
          {icon}
          <DescriptionTitle>{date}</DescriptionTitle>
        </HStack>
        <InfoBoxDescription>{description}</InfoBoxDescription>
      </Stack>
    );
  };

  function getConversationTrendText(trendPercent: number) {
    if (trendPercent < 0) {
      return 'Down ' + Math.round(trendPercent) + '%';
    }
    if (trendPercent > 0) {
      return 'Up ' + Math.round(trendPercent) + '%';
    }
    return 'Normal';
  }
  function getSentimentPercentText(negSentimentPercent: number) {
    if (negSentimentPercent > 50) {
      return 'Negative ' + Math.round(negSentimentPercent) + '%';
    }
    if (negSentimentPercent < 50) {
      return 'Positive ' + Math.round(100 - negSentimentPercent) + '%';
    }
    return 'Neutral';
  }
  function getRecencyText(epochMillis: number) {
    return moment(epochMillis).format('D MMM YYYY');
  }
  function getParticipantsJsx() {
    let parts = data.participants;
    return parts.map((d, idx) => {
      return (
        <ParticipantInfo
          employeeUuid={d.employeeUuid}
          loudness={d.loudness}
          key={'participant-' + idx}
        />
      );
    });
  }

  return (
    <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
      <Box
        p={1}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        }}
      >
        {data.threadDescription}
      </Box>
      <Stack>
        <HStack spacing={1.5}>
          <HStack spacing={0.5}>
            <SouthWestIcon sx={{ fill: lxColors.icons.down.color }} />
            <Box>
              <DescriptionTitle>Conversations</DescriptionTitle>
              <SmallDescriptionText>
                {getConversationTrendText(data.conversationTrendPercent)}
              </SmallDescriptionText>
            </Box>
          </HStack>
          <HStack spacing={0.5}>
            {data.sentimentNegativePercent > 50 && (
              <SouthWestIcon sx={{ fill: lxColors.icons.down.color }} />
            )}
            {data.sentimentNegativePercent <= 50 && (
              <NorthEastIcon sx={{ fill: lxColors.icons.up.color }} />
            )}
            <Box>
              <DescriptionTitle>Sentiment</DescriptionTitle>
              <SmallDescriptionText>
                {getSentimentPercentText(data.sentimentNegativePercent)}
              </SmallDescriptionText>
            </Box>
          </HStack>
          <HStack spacing={0.5}>
            <HistoryIcon sx={{ fill: lxColors.icons.defaultStyle.color }} />
            <Box>
              <DescriptionTitle>Recency</DescriptionTitle>
              <SmallDescriptionText>
                {getRecencyText(data.recencyEpochMillis)}
              </SmallDescriptionText>
            </Box>
          </HStack>
        </HStack>
        <HStack justifyContent={'space-between'}>{getParticipantsJsx()}</HStack>
      </Stack>

      <HStack spacing={2}>
        {getCaptionBox({
          boxBorder: lxColors.infoBox.primary.border,
          boxColor: lxColors.infoBox.primary.background,
          icon: (
            <StarIcon
              sx={{
                fill: lxColors.infoBox.primary.icon.fill,
                fontSize: 15,
              }}
            />
          ),
          date: getRecencyText(data.positiveSentimentExcerptEpochMillis),
          description: data.positiveSentimentExcerpt,
        })}
        {getCaptionBox({
          boxBorder: lxColors.infoBox.secondary.border,
          boxColor: lxColors.infoBox.secondary.background,
          icon: (
            <EventIcon
              sx={{
                fill: lxColors.infoBox.secondary.icon.fill,
                fontSize: 15,
              }}
            />
          ),
          date: getRecencyText(data.negativeSentimentExcerptEpochMillis),
          description: data.negativeSentimentExcerpt,
        })}
      </HStack>
    </HStack>
  );
};

export default DiscussionThread;
