import { Grid, Stack, Typography } from '@mui/material';
import { lxFonts } from '../utils/LxFonts';
import ShareLevelCard from '../components/sharelevel/ShareLevelCard';
import {
  AccessLevelEnum,
  ShareLevelEnum,
} from '../components/sharelevel/ShareLevelConstants';
import SensitivityInfoCard from '../components/SensitivityInfoCard';
import DocumentPrivacyInfoTile, {
  DocumentPrivacyInfoTileProps,
} from '../components/documentprivacyinfotile/DocumentPrivacyInfoTile';
import {
  AmyAvatarObj,
  AngesAvatarObj,
  CindyAvatarObj,
  HenryAvatarObj,
  JoshAvatarObj,
  TravisAvatarObj,
  TrevorAvatarObj,
} from '../utils/TestAvatars';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import moment from 'moment';
import LockIcon from '@mui/icons-material/Lock';
import { PartnerIcon, WonkaIcon } from '../features/common/icons/SvgIcons';
import { lxColors, lxColorsNew } from '../utils/LxColors';

// Styles
const titleStyle = {
  fontFamily: lxFonts.HEADING,
  fontSize: 28,
  fontWeight: 'bold',
  sx: {
    color: lxColors.font.title.darkGrey,
  },
};

const subTitleStyle = {
  fontFamily: lxFonts.DATA,
  fontSize: 16,
  sx: { color: lxColors.font.title.lightGrey },
};

// Share Level JSON
const userShareLevel = {
  shareLevel: ShareLevelEnum.USER,
  accessLevel: AccessLevelEnum.EVERYTHING,
  people: [CindyAvatarObj],
};

const teamShareLevel = {
  shareLevel: ShareLevelEnum.TEAM,
  accessLevel: AccessLevelEnum.NONSENSITIVE,
  people: [HenryAvatarObj, TravisAvatarObj, AngesAvatarObj],
};

const collaboratorsShareLevel = {
  shareLevel: ShareLevelEnum.COLLABORATORS,
  accessLevel: AccessLevelEnum.REDACTED,
  people: [
    HenryAvatarObj,
    TravisAvatarObj,
    AngesAvatarObj,
    TrevorAvatarObj,
    JoshAvatarObj,
    AmyAvatarObj,
    HenryAvatarObj,
    TravisAvatarObj,
    AngesAvatarObj,
    TrevorAvatarObj,
    JoshAvatarObj,
    AmyAvatarObj,
    HenryAvatarObj,
    TravisAvatarObj,
    AngesAvatarObj,
    TrevorAvatarObj,
    JoshAvatarObj,
    AmyAvatarObj,
  ],
};

const organizationShareLevel = {
  shareLevel: ShareLevelEnum.ORGANIZATION,
  accessLevel: AccessLevelEnum.NOTHING,
  people: [],
};

// Data Viewing Permissions JSON
const humanResourcesInfoCardProps = {
  title: 'Human Resources',
  infoItems: [
    {
      visible: false,
      name: 'Salary',
    },
    {
      visible: false,
      name: 'Employment Information',
    },
    {
      visible: false,
      name: 'Workday Emails',
    },
  ],
};

const financialInfoCardProps = {
  title: 'Financial',
  infoItems: [
    {
      visible: false,
      name: 'Financials, bank information',
    },
    {
      visible: false,
      name: 'Budgets, projections',
    },
    {
      visible: false,
      name: 'Statements, expenses',
    },
    {
      visible: true,
      name: 'Except pricing with customer',
    },
  ],
};

const legalInfoCardProps = {
  title: 'Legal',
  infoItems: [
    {
      visible: false,
      name: 'Internal and government legal documents',
    },
    {
      visible: false,
      name: 'Shareholder information',
    },
    {
      visible: false,
      name: 'Confidential tags',
    },
    {
      visible: true,
      name: 'Except pricing with customer',
    },
  ],
};

const personalInfoCardProps = {
  title: 'Personal',
  infoItems: [
    {
      visible: false,
      name: 'One-on-one messages',
    },
  ],
};

const documentPrivacyInfoTileProps: DocumentPrivacyInfoTileProps = {
  person: HenryAvatarObj,
  documentPrivacyInfoBlocks: [
    {
      accessLevel: 'full access',
      icon: MarkAsUnreadIcon,
      title: 'PO for spring conference install',
      organizations: [
        {
          name: 'Wonka',
          avatar: WonkaIcon,
        },
        {
          name: 'Partner.com',
          avatar: PartnerIcon,
        },
      ],
      people: [TravisAvatarObj, CindyAvatarObj],
      tags: ['Upsell', 'Signed'],
      notifications: true,
      documentType: 'PDF',
      documentInfoLines: [
        {
          date: moment(),
          person: TravisAvatarObj,
          info: 'RFP is out, due next week. Thank you so much everyone for your concern for the RFP.',
        },
        {
          date: moment(),
          person: TravisAvatarObj,
          info: 'Our procurement will send next steps via Ariba system. Regards',
        },
        {
          date: moment(),
          person: TravisAvatarObj,
          redacted: true,
          button: {
            icon: (
              <LockIcon
                sx={{ height: 12, width: 12, color: lxColorsNew.grey.light }}
              />
            ),
            text: `Request access from ${TravisAvatarObj.name}`,
            onClick: () => {},
          },
        },
      ],
    },
    {
      accessLevel: 'full access',
      icon: MarkAsUnreadIcon,
      title: 'PO for spring conference install',
      organizations: [
        {
          name: 'Wonka',
          avatar: WonkaIcon,
        },
        {
          name: 'Partner.com',
          avatar: PartnerIcon,
        },
      ],
      people: [TravisAvatarObj, CindyAvatarObj],
      tags: ['Upsell', 'Signed'],
      notifications: true,
      documentType: 'PDF',
      documentInfoLines: [
        {
          date: moment(),
          person: TravisAvatarObj,
          info: 'RFP is out, due next week. Thank you so much everyone for your concern for the RFP.',
        },
        {
          date: moment(),
          person: TravisAvatarObj,
          info: 'Our procurement will send next steps via Ariba system. Regards',
        },
        {
          date: moment(),
          person: TravisAvatarObj,
          redacted: true,
          button: {
            icon: (
              <LockIcon
                sx={{ height: 12, width: 12, color: lxColorsNew.grey.light }}
              />
            ),
            text: `Request access from ${TravisAvatarObj.name}`,
            onClick: () => {},
          },
        },
      ],
    },
  ],
};

const PrivacyAndSharing = () => {
  return (
    <Stack direction={'column'} width={1} height={1} gap={3}>
      <Typography {...titleStyle}>Privacy & Sharing</Typography>

      <Typography {...subTitleStyle}>
        You may change settings at any time to your desired level, or go back to
        your assigned level.
      </Typography>

      <Typography {...titleStyle}>Sharing levels</Typography>

      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        alignItems={'stretch'}
        width={1}
        gap={5}
      >
        <Grid item xs display={'flex'} width={1}>
          <ShareLevelCard {...userShareLevel} />
        </Grid>
        <Grid item xs display={'flex'} width={1}>
          <ShareLevelCard {...teamShareLevel} />
        </Grid>
        <Grid item xs display={'flex'} width={1}>
          <ShareLevelCard {...collaboratorsShareLevel} />
        </Grid>
        <Grid item xs display={'flex'} width={1}>
          <ShareLevelCard {...organizationShareLevel} />
        </Grid>
      </Grid>

      <Typography {...titleStyle}>Sensitive information for you</Typography>

      <Typography {...subTitleStyle}>
        Your admin assigns sensitive information and can change the settings for
        you.
      </Typography>

      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'stretch'}
        spacing={3}
      >
        <Grid item xs={6} container width={1}>
          <SensitivityInfoCard {...humanResourcesInfoCardProps} />
        </Grid>
        <Grid item xs={6} container width={1}>
          <SensitivityInfoCard {...financialInfoCardProps} />
        </Grid>
        <Grid item xs={6} container width={1}>
          <SensitivityInfoCard {...legalInfoCardProps} />
        </Grid>
        <Grid item xs={6} container width={1}>
          <SensitivityInfoCard {...personalInfoCardProps} />
        </Grid>
      </Grid>

      <Typography {...titleStyle}>Redacted previews</Typography>

      <Typography {...subTitleStyle}>
        Your admin assigns sensitive information and can change the settings for
        you.
      </Typography>
      <DocumentPrivacyInfoTile {...documentPrivacyInfoTileProps} />
    </Stack>
  );
};

export default PrivacyAndSharing;
