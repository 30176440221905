import { ShareLevelEnum } from './ShareLevelConstants';
import Badge from '@mui/material/Badge';
import BordedIcon from '../../features/common/icons/BorderedIcon';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import CustomBadge from '../../features/common/avatars/CustomBadge';
import PeopleIcon from '@mui/icons-material/People';
import AvatarGroup from '@mui/material/AvatarGroup';
import {
  ColleaguesIcon,
  CubesIcon,
} from '../../features/common/icons/SvgIcons';
import BusinessIcon from '@mui/icons-material/Business';
import { Employee } from '../../utils/types/Entities';

const avatarSx = {
  color: 'primary.main',
  p: 0.5,
  bgcolor: 'white',
};

type ShareLevelAvatarProps = { shareLevel: ShareLevelEnum; people: Employee[] };

const ShareLevelAvatar = ({ shareLevel, people }: ShareLevelAvatarProps) => {
  return (
    <>
      {shareLevel === ShareLevelEnum.USER && (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          badgeContent={<BordedIcon icon={PersonIcon} sx={avatarSx} />}
        >
          <Avatar
            src={people![0].avatarSrc}
            alt={people![0].name}
            sx={{ width: 78, height: 78 }}
          />
        </Badge>
      )}

      {shareLevel === ShareLevelEnum.TEAM && (
        <CustomBadge
          overlap="circular"
          top={120}
          badgeContent={<BordedIcon icon={PeopleIcon} sx={avatarSx} />}
        >
          <AvatarGroup max={4}>
            {people.map((person, index) => (
              <Avatar key={index} src={person.avatarSrc} alt={person.name} />
            ))}
          </AvatarGroup>
        </CustomBadge>
      )}

      {shareLevel === ShareLevelEnum.COLLABORATORS && (
        <CustomBadge
          overlap="circular"
          badgeContent={<BordedIcon icon={CubesIcon} sx={avatarSx} />}
          sx={{ justifyContent: 'center' }}
        >
          <AvatarGroup
            max={12}
            sx={{ flexWrap: 'wrap', aspectRatio: '1/1', width: 160 }}
          >
            {people.map((person, index) => (
              <Avatar key={index} src={person.avatarSrc} alt={person.name} />
            ))}
          </AvatarGroup>
        </CustomBadge>
      )}

      {shareLevel === ShareLevelEnum.ORGANIZATION && (
        <CustomBadge
          overlap="circular"
          badgeContent={<BordedIcon icon={BusinessIcon} sx={avatarSx} />}
          top={90}
          right={46}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'center',
            width: 0.5,
            aspectRatio: '1/1 !important',
            flexGrow: 1,
          }}
        >
          <ColleaguesIcon
            sx={{
              width: 1,
              aspectRatio: '1/1 !important',
              flexGrow: 1,
            }}
          />
        </CustomBadge>
      )}
    </>
  );
};

export default ShareLevelAvatar;
