import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import EastIcon from '@mui/icons-material/East';

export const WhiteLeftArrow = (): JSX.Element => {
  return <ArrowForwardIcon sx={{ color: 'white', p: 1 }} />;
};

export const getTrendColor = (trendNumber: number) => {
  return trendNumber > 0 ? 'green' : 'orange';
};
export const getTrendText = (trendNumber: number, upDownLabel: boolean) => {
  const label = upDownLabel ? (trendNumber > 0 ? 'UP' : 'DOWN') : '';
  return `${label} ${Math.abs(trendNumber)}%`;
};
export const getTrendArrowIcon = (trendNumber: number) => {
  switch (true) {
    case trendNumber > 0:
      return NorthEastIcon;
    case trendNumber < 0:
      return SouthWestIcon;
    default:
      return EastIcon;
  }
};
