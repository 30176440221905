import {
  alpha,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Slider,
  SliderThumb,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import InsightsIcon from '@mui/icons-material/Insights';
import GridViewIcon from '@mui/icons-material/GridView';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { PartnerIcon, WonkaIcon } from '../features/common/icons/SvgIcons';
import moment from 'moment';
import LockIcon from '@mui/icons-material/Lock';
import DocumentPrivacyInfoTile, {
  DocumentPrivacyInfoTileProps,
} from '../components/documentprivacyinfotile/DocumentPrivacyInfoTile';
import { CindyAvatarObj, TravisAvatarObj } from '../utils/TestAvatars';
import { OrangeChip, PurpleToBlueChip } from '../features/common/LxChips';
import { ConversationVolumeBarChart } from '../components/ConversationVolumeBarChart';
import { useState } from 'react';
import { lxColorsNew } from '../utils/LxColors';

export const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#79c3e8',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 47,
    width: 20,
    backgroundColor: '#79c3e8',
    border: '1px solid currentColor',
    borderRadius: '0px',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#79c3e8',
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {
  'data-index': number;
}

export function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  const direction = other['data-index'] === 0 ? '<' : '>';

  return (
    <SliderThumb {...other}>
      <Typography color={'white'}>{direction}</Typography>
      {children}
    </SliderThumb>
  );
}

///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

export const TimelineTitle = (): JSX.Element => {
  return (
    <Grid container item justifyContent={'space-between'}>
      <Grid item>
        <Typography variant={'body1'}>TIMELINE</Typography>
      </Grid>
      <Grid item>
        <DateRangeIcon
          sx={{
            '&:hover': {
              border: 1,
              borderColor: 'info.light',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export const ConversationAndVolumeChart = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      sx={{
        mt: 1,
        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
        borderRadius: 1,
      }}
    >
      <Grid item>Finance</Grid>
      <Grid item>
        <Divider
          sx={{
            borderWidth: theme.spacing(0.5),
            borderRadius: theme.spacing(1),
            borderColor: 'primary.main',
            width: 90,
          }}
        />
      </Grid>
      <Grid item>
        <Typography>25 converstaions</Typography>
      </Grid>

      <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
        <Stack
          direction={'column'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{
            borderRadius: theme.spacing(1),
            height: '60px',
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
          }}
        >
          Jan
        </Stack>
        <Stack
          direction={'column'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{
            borderRadius: theme.spacing(1),
            height: '80px',
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
          }}
        >
          Feb
        </Stack>
        <Stack
          direction={'column'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{
            borderRadius: theme.spacing(1),
            height: '30px',
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
          }}
        >
          Mar
        </Stack>
      </Stack>
    </Grid>
  );
};

export const AvatarMessages = (): JSX.Element => {
  return (
    <Grid container item sx={{ pl: 2 }}>
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item>
          <Typography sx={{ color: 'primary.main' }} fontSize={''}>
            &#11044;
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>Mar 26</Typography>
        </Grid>
        <Grid item>
          <Avatar
            src={
              'https://i.picsum.photos/id/167/200/300.jpg?hmac=ZAuGlRPlSv0i_JnJr4FFW-OPsVz5bTx8mAI_qUYP_bM'
            }
            sx={{ width: 15, height: 15 }}
          />
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>Food</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Search = (): JSX.Element => {
  const theme = useTheme();

  const blueBoxBorder = {
    padding: 4,
    marginTop: 4,
    border: 1,
    borderColor: alpha(theme.palette.info.light, 0.1),
    borderRadius: 2,
  };

  type IdValueName = {
    id: string;
    value: number;
    name: string;
  };

  // temporarily data for the volume chart
  let tmpConversationData: IdValueName[] = [];
  for (let i = 0; i < 365 * 3; ++i) {
    let vv: number = parseInt((i / 10).toString());

    let thirtyMultiple = parseInt((i / 30).toString());
    let isOdd = vv % 2 === 1;
    if (isOdd) {
      vv = 10 - (i % 10);
    } else {
      vv = i % 10;
    } // always be from 0 ~ 10
    vv += Math.random() * 100;
    if (thirtyMultiple % 2 === 0) {
      vv = 2;
    }

    let n = {
      id: 'id' + i,
      value: vv,
      name: 'name' + i,
    };
    tmpConversationData.push(n);
  }
  let defaultDateSliderValue = [90, 95];
  const [conversationVolumeData] = useState<IdValueName[]>(tmpConversationData);

  const sliceConversationVolumeData = (l: number, r: number) => {
    let len = conversationVolumeData.length;
    l = (l / 100) * len;
    r = (r / 100) * len;
    l = parseInt(l.toString());
    r = parseInt(r.toString());
    return conversationVolumeData.slice(l, r);
  };
  const [zoomedConversationVolumeData, setZoomedConversationVolumeData] =
    useState<IdValueName[]>(
      sliceConversationVolumeData(
        defaultDateSliderValue[0],
        defaultDateSliderValue[1]
      )
    );

  const documentPrivacyInfoTileProps: DocumentPrivacyInfoTileProps = {
    documentPrivacyInfoBlocks: [
      {
        icon: MarkAsUnreadIcon,
        title: 'PO for spring conference install',
        organizations: [
          {
            name: 'Wonka',
            avatar: WonkaIcon,
          },
          {
            name: 'Partner.com',
            avatar: PartnerIcon,
          },
        ],
        people: [TravisAvatarObj, CindyAvatarObj],
        tags: ['Upsell', 'Signed'],
        notifications: true,
        documentType: 'PDF',
        documentInfoLines: [
          {
            date: moment(),
            person: TravisAvatarObj,
            info: 'RFP is out, due next week. Thank you so much everyone for your concern for the RFP.',
          },
          {
            date: moment(),
            person: TravisAvatarObj,
            info: 'Our procurement will send next steps via Ariba system. Regards',
          },
          {
            date: moment(),
            person: TravisAvatarObj,
            redacted: true,
            button: {
              icon: (
                <LockIcon
                  sx={{ height: 12, width: 12, color: lxColorsNew.grey.light }}
                />
              ),
              text: `Request access from ${TravisAvatarObj.name}`,
              onClick: () => {},
            },
          },
        ],
      },
      {
        icon: MarkAsUnreadIcon,
        title: 'PO for spring conference install',
        organizations: [
          {
            name: 'Wonka',
            avatar: WonkaIcon,
          },
          {
            name: 'Partner.com',
            avatar: PartnerIcon,
          },
        ],
        people: [TravisAvatarObj, CindyAvatarObj],
        tags: ['Upsell', 'Signed'],
        notifications: true,
        documentType: 'PDF',
        documentInfoLines: [
          {
            date: moment(),
            person: TravisAvatarObj,
            info: 'RFP is out, due next week. Thank you so much everyone for your concern for the RFP.',
          },
          {
            date: moment(),
            person: TravisAvatarObj,
            info: 'Our procurement will send next steps via Ariba system. Regards',
          },
          {
            date: moment(),
            person: TravisAvatarObj,
            redacted: true,
            button: {
              icon: (
                <LockIcon
                  sx={{ height: 12, width: 12, color: lxColorsNew.grey.light }}
                />
              ),
              text: `Request access from ${TravisAvatarObj.name}`,
              onClick: () => {},
            },
          },
        ],
      },
    ],
  };

  return (
    <Box className="home">
      <Grid spacing={theme.spacing(2)} container>
        <Grid item container xs={3} direction={'column'}>
          <Grid container justifyContent={'center'}>
            <OutlinedInput
              size={'small'}
              onChange={(e) => console.log(e.target.value)}
              id="outlined-basic"
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant={'h5'} color={'primary.main'}>
                    &#x02217;
                  </Typography>
                </InputAdornment>
              }
              sx={{
                backgroundColor: 'white',
                my: 2,
                mx: 1,
                border: '4px solid #c7c7ff',
              }}
            />
          </Grid>
          <Paper
            elevation={1}
            sx={{
              width: 1,
              py: 2,
            }}
          >
            <Box mb={1} ml={1}>
              Messages
            </Box>

            <Paper
              sx={{
                p: 1,
                m: 1,
                color: 'primary.main',
                borderRadius: 2,
                border: 2,
                borderColor: '#ededfb',
              }}
              elevation={0}
            >
              <Typography pl={3} sx={{ fontWeight: 'medium' }}>
                &#x02217; Topics
              </Typography>
              <Box
                sx={{
                  '& .MuiChip-root': {
                    m: 0.2,
                  },
                }}
              >
                <OrangeChip label="Finance 15" variant="filled" />
                <OrangeChip label="Compliance 3" variant="filled" />
                <OrangeChip label="Legal 29" variant="filled" />
                <OrangeChip label="Upsell 3" variant="filled" />
                <OrangeChip label="Onboarding 7" variant="filled" />
              </Box>
            </Paper>

            <Paper
              sx={{
                p: 1,
                m: 1,
                color: 'primary.main',
                orderRadius: 2,
                border: 2,
                borderColor: '#ededfb',
              }}
              elevation={0}
            >
              <Typography pl={3} sx={{ fontWeight: 'medium' }}>
                &#x02217; Smart Filters
              </Typography>
              <Grid container spacing={1}>
                {new Array(9).fill(0).map((k, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      <Stack
                        direction={'column'}
                        alignItems={'center'}
                        sx={{
                          border: 2,
                          borderColor: alpha(theme.palette.primary.main, 0.2),
                        }}
                      >
                        <Person2OutlinedIcon color={'primary'} />
                        <Typography variant={'subtitle2'}>Personal</Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>

            <Paper
              sx={{
                p: 1,
                m: 1,
                color: 'primary.main',
                borderRadius: 2,
                border: 2,
                borderColor: '#ededfb',
              }}
              elevation={0}
            >
              <Typography pl={3} sx={{ fontWeight: 'medium' }}>
                &#x02217; FILE TEMPLATES
              </Typography>
              <Grid container spacing={1}>
                {new Array(9).fill(0).map((k, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      <Box
                        sx={{
                          border: 2,
                          borderColor: alpha(theme.palette.primary.main, 0.2),
                        }}
                      >
                        <Grid container justifyContent={'flex-end'}>
                          <Grid item>
                            <Typography variant={'subtitle2'}>
                              3&nbsp;
                            </Typography>
                          </Grid>
                        </Grid>
                        <Stack direction={'column'} alignItems={'center'}>
                          <DateRangeIcon color={'primary'} />
                          <Typography variant={'subtitle2'}>
                            Calendar File
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>

            <Paper
              sx={{
                p: 1,
                m: 1,
                color: 'primary.main',
                borderRadius: 2,
                border: 2,
                borderColor: '#ededfb',
              }}
              elevation={0}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <PeopleOutlineIcon color={'secondary'} />
                <Typography color={'secondary'}>WITH</Typography>
              </Stack>
              <Grid container spacing={theme.spacing(1)}>
                <Grid item>
                  <PurpleToBlueChip label={'Internal'} />
                </Grid>
                <Grid item>
                  <PurpleToBlueChip label={'Wonka.com'} />
                </Grid>
                <Grid item>
                  <PurpleToBlueChip label={'Henry'} />
                </Grid>
                <Grid item>
                  <PurpleToBlueChip
                    label={'Samuel'}
                    avatar={
                      <Avatar
                        src={
                          'https://i.picsum.photos/id/167/200/300.jpg?hmac=ZAuGlRPlSv0i_JnJr4FFW-OPsVz5bTx8mAI_qUYP_bM'
                        }
                        sx={{ width: 15, height: 15 }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>

        {/*END left side*/}

        <Grid item container xs={9}>
          <Paper sx={{ width: 1, p: 4 }}>
            <Grid container>
              <Grid container>
                <Typography sx={{ border: 1, borderRadius: 8 }} variant={'h4'}>
                  &nbsp;W&nbsp;
                </Typography>
                <Typography sx={{ pl: 1 }} variant={'h4'}>
                  Wonka Industries Inc.
                </Typography>
              </Grid>
              <Grid sx={{ ml: 8 }}>
                <Typography
                  sx={{ color: 'secondary.main' }}
                  variant={'subtitle1'}
                >
                  wonkaindustries.com
                </Typography>
                <OrangeChip
                  sx={{
                    border: 2,
                    borderColor: 'primary.main',
                    borderRadius: 1.5,
                  }}
                  label="Customer"
                  variant="filled"
                />
                <PurpleToBlueChip
                  icon={<CloudIcon color="info" />}
                  label={'View on Salesforce'}
                />
                <PurpleToBlueChip
                  icon={<Person2OutlinedIcon color="info" />}
                  label={'View on GainSignt'}
                />
                <PurpleToBlueChip
                  icon={<ReceiptLongOutlinedIcon color="success" />}
                  label={'View on Quickbooks'}
                />
              </Grid>
            </Grid>
            {/*todo: needs to be refactored to be used with customerhandover*/}
            <Box sx={blueBoxBorder}>
              <TimelineTitle />
              <Box sx={{ height: '8px' }}></Box>

              <div>
                <ConversationVolumeBarChart
                  chartData={conversationVolumeData}
                  barColor={'#C1CDD4'}
                  backgroundColor={'#FFF8F2'}
                  chartHeight={40}
                />
              </div>

              <AirbnbSlider
                sx={{ position: 'relative', top: '-34px' }}
                slots={{ thumb: AirbnbThumbComponent }}
                getAriaLabel={(index) =>
                  index === 0 ? 'Minimum price' : 'Maximum price'
                }
                defaultValue={defaultDateSliderValue}
                onChangeCommitted={(_, value) => {
                  let [l, r] = value as number[];
                  setZoomedConversationVolumeData(
                    sliceConversationVolumeData(l, r)
                  );
                }}
              />

              <Box
                sx={{ border: 4, borderColor: '#79c3e8', marginTop: '-34px' }}
              >
                <ConversationVolumeBarChart
                  chartData={zoomedConversationVolumeData}
                  backgroundColor={'#fff'}
                  barColor={alpha('#758B96', 0.6)}
                  chartHeight={230}
                />
              </Box>

              <Box sx={{ height: '32px' }}></Box>

              <AvatarMessages />

              <Stack direction={'row'} spacing={2}>
                <ConversationAndVolumeChart />
                <ConversationAndVolumeChart />
                <ConversationAndVolumeChart />
              </Stack>
            </Box>
            <Box mt={4}>
              <Chip
                onClick={(c) => {
                  console.log('clicked ', c);
                }}
                sx={{
                  borderRadius: 0,
                  p: 3,
                  mr: 4,
                  borderWidth: 4,
                  borderColor: '#adefff',
                }}
                label="All"
                variant={'outlined'}
                color={'primary'}
                icon={<FormatAlignCenterIcon color={'primary'} />}
              />
              <Chip
                onClick={(c) => {
                  console.log('clicked ', c);
                }}
                sx={{
                  borderRadius: 0,
                  p: 3,
                  mr: 4,
                  borderWidth: 4,
                  borderColor: alpha(theme.palette.secondary.light, 0.2),
                }}
                label="Files"
                variant={'outlined'}
                color={'secondary'}
                icon={<GridViewIcon color={'secondary'} />}
              />
              <Chip
                onClick={(c) => {
                  console.log('clicked ', c);
                }}
                sx={{
                  borderRadius: 0,
                  p: 3,
                  mr: 4,
                  borderWidth: 4,
                  borderColor: alpha(theme.palette.secondary.light, 0.2),
                }}
                label="Analytics"
                variant={'outlined'}
                color={'secondary'}
                icon={<InsightsIcon color={'secondary'} />}
              />
            </Box>

            <Stack direction={'column'}>
              <DocumentPrivacyInfoTile {...documentPrivacyInfoTileProps} />

              {/* end of  <Stack direction={'column'}> */}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Search;
