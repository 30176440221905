import { lxColors } from '../../../utils/LxColors';
import UnstyledButton, { UnstyledButtonProps } from './UnstyledButton';
import { lxFonts } from '../../../utils/LxFonts';

const OrangeButtonSx = {
  color: 'common.white',
  bgcolor: lxColors.button.orange,
  px: 2,
  border: 3,
  borderColor: lxColors.button.lightOrange,
  '&:hover': {
    bgcolor: lxColors.button.lightOrange,
  },
};

const TypographyProps = {
  fontSize: lxFonts.SYSTEM,
  fontWeight: 'bold',
};

const OrangeButton = ({ text, prefixIcon, sx }: UnstyledButtonProps) => {
  return (
    <UnstyledButton
      text={text}
      prefixIcon={prefixIcon}
      {...TypographyProps}
      sx={{ ...OrangeButtonSx, ...sx }}
    />
  );
};

export default OrangeButton;
