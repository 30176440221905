import React, { useEffect, useState } from 'react';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BackgroundedLabel from '../../../features/common/BackgroundedLabel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { HStack } from '../../../features/common/structures/Stacks';
import { lxColors, lxColorsNew } from '../../../utils/LxColors';
import { lxFonts } from '../../../utils/LxFonts';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SectionHeader from '../../../features/common/SectionHeader';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import accountHealthRelationshipData, {
  accountHealthSentimentData,
  accountHealthWorkData,
} from './AccountHealthFetch';
import { useParams } from 'react-router-dom';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';

type StatusText = 'good' | 'medium' | 'fair';
type TrendText = 'improving' | 'decreasing';
type AccountHealthType = 'relationship' | 'work' | 'sentiment';

type AccountBoxProps = {
  relationshipProps?: RelationshipProps;
  workProps?: WorkProps;
  sentimentProps?: SentimentProps;
};

export type SentimentProps = {
  sentimentBreakdown: { negAmount: number; title: string }[];
  status: Status;
};

export type RelationshipProps = {
  emails: number;
  meetings: number;
  people: number;
  status: Status;
};

export type WorkProps = {
  chats: number;
  tickets: number;
  files: number;
  status: Status;
};

type Status = {
  status: StatusText;
  trend: TrendText;
};

const AccountHealth = () => {
  const theme = useTheme();
  const { customerId } = useParams();
  const [relationship, setRelationship] = useState<RelationshipProps>({
    emails: 0,
    meetings: 0,
    people: 0,
    status: { status: 'medium', trend: 'improving' },
  });
  const [sentiment, setSentiment] = useState<SentimentProps>({
    sentimentBreakdown: [],
    status: { status: 'medium', trend: 'improving' },
  });
  const [work, setWork] = useState<WorkProps>({
    chats: 0,
    files: 0,
    tickets: 0,
    status: { status: 'medium', trend: 'improving' },
  });

  useEffect(() => {
    accountHealthRelationshipData().then((res) => {
      setRelationship(res.relationship!);
    });
  }, []);

  useEffect(() => {
    accountHealthWorkData().then((res) => {
      console.log('work=', res);
      setWork(res.work!);
    });
  }, []);

  useEffect(() => {
    accountHealthSentimentData().then((res) => {
      console.log('sentiment=', res);
      setSentiment(res.sentiment!);
    });
  }, []);

  const getTrend = (trend: TrendText) => {
    //orange
    const improving = trend === 'improving';
    const color = improving
      ? lxColorsNew.status.green
      : lxColorsNew.status.orange;
    return (
      <HStack spacing={1.5}>
        <Grid
          container
          width={28}
          height={28}
          sx={{
            backgroundColor: color,
            borderRadius: '100px',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          {improving ? (
            <NorthEastIcon sx={{ fill: theme.palette.common.white }} />
          ) : (
            <SouthWestIcon sx={{ fill: theme.palette.common.white }} />
          )}
        </Grid>
        <Typography
          fontFamily={lxFonts.SYSTEM}
          color={color}
          sx={{ textTransform: 'capitalize' }}
        >
          {trend}
        </Typography>
      </HStack>
    );
  };

  const getStatusBackgroundColor = (status: StatusText) => {
    switch (status) {
      case 'good':
        return {
          backgroundColor: lxColorsNew.status.green,
          fontColor: lxColorsNew.white,
          checkmarkCount: 3,
        };
      case 'medium':
        return {
          backgroundColor: lxColorsNew.status.orange,
          fontColor: lxColorsNew.white,
          checkmarkCount: 2,
        };
      case 'fair':
        return {
          backgroundColor: lxColorsNew.status.yellow,
          fontColor: lxColorsNew.grey.regular,
          checkmarkCount: 1,
        };
    }
  };

  const getStatus = (status: StatusText) => {
    const { backgroundColor, fontColor, checkmarkCount } =
      getStatusBackgroundColor(status);
    return (
      <HStack
        spacing={2}
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: 1,
          justifyContent: 'center',
          alignSelf: 'stretch',
          py: 0.5,
          pl: 2,
          pr: 2,
        }}
      >
        <HStack spacing={-0.75} sx={{ pt: 0.25 }}>
          {Array(checkmarkCount)
            .fill('')
            .map((_, index) => {
              return (
                <CheckIcon key={index} sx={{ fill: fontColor, fontSize: 20 }} />
              );
            })}
        </HStack>
        <Typography
          fontFamily={lxFonts.SYSTEM}
          fontWeight={'bold'}
          color={theme.palette.common.white}
          sx={{ textTransform: 'capitalize', color: fontColor }}
        >
          {status}
        </Typography>
      </HStack>
    );
  };

  const sentimentGradient = (
    title: string,
    percentageNegative: number,
    jsxIdx: number
  ) => {
    return (
      <HStack
        key={jsxIdx}
        sx={{
          pl: 2,
          width: '150px',
          backgroundColor: lxColorsNew.box.orange.backgroundColor,
          border: `1px solid ${lxColorsNew.orange.medium}`,
        }}
      >
        <div
          style={{
            height: '11px',
            width: '48px',
            // flexGrow: 1,
            borderRadius: '4px',
            background: `linear-gradient(90deg, #ff1212 , ${percentageNegative}%, #00ff80 )`,
          }}
        ></div>
        <div style={{ flexGrow: 1 }}>
          <Typography
            color={lxColorsNew.orange.dark}
            sx={{ textTransform: 'capitalize' }}
          >
            {title}
          </Typography>
        </div>
      </HStack>
    );
  };

  const getStatusData = (
    title: AccountHealthType,
    infoProps: AccountBoxProps
  ) => {
    switch (title) {
      case 'relationship':
        return infoProps.relationshipProps?.status;
      case 'sentiment':
        return infoProps.sentimentProps?.status;
      case 'work':
        return infoProps.workProps?.status;
    }
  };

  const box = (title: AccountHealthType, infoProps: AccountBoxProps) => {
    const { sentimentProps, workProps, relationshipProps } = infoProps;
    const status = getStatusData(title, infoProps);

    return (
      <Stack
        p={2}
        spacing={2}
        border={'1px solid'}
        borderColor={lxColors.box.defaultStyle.backgroundColor}
        sx={{
          // height: '200px',
          backgroundColor: 'white',
        }}
      >
        <Typography
          sx={{ textTransform: 'capitalize' }}
          fontFamily={lxFonts.HEADING}
        >
          {title}
        </Typography>
        <HStack spacing={8}>
          <Stack gap={1} alignItems={'flex-start'} sx={{ width: '100%' }}>
            {relationshipProps && (
              <>
                <BackgroundedLabel
                  label={`${relationshipProps?.emails} Emails`}
                  Icon={AlternateEmailIcon}
                />
                <BackgroundedLabel
                  label={`${relationshipProps?.meetings} Meetings`}
                  Icon={CalendarTodayIcon}
                />
                <BackgroundedLabel
                  label={`${relationshipProps?.people} People`}
                  Icon={PeopleOutlineIcon}
                />
              </>
            )}
            {workProps && (
              <>
                <BackgroundedLabel
                  label={`${workProps.chats} Chats`}
                  Icon={AlternateEmailIcon}
                />
                <BackgroundedLabel
                  label={`${workProps.tickets} Tickets`}
                  Icon={CalendarTodayIcon}
                />
                <BackgroundedLabel
                  label={`${workProps.files} Files`}
                  Icon={PeopleOutlineIcon}
                />
              </>
            )}
            {sentimentProps && sentimentProps.sentimentBreakdown.length > 0 && (
              <>
                {sentimentProps.sentimentBreakdown.map((v, idx) => {
                  return sentimentGradient(v.title, v.negAmount, idx);
                })}
              </>
            )}
          </Stack>
          <Stack spacing={2} alignItems={'center'}>
            {getStatus(status!.status)}
            {getTrend(status!.trend)}
          </Stack>
        </HStack>
      </Stack>
    );
  };

  return (
    <>
      {customerId && (
        <LxTypography variant={LxTypographyTypes.HEADING}>
          {customerId}
        </LxTypography>
      )}
      <SectionHeader
        sectionTitle={'Account Health'}
        side={
          <HStack spacing={1.5}>
            <Typography
              variant={'body2'}
              color={theme.palette.primary.main}
              fontSize={13}
            >
              90 days
            </Typography>
            <DateRangeIcon
              sx={{ fontSize: 20, fill: theme.palette.secondary.main }}
            />
          </HStack>
        }
      />
      <HStack
        alignItems={'stretch'}
        justifyContent={'space-between'}
        sx={{ minHeight: '203px' }}
      >
        {box('relationship', {
          relationshipProps: relationship,
        })}
        {box('work', {
          workProps: work,
        })}
        {box('sentiment', {
          sentimentProps: sentiment,
        })}
      </HStack>
    </>
  );
};

export default AccountHealth;
