import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactNode, SyntheticEvent, useState } from 'react';
import TabPanel from './TabPanel';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

type TabComponentProps = {
  tabLabels: string[];
  tabContents: ReactNode[];
};

const TabComponent = ({
  tabLabels,
  tabContents,
}: TabComponentProps): JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          {tabLabels.map((label, index) => (
            <Tab
              label={label}
              {...a11yProps(index)}
              sx={{ p: 1, pl: index === 0 ? 0 : 1, fontSize: 15 }}
            />
          ))}
        </Tabs>
      </Box>
      {tabContents.map((content, index) => (
        <TabPanel value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabComponent;
