import { Box, Grid, Typography } from '@mui/material';
import MonthChart from './MonthChart';
import { HStack } from '../../features/common/structures/Stacks';
import { CalendarYearData } from './CalendarDataTypes';
import moment from 'moment';

type YearChartProps = {
  calendarYearData: CalendarYearData;
};

const YearChart = ({ calendarYearData }: YearChartProps): JSX.Element => {
  return (
    <Grid container direction={'column'}>
      <HStack spacing={2} alignItems={'center'}>
        <Typography
          fontSize={'11px'}
          sx={{
            color: '#858585',
            textDecoration: 'none solid rgb(133, 133, 133);',
          }}
        >
          JAN {calendarYearData.year}
        </Typography>
        <Box
          flexGrow={1}
          height={'8px'}
          sx={{ bgcolor: 'primary.dark', mt: '-3px !important' }}
        ></Box>
        <Typography
          fontSize={'11px'}
          sx={{
            color: '#858585',
            textDecoration: 'none solid rgb(133, 133, 133);',
          }}
        >
          DEC {calendarYearData.year}
        </Typography>
      </HStack>
      <Box sx={{ height: '12px' }} />
      <Grid item container direction={'row'} spacing={1}>
        {Array.from({ length: 12 }).map((_, i) => {
          return (
            <Grid key={i} item xs={4}>
              <MonthChart
                monthName={moment(`${i + 1}`, 'MM').format('MMM')}
                monthNumber={i + 1}
                year={calendarYearData.year}
                monthData={calendarYearData.months[i + 1] || {}}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default YearChart;
