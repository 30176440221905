import { BadgeProps, styled } from '@mui/material';
import Badge from '@mui/material/Badge';

type CustomBadgeProps = BadgeProps & {
  top?: number;
  right?: number;
};

const CustomBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'success',
})<CustomBadgeProps>(({ top = 100, right = 46, theme }) => ({
  '.MuiBadge-root': {
    justifyContent: 'center',
  },
  '.MuiBadge-badge': {
    top: `${top}%`,
    right: `${right}%`,
  },
}));

export default CustomBadge;
