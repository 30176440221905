import {
  ColumnType,
  DefaultColumnStyle,
  MetricsTableCol,
  UseMetricsTableData,
} from '../../metricstable/MetricsTableTypes';
import useFetch from '../../../utils/data/useFetch';

type MetaDataRowEl = {
  id: string;
  stub: string;
  enabled: number;
  data: string;
  dbInsertTimeEpochMs: number;
  extraShort: number;
  extraInt: number;
  extraBin: string;
  dbUpdateBy: string;
};

const MetaDataColDef: MetricsTableCol[] = [
  {
    ...DefaultColumnStyle,
    field: 'id',
    headerName: 'ID',
  },
  {
    ...DefaultColumnStyle,
    field: 'orgid',
    headerName: 'Org ID',
  },
  {
    ...DefaultColumnStyle,
    field: 'stub',
    headerName: 'Stub',
  },
  {
    ...DefaultColumnStyle,
    field: 'enabled',
    headerName: 'Enabled',
  },
  {
    ...DefaultColumnStyle,
    field: 'data',
    headerName: 'Data',
    columnType: ColumnType.SERIALIZED_JSON,
  },
  {
    ...DefaultColumnStyle,
    field: 'dbInsertTimeEpochMs',
    headerName: 'DB Insert Time',
    columnType: ColumnType.EPOCH_TIME,
  },
  {
    ...DefaultColumnStyle,
    field: 'extraShort',
    headerName: 'Extra Short',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraInt',
    headerName: 'Extra Integer',
  },
  {
    ...DefaultColumnStyle,
    field: 'extraBin',
    headerName: 'Extra Binary',
  },
  {
    ...DefaultColumnStyle,
    field: 'dbUpdateBy',
    headerName: 'Update DB',
  },
];

type UseMetaDataTableData = UseMetricsTableData & {
  data: MetaDataRowEl[] | undefined | null;
};

export enum MetaDataTypes {
  WF_TENANTS = 'wfTenants',
  WF_EMPLOYEE = 'wfEmployee',
  WF_MAPPING = 'wfMapping',
  PROPS = 'props',
}

const getMetaDataURL = (metaDataType: MetaDataTypes): string => {
  const URL = '/api/debug/orgmeta';

  switch (metaDataType) {
    case MetaDataTypes.WF_TENANTS:
      return URL + '/wftenants';
    case MetaDataTypes.WF_EMPLOYEE:
      return URL + '/employees';
    case MetaDataTypes.WF_MAPPING:
      return URL + '/wfmappings';
    case MetaDataTypes.PROPS:
      return URL + '/props';
  }
};

const useMetaDataTableData = (
  orgId: number,
  metaDataType: MetaDataTypes
): UseMetaDataTableData => {
  const useFetchData = useFetch<MetaDataRowEl[]>(
    `${getMetaDataURL(metaDataType)}/${orgId}`,
    [orgId, metaDataType]
  );

  return {
    colDef: MetaDataColDef,
    ...useFetchData,
  };
};

export default useMetaDataTableData;
