import { lxFonts } from '../../utils/LxFonts';
import { lxColorsNew } from '../../utils/LxColors';

export enum LxTypographyTypes {
  HEADING = 'HEADING',
  SUBHEADING = 'SUBHEADING',
  CARD_HEADING = 'CARD_HEADING',
  CARD_SUB_HEADING_PRIMARY = 'CARD_SUB_HEADING_PRIMARY',
  CARD_SUB_HEADING_SECONDARY = 'CARD_SUB_HEADING_SECONDARY',

  TITLE_1 = 'TITLE_1',
  TITLE_2 = 'TITLE_2',
  TITLE_3 = 'TITLE_3',
  TITLE_4 = 'TITLE_4',

  DATA_1 = 'DATA_1',
  DATA_2 = 'DATA_2',
  DATA_3 = 'DATA_3',
  DATA_DESCRIPTION = 'DATA_DESCRIPTION',

  TILE = 'TILE',

  CHIP_PRIMARY = 'CHIP_PRIMARY',
  CHIP_SECONDARY = 'CHIP_SECONDARY',
  CHIP_VALUE = 'CHIP_VALUE',
  CHIP_LABEL = 'CHIP_LABEL',

  AVATAR_TITLE = 'AVATAR_TITLE',
  AVATAR_LARGE = 'AVATAR_LARGE',
  AVATAR_MEDIUM = 'AVATAR_MEDIUM',
  AVATAR_SMALL = 'AVATAR_SMALL',
  AVATAR_LABEL = 'AVATAR_LABEL',
}

export const getLxTypographyStyle = (variant: LxTypographyTypes): {} => {
  switch (variant) {
    case LxTypographyTypes.HEADING:
      return {
        fontSize: '24px',
        fontFamily: lxFonts.HEADING,
        color: lxColorsNew.grey.regular,
        fontWeight: 500,
      };
    case LxTypographyTypes.SUBHEADING:
      return {
        fontSize: '18px',
        fontFamily: lxFonts.HEADING,
        color: lxColorsNew.grey.regular,
        fontWeight: 500,
      };
    case LxTypographyTypes.CARD_HEADING:
      return {
        fontSize: '15px',
        fontFamily: lxFonts.HEADING,
        color: lxColorsNew.grey.dark,
        textTransform: 'uppercase',
      };
    case LxTypographyTypes.CARD_SUB_HEADING_PRIMARY:
      return {
        fontSize: '12px',
        fontFamily: lxFonts.HEADING,
        textTransform: 'uppercase',
        fontWeight: 500,
        color: lxColorsNew.orange.dark,
      };
    case LxTypographyTypes.CARD_SUB_HEADING_SECONDARY:
      return {
        fontSize: '12px',
        fontFamily: lxFonts.HEADING,
        textTransform: 'uppercase',
        fontWeight: 500,
        color: lxColorsNew.grey.dark,
      };
    case LxTypographyTypes.TITLE_1:
      return {
        fontSize: '24px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.TITLE_2:
      return {
        fontSize: '18px',
        fontFamily: lxFonts.DATA,
        fontWeight: 500,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.TITLE_3:
      return {
        fontSize: '16px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.TITLE_4:
      return {
        fontSize: '16px',
        fontFamily: lxFonts.DATA,
        fontWeight: 500,
        color: lxColorsNew.grey.regular,
      };
    // Data
    case LxTypographyTypes.DATA_1:
      return {
        fontSize: '14px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.DATA_2:
      return {
        fontSize: '12px',
        fontFamily: lxFonts.DATA,
        fontWeight: 500,
        color: lxColorsNew.grey.dark,
      };
    case LxTypographyTypes.DATA_3:
      return {
        fontSize: '12px',
        fontFamily: lxFonts.DATA,
        textTransform: 'uppercase',
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.DATA_DESCRIPTION:
      return {
        fontSize: '12px',
        fontFamily: lxFonts.DATA,
        textTransform: 'capitalize',
        color: lxColorsNew.grey.regular,
      };
    // Tile
    case LxTypographyTypes.TILE:
      return {
        fontSize: '11px',
        fontFamily: lxFonts.SYSTEM,
        color: lxColorsNew.orange.dark,
      };
    // Chip
    case LxTypographyTypes.CHIP_PRIMARY:
      return {
        fontSize: '13px',
        fontFamily: lxFonts.SYSTEM,
        color: lxColorsNew.orange.dark,
      };
    case LxTypographyTypes.CHIP_SECONDARY:
      return {
        fontSize: '13px',
        fontFamily: lxFonts.SYSTEM,
        color: lxColorsNew.orange.dark,
        fontWeight: 500,
      };
    case LxTypographyTypes.CHIP_VALUE:
      return {
        fontSize: '13px',
        fontFamily: lxFonts.SYSTEM,
        color: lxColorsNew.orange.dark,
        fontWeight: 500,
      };
    case LxTypographyTypes.CHIP_LABEL:
      return {
        fontSize: '11px',
        fontFamily: lxFonts.SYSTEM,
        color: lxColorsNew.grey.regular,
      };
    // Avatar
    case LxTypographyTypes.AVATAR_TITLE:
      return {
        fontSize: '24px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.black,
      };
    case LxTypographyTypes.AVATAR_LARGE:
      return {
        fontSize: '18px',
        fontFamily: lxFonts.DATA,
        fontWeight: 500,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.AVATAR_MEDIUM:
      return {
        fontSize: '16px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.AVATAR_SMALL:
      return {
        fontSize: '11px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.regular,
      };
    case LxTypographyTypes.AVATAR_LABEL:
      return {
        fontSize: '11px',
        fontFamily: lxFonts.DATA,
        color: lxColorsNew.grey.light,
      };
    default:
      return {};
  }
};
