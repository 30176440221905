import { Avatar } from '@mui/material';
import Badge from '@mui/material/Badge';
import { Employee } from '../../../utils/types/Entities';
import { lxColors } from '../../../utils/LxColors';
import PeopleIcon from '@mui/icons-material/People';
import { ShareLevelEnum } from '../../../components/sharelevel/ShareLevelConstants';
import { CubesIcon } from '../icons/SvgIcons';
import BusinessIcon from '@mui/icons-material/Business';

// Todo: to be refactored
type BadgedAvatarProps = {
  person: Employee;
  variant: ShareLevelEnum;
};

const badgeSx = {
  color: 'primary.main',
  p: 0.5,
  bgcolor: 'white',
  border: 4,
  borderRadius: '100%',
  borderColor: lxColors.icons.border.color,
};

const getBadge = (variant: ShareLevelEnum) => {
  switch (variant) {
    case ShareLevelEnum.USER:
      return <PeopleIcon sx={badgeSx} />;
    case ShareLevelEnum.TEAM:
      return <PeopleIcon sx={badgeSx} />;
    case ShareLevelEnum.COLLABORATORS:
      return <CubesIcon sx={badgeSx} />;
    case ShareLevelEnum.ORGANIZATION:
      return <BusinessIcon sx={badgeSx} />;
  }
};

export const BadgedAvatar = ({ person, variant }: BadgedAvatarProps) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      badgeContent={getBadge(variant)}
    >
      <Avatar
        src={person.avatarSrc}
        alt={person.name}
        sx={{ width: 78, height: 78 }}
      />
    </Badge>
  );
};
