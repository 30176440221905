import { createSvgIcon, SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

// Custom made icons
export const CubesIcon = createSvgIcon(
  <path
    d="M8.3,19.4l3.2-1.9v-3.2L8.3,16V19.4z M7.8,14.8l3.3-1.7l-3.3-1.7l-3.3,1.7L7.8,14.8z M16.8,19.4l3.2-1.9v-3.2L16.8,16V19.4z M16.2,14.8l3.3-1.7l-3.3-1.7l-3.3,1.7L16.2,14.8z M12.5,11.9l3.2-1.7V7.6l-3.2,1.6V11.9L12.5,11.9z M12,8.1l3.6-1.9L12,4.3L8.4,6.2 L12,8.1z M21,13.3v4.2c0,0.2-0.1,0.5-0.2,0.7s-0.2,0.4-0.4,0.5l-3.7,2.2C16.6,21,16.4,21,16.2,21c-0.2,0-0.3,0-0.5-0.1l-3.7-2.2 c0,0,0,0-0.1,0c0,0,0,0-0.1,0l-3.7,2.2C8.1,21,7.9,21,7.8,21c-0.2,0-0.3,0-0.5-0.1l-3.7-2.2c-0.2-0.1-0.3-0.3-0.4-0.5S3,17.7,3,17.5 v-4.2c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5l3.6-1.9v-4c0-0.3,0.1-0.5,0.2-0.7C7.5,5.3,7.7,5.1,7.9,5l3.7-1.9 C11.7,3,11.9,3,12,3c0.1,0,0.3,0,0.4,0.1L16.1,5c0.2,0.1,0.3,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v4l3.6,1.9 c0.2,0.1,0.4,0.3,0.5,0.5C20.9,12.8,21,13,21,13.3L21,13.3z"
    vectorEffect="non-scaling-stroke"
  />,
  'Cubes'
);

export const AsteriskIcon = createSvgIcon(
  <path
    id="asterisk"
    d="M16,12l2.77-1.6c0.31-0.17,0.54-0.46,0.62-0.81c0.09-0.34,0.05-0.7-0.13-1l-0.67-1.16  c-0.17-0.31-0.46-0.54-0.81-0.62c-0.34-0.09-0.7-0.05-1,0.13L14,8.53V5.34C13.99,4.61,13.4,4.01,12.67,4h-1.34  C10.6,4.01,10.01,4.61,10,5.34v3.2l-2.77-1.6c-0.3-0.18-0.66-0.22-1-0.13C5.88,6.89,5.59,7.12,5.42,7.43L4.74,8.57  c-0.18,0.3-0.22,0.66-0.13,1c0.08,0.35,0.31,0.64,0.62,0.81L8,12l-2.77,1.6c-0.31,0.17-0.54,0.46-0.62,0.81  c-0.09,0.34-0.05,0.7,0.13,1l0.67,1.15c0.17,0.31,0.46,0.54,0.81,0.62c0.34,0.09,0.7,0.05,1-0.13L10,15.47v3.2  c0.01,0.73,0.6,1.32,1.33,1.33h1.33c0.74-0.01,1.33-0.6,1.34-1.34v-3.2l2.77,1.59c0.3,0.18,0.66,0.22,1,0.13  c0.35-0.08,0.64-0.31,0.81-0.62l0.67-1.15c0.18-0.3,0.22-0.66,0.13-1c-0.08-0.35-0.31-0.64-0.62-0.81L16,12z"
    vectorEffect="non-scaling-stroke"
  />,
  'Asterisk'
);

export const AsteriskThreeIcon = (props: { [key: string]: any }) => {
  return (
    <SvgIcon viewBox={'0 0 31 37'} {...props}>
      <path d="M10.7653 7L13.3728 5.6C13.6646 5.45125 13.8811 5.1975 13.9564 4.89125C14.0411 4.59375 14.0035 4.27875 13.834 4.01625L13.2033 3.00125C13.0433 2.73 12.7703 2.52875 12.4409 2.45875C12.1208 2.38 11.7819 2.415 11.4995 2.5725L8.88265 3.96375V1.1725C8.87324 0.53375 8.31786 0.00875 7.63069 0H6.36931C5.68214 0.00875 5.12676 0.53375 5.11735 1.1725V3.9725L2.50988 2.5725C2.22748 2.415 1.8886 2.38 1.56855 2.45875C1.23909 2.52875 0.966105 2.73 0.80608 3.00125L0.165978 3.99875C-0.00346051 4.26125 -0.0411132 4.57625 0.0436061 4.87375C0.118912 5.18 0.335417 5.43375 0.627228 5.5825L3.2347 7L0.627228 8.4C0.335417 8.54875 0.118912 8.8025 0.0436061 9.10875C-0.0411132 9.40625 -0.00346051 9.72125 0.165978 9.98375L0.796666 10.99C0.956691 11.2612 1.22968 11.4625 1.55914 11.5325C1.87919 11.6113 2.21807 11.5762 2.50047 11.4187L5.11735 10.0363V12.8363C5.12676 13.475 5.68214 13.9913 6.36931 14H7.62128C8.31786 13.9913 8.87324 13.475 8.88265 12.8275V10.0275L11.4901 11.4187C11.7725 11.5762 12.1114 11.6113 12.4314 11.5325C12.7609 11.4625 13.0339 11.2612 13.1939 10.99L13.8246 9.98375C13.994 9.72125 14.0317 9.40625 13.947 9.10875C13.8717 8.8025 13.6552 8.54875 13.3634 8.4L10.7653 7Z" />
      <path d="M27.0722 18.5L30.2384 16.6C30.5927 16.3981 30.8556 16.0538 30.947 15.6381C31.0499 15.2344 31.0042 14.8069 30.7985 14.4506L30.0326 13.0731C29.8383 12.705 29.5068 12.4319 29.1068 12.3369C28.7181 12.23 28.3066 12.2775 27.9637 12.4913L24.7861 14.3794V10.5913C24.7746 9.72438 24.1003 9.01187 23.2658 9H21.7342C20.8997 9.01187 20.2254 9.72438 20.2139 10.5913V14.3912L17.0477 12.4913C16.7048 12.2775 16.2933 12.23 15.9047 12.3369C15.5046 12.4319 15.1731 12.705 14.9788 13.0731L14.2015 14.4269C13.9958 14.7831 13.9501 15.2106 14.053 15.6144C14.1444 16.03 14.4073 16.3744 14.7616 16.5763L17.9278 18.5L14.7616 20.4C14.4073 20.6019 14.1444 20.9462 14.053 21.3619C13.9501 21.7656 13.9958 22.1931 14.2015 22.5494L14.9674 23.915C15.1617 24.2831 15.4932 24.5563 15.8932 24.6513C16.2819 24.7581 16.6934 24.7106 17.0363 24.4969L20.2139 22.6206V26.4206C20.2254 27.2875 20.8997 27.9881 21.7342 28H23.2544C24.1003 27.9881 24.7746 27.2875 24.7861 26.4087V22.6088L27.9523 24.4969C28.2952 24.7106 28.7067 24.7581 29.0953 24.6513C29.4954 24.5563 29.8269 24.2831 30.0212 23.915L30.787 22.5494C30.9928 22.1931 31.0385 21.7656 30.9356 21.3619C30.8442 20.9462 30.5813 20.6019 30.2269 20.4L27.0722 18.5Z" />
      <path d="M14.4585 31.5L16.5072 30.4C16.7365 30.2831 16.9066 30.0838 16.9657 29.8431C17.0323 29.6094 17.0027 29.3619 16.8696 29.1556L16.374 28.3581C16.2483 28.145 16.0338 27.9869 15.775 27.9319C15.5235 27.87 15.2572 27.8975 15.0353 28.0213L12.9792 29.1144V26.9213C12.9718 26.4194 12.5355 26.0069 11.9955 26H11.0045C10.4645 26.0069 10.0282 26.4194 10.0208 26.9213V29.1212L7.97205 28.0213C7.75016 27.8975 7.4839 27.87 7.23243 27.9319C6.97357 27.9869 6.75908 28.145 6.63335 28.3581L6.13041 29.1419C5.99728 29.3481 5.9677 29.5956 6.03426 29.8294C6.09343 30.07 6.26354 30.2694 6.49282 30.3863L8.54155 31.5L6.49282 32.6C6.26354 32.7169 6.09343 32.9162 6.03426 33.1569C5.9677 33.3906 5.99728 33.6381 6.13041 33.8444L6.62595 34.635C6.75169 34.8481 6.96617 35.0063 7.22504 35.0613C7.47651 35.1231 7.74277 35.0956 7.96465 34.9719L10.0208 33.8856V36.0856C10.0282 36.5875 10.4645 36.9931 11.0045 37H11.9881C12.5355 36.9931 12.9718 36.5875 12.9792 36.0787V33.8788L15.028 34.9719C15.2498 35.0956 15.5161 35.1231 15.7676 35.0613C16.0264 35.0063 16.2409 34.8481 16.3667 34.635L16.8622 33.8444C16.9953 33.6381 17.0249 33.3906 16.9583 33.1569C16.8992 32.9162 16.7291 32.7169 16.4998 32.6L14.4585 31.5Z" />
    </SvgIcon>
  );
};

type ColleaguesIconProps = SvgIconProps & {
  peopleColor?: string;
  ovalColor?: string;
};

export const ColleaguesIcon = ({
  peopleColor,
  ovalColor,
  ...otherProps
}: ColleaguesIconProps) => {
  return (
    <SvgIcon viewBox={'0 0 78 60'} {...otherProps}>
      <path
        d="M26 5C40.3498 5 52 16.6502 52 31C52 45.3498 40.3498 57 26 57C11.6502 57 0 45.3498 0 31C0 16.6502 11.6502 5 26 5Z"
        fill={ovalColor ? ovalColor : '#D4E9FF'}
      />
      <path
        d="M32.0208 32.3333C30.4708 32.3333 28.0554 32.7725 26.2083 33.625C24.3612 32.7596 21.9458 32.3333 20.3958 32.3333C17.5929 32.3333 12 33.7283 12 36.5313V40.0833H40.4167V36.5313C40.4167 33.7283 34.8237 32.3333 32.0208 32.3333ZM26.8542 38.1458H13.9375V36.5313C13.9375 35.8338 17.2442 34.2708 20.3958 34.2708C23.5475 34.2708 26.8542 35.8338 26.8542 36.5313V38.1458ZM38.4792 38.1458H28.7917V36.5313C28.7917 35.9371 28.5333 35.4204 28.12 34.9554C29.2567 34.5679 30.6517 34.2708 32.0208 34.2708C35.1725 34.2708 38.4792 35.8338 38.4792 36.5313V38.1458ZM20.3958 31.0417C22.8887 31.0417 24.9167 29.0138 24.9167 26.5208C24.9167 24.0279 22.8887 22 20.3958 22C17.9029 22 15.875 24.0279 15.875 26.5208C15.875 29.0138 17.9029 31.0417 20.3958 31.0417ZM20.3958 23.9375C21.8167 23.9375 22.9792 25.1 22.9792 26.5208C22.9792 27.9417 21.8167 29.1042 20.3958 29.1042C18.975 29.1042 17.8125 27.9417 17.8125 26.5208C17.8125 25.1 18.975 23.9375 20.3958 23.9375ZM32.0208 31.0417C34.5137 31.0417 36.5417 29.0138 36.5417 26.5208C36.5417 24.0279 34.5137 22 32.0208 22C29.5279 22 27.5 24.0279 27.5 26.5208C27.5 29.0138 29.5279 31.0417 32.0208 31.0417ZM32.0208 23.9375C33.4417 23.9375 34.6042 25.1 34.6042 26.5208C34.6042 27.9417 33.4417 29.1042 32.0208 29.1042C30.6 29.1042 29.4375 27.9417 29.4375 26.5208C29.4375 25.1 30.6 23.9375 32.0208 23.9375Z"
        fill={peopleColor ? peopleColor : '#7CB7F7'}
      />
      <path
        d="M65 0C72.1749 0 78 5.8251 78 13C78 20.1749 72.1749 26 65 26C57.8251 26 52 20.1749 52 13C52 5.8251 57.8251 0 65 0Z"
        fill={ovalColor ? ovalColor : '#D4E9FF'}
      />
      <path
        d="M68.0104 13.6667C67.2354 13.6667 66.0277 13.8863 65.1042 14.3125C64.1806 13.8798 62.9729 13.6667 62.1979 13.6667C60.7965 13.6667 58 14.3642 58 15.7656V17.5417H72.2083V15.7656C72.2083 14.3642 69.4119 13.6667 68.0104 13.6667ZM65.4271 16.5729H58.9687V15.7656C58.9687 15.4169 60.6221 14.6354 62.1979 14.6354C63.7737 14.6354 65.4271 15.4169 65.4271 15.7656V16.5729ZM71.2396 16.5729H66.3958V15.7656C66.3958 15.4685 66.2667 15.2102 66.06 14.9777C66.6283 14.784 67.3258 14.6354 68.0104 14.6354C69.5862 14.6354 71.2396 15.4169 71.2396 15.7656V16.5729ZM62.1979 13.0208C63.4444 13.0208 64.4583 12.0069 64.4583 10.7604C64.4583 9.51396 63.4444 8.5 62.1979 8.5C60.9515 8.5 59.9375 9.51396 59.9375 10.7604C59.9375 12.0069 60.9515 13.0208 62.1979 13.0208ZM62.1979 9.46875C62.9083 9.46875 63.4896 10.05 63.4896 10.7604C63.4896 11.4708 62.9083 12.0521 62.1979 12.0521C61.4875 12.0521 60.9062 11.4708 60.9062 10.7604C60.9062 10.05 61.4875 9.46875 62.1979 9.46875ZM68.0104 13.0208C69.2569 13.0208 70.2708 12.0069 70.2708 10.7604C70.2708 9.51396 69.2569 8.5 68.0104 8.5C66.764 8.5 65.75 9.51396 65.75 10.7604C65.75 12.0069 66.764 13.0208 68.0104 13.0208ZM68.0104 9.46875C68.7208 9.46875 69.3021 10.05 69.3021 10.7604C69.3021 11.4708 68.7208 12.0521 68.0104 12.0521C67.3 12.0521 66.7187 11.4708 66.7187 10.7604C66.7187 10.05 67.3 9.46875 68.0104 9.46875Z"
        fill={peopleColor ? peopleColor : '#7CB7F7'}
      />
      <path
        d="M59.5 41C64.7432 41 69 45.2568 69 50.5C69 55.7432 64.7432 60 59.5 60C54.2568 60 50 55.7432 50 50.5C50 45.2568 54.2568 41 59.5 41Z"
        fill={ovalColor ? ovalColor : '#D4E9FF'}
      />
      <path
        d="M61.6999 50.9872C61.1336 50.9872 60.251 51.1476 59.5761 51.4591C58.9012 51.1429 58.0187 50.9872 57.4523 50.9872C56.4282 50.9872 54.3846 51.4969 54.3846 52.521V53.8189H64.7676V52.521C64.7676 51.4969 62.7241 50.9872 61.6999 50.9872ZM59.8121 53.111H55.0925V52.521C55.0925 52.2662 56.3008 51.6951 57.4523 51.6951C58.6039 51.6951 59.8121 52.2662 59.8121 52.521V53.111ZM64.0597 53.111H60.52V52.521C60.52 52.3039 60.4256 52.1152 60.2746 51.9452C60.6899 51.8037 61.1996 51.6951 61.6999 51.6951C62.8515 51.6951 64.0597 52.2662 64.0597 52.521V53.111ZM57.4523 50.5152C58.3632 50.5152 59.1042 49.7743 59.1042 48.8634C59.1042 47.9525 58.3632 47.2115 57.4523 47.2115C56.5414 47.2115 55.8005 47.9525 55.8005 48.8634C55.8005 49.7743 56.5414 50.5152 57.4523 50.5152ZM57.4523 47.9195C57.9715 47.9195 58.3962 48.3442 58.3962 48.8634C58.3962 49.3825 57.9715 49.8073 57.4523 49.8073C56.9332 49.8073 56.5084 49.3825 56.5084 48.8634C56.5084 48.3442 56.9332 47.9195 57.4523 47.9195ZM61.6999 50.5152C62.6108 50.5152 63.3518 49.7743 63.3518 48.8634C63.3518 47.9525 62.6108 47.2115 61.6999 47.2115C60.789 47.2115 60.0481 47.9525 60.0481 48.8634C60.0481 49.7743 60.789 50.5152 61.6999 50.5152ZM61.6999 47.9195C62.2191 47.9195 62.6438 48.3442 62.6438 48.8634C62.6438 49.3825 62.2191 49.8073 61.6999 49.8073C61.1808 49.8073 60.756 49.3825 60.756 48.8634C60.756 48.3442 61.1808 47.9195 61.6999 47.9195Z"
        fill={peopleColor ? peopleColor : '#7CB7F7'}
      />
    </SvgIcon>
  );
};

export const WonkaIcon = createSvgIcon(
  <path
    id="wonka"
    d="M15.56,19.74h0.51L21.89,6.3c0.31-0.9,1.16-1.5,2.11-1.51V4.31h-4.79v0.48c0.2,0.01,0.39,0.03,0.58,0.08  c0.24,0.07,0.48,0.18,0.68,0.33c0.24,0.16,0.42,0.38,0.52,0.65l-4.62,10.59l-1.27-2.85c-0.79-1.79-1.24-2.79-1.33-3L16,6.01  c0.24-0.35,0.56-0.64,0.94-0.84c0.38-0.21,0.81-0.33,1.24-0.34V4.27h-4.62v0.27c0.01,0.09,0.01,0.17,0,0.26  c0.46-0.03,0.93,0.07,1.34,0.28c0.24,0.21,0.29,0.57,0.1,0.83c-0.44,1.03-1.04,2.29-1.8,3.78c-0.22-0.47-0.52-1.14-0.9-2  c-0.38-0.86-0.68-1.53-0.91-2c-0.13-0.23-0.07-0.52,0.14-0.68c0.3-0.18,0.65-0.27,1-0.26V4.29H7.16V4.8c1.13,0,1.83,0.25,2.09,0.74  c0.2,0.37,0.67,1.39,1.42,3.07s1.19,2.66,1.33,2.93l-1.38,2.57c-0.75,1.43-1.24,2.35-1.46,2.77c-0.31-0.67-1.13-2.54-2.45-5.62  L4.45,6.07c-0.12-0.22-0.08-0.49,0.1-0.67c0.19-0.21,0.43-0.36,0.69-0.45c0.27-0.1,0.55-0.15,0.83-0.17V4.27H0c0,0.05,0,0.15,0,0.28  C0,4.68,0,4.76,0,4.8c0.39,0.04,0.76,0.19,1.06,0.45c0.4,0.29,0.76,0.62,1.08,1c0.28,0.34,0.51,0.72,0.69,1.12  c0.6,1.4,1.5,3.47,2.7,6.21s2.09,4.78,2.66,6.11h0.28h0.3c0.45-0.92,1.14-2.27,2.07-4.06l1.65-3.18l3.07,7.24l0,0L15.56,19.74z"
  />,
  'Cubes'
);

export const PartnerIcon = createSvgIcon(
  <path
    id="codepie"
    d="M19.52,16.06c-2.07,3.63-6.53,5.13-10.37,3.5c-1.99-0.82-3.57-2.4-4.39-4.39c-0.87-2.05-0.87-4.36,0-6.41  c0.82-1.99,2.4-3.57,4.39-4.4c2.31-0.98,4.94-0.86,7.15,0.34c1.22,0.65,2.25,1.6,3,2.76L17,8.76c-1.51-2.16-4.33-2.97-6.76-1.94  c-1.36,0.56-2.44,1.64-3,3c-1.21,2.84,0.11,6.12,2.95,7.33c0.7,0.3,1.46,0.45,2.22,0.45c0.96,0,1.91-0.24,2.74-0.72  c0.83-0.48,1.52-1.17,2-2l2.27,1.16l0,0L19.52,16.06z M20.75,7.84c-0.81-1.56-2.01-2.87-3.49-3.82c-1.47-0.93-3.17-1.41-4.9-1.39  c-1.24,0-2.48,0.25-3.62,0.74c-2.27,0.93-4.07,2.73-5,5c-0.99,2.32-0.99,4.93,0,7.25c0.93,2.27,2.73,4.07,5,5  c1.14,0.49,2.38,0.74,3.62,0.74c1.83,0.02,3.61-0.51,5.13-1.53c1.55-1.05,2.77-2.52,3.51-4.24l-7.25-3.63L20.75,7.84z M19.1,11.96  c0.22,0.01,0.43-0.07,0.6-0.21c0.15-0.13,0.25-0.3,0.29-0.5c0.04-0.19,0.04-0.39,0-0.58c-0.04-0.2-0.15-0.37-0.3-0.5  c-0.16-0.14-0.37-0.22-0.58-0.21h-1.42v3.66h1v-1.66H19.1z"
  />,
  'Cubes'
);

export const WindowsIcon = createSvgIcon(
  <path
    id="windows"
    d="M3.34,12.48v5.8l7.1,1v-6.8H3.34z M3.34,5.72v5.88h7.1V4.74L3.34,5.72z M11.22,12.48v6.88l9.44,1.3v-8.18  H11.22z M11.22,4.64v7h9.44v-8.3L11.22,4.64z"
  />,
  'Windows'
);
