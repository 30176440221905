import { useCallback, useEffect, useState } from 'react';
import {
  ListWithDetailSchemaUtils,
  OrgSchema,
  forEachSchemaProperty,
} from '../../../../../utils/data/jsonSchema';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TabPanel from '../../../../../components/TabPanel';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { OrgSettingsTabPanelProps } from './OrgSettings';
import { VStack } from '../../../../../features/common/structures/Stacks';
import { OrgSettings } from '../../../../../utils/types/Entities';

const INTEGRATION_INFO_KEYNAME = 'appId';
const INTEGRATION_INFO_PROPERTY_SUFFIX = 'IntegrationInfoMap';

/**
 * Map from integration map property (ex. "googleIntegrationInfoMap") to the map.
 */
export type IntegrationInfoMap = {
  [integrationInfoMapName: string]: object;
};

export type OrgSettingsIntegrationInfoViewProps = {
  integrationInfoMap?: IntegrationInfoMap;
  orgSettingsSchema?: OrgSchema;
  integrationInfoMapProperty: string;
  integrationInfoKeyName: string;
  onChange: (integrationInfoMap: IntegrationInfoMap, isValid: boolean) => any;
};

/**
 * This view edits a single integration info map identified by the integrationInfoMapProperty.
 * The map is converted to an array internally so that JsonForms can show it
 * in a ListWithDetail view (see https://jsonforms.io/examples/list-with-detail/).
 */
const OrgSettingsIntegrationInfoView = ({
  integrationInfoMap: initialIntegrationInfoMap,
  orgSettingsSchema,
  integrationInfoMapProperty,
  integrationInfoKeyName,
  onChange,
}: OrgSettingsIntegrationInfoViewProps) => {
  // TODO: Refactor processing of initialData, schema, uiSchema into hook.
  const [initialData, setInitialData] = useState<IntegrationInfoMap>();
  const [schema, setSchema] = useState<JsonSchema>();
  const [uiSchema, setUiSchema] = useState<UISchemaElement>();

  useEffect(() => {
    if (initialIntegrationInfoMap) {
      setInitialData(
        ListWithDetailSchemaUtils.toJsonFormsData(
          integrationInfoMapProperty,
          integrationInfoKeyName,
          initialIntegrationInfoMap
        )
      );
    }
  }, [
    integrationInfoMapProperty,
    integrationInfoKeyName,
    initialIntegrationInfoMap,
  ]);

  useEffect(() => {
    if (orgSettingsSchema) {
      // Convert the JSON schema from "object" to "array".
      setSchema(
        ListWithDetailSchemaUtils.schema(
          integrationInfoMapProperty,
          integrationInfoKeyName,
          orgSettingsSchema
        )
      );
      // Generate a UI schema for list with detail view with all
      // integration info properties in the detail.
      setUiSchema(
        ListWithDetailSchemaUtils.uiSchema(
          integrationInfoMapProperty,
          integrationInfoKeyName,
          orgSettingsSchema
        )
      );
    }
  }, [integrationInfoMapProperty, integrationInfoKeyName, orgSettingsSchema]);

  return initialData && schema && uiSchema ? (
    <JsonForms
      schema={schema}
      uischema={uiSchema}
      data={initialData}
      renderers={materialRenderers}
      onChange={({ data, errors }) => {
        const [edited, hasErrors] = ListWithDetailSchemaUtils.fromJsonFormsData(
          integrationInfoMapProperty,
          integrationInfoKeyName,
          data
        );
        onChange(
          Object.assign({}, initialIntegrationInfoMap, edited),
          !hasErrors && (errors?.length ?? 0) === 0
        );
      }}
    />
  ) : (
    <CircularProgress size={50} />
  );
};

function extractIntegrationInfoMapProperties(
  schema: JsonSchema,
  orgSettings: OrgSettings
) {
  const integrationInfoMap: IntegrationInfoMap = {};
  forEachSchemaProperty((key, value) => {
    if (key.endsWith(INTEGRATION_INFO_PROPERTY_SUFFIX)) {
      if (orgSettings && orgSettings[key]) {
        integrationInfoMap[key] = orgSettings[key];
      }
    }
  }, schema);
  return integrationInfoMap;
}

/**
 * Tab for editing integration info map properties of SettingsJson.
 * User selects integration info map property (ex. "googleIntegrationInfoMap")
 * and edits the map within a JsonForms list with detail view.
 */
const OrgSettingsIntegrationInfoTabPanel = ({
  orgSettings,
  orgSettingsSchema,
  tabIndex,
  tabIndexValue,
  onChange,
}: OrgSettingsTabPanelProps) => {
  const [integrationInfoMap, setIntegrationInfoMap] =
    useState<IntegrationInfoMap>();
  const [editedIntegrationInfoMap, setEditedIntegrationInfoMap] =
    useState<IntegrationInfoMap>();
  const [integrationInfoMapProperty, setIntegrationInfoMapProperty] =
    useState<string>('');
  const [integrationInfoMapProperties, setIntegrationInfoMapProperties] =
    useState<string[]>([]);

  const processSchema = useCallback(
    (schema: JsonSchema) => {
      if (orgSettings) {
        const integrationInfoMap = extractIntegrationInfoMapProperties(
          schema,
          orgSettings
        );
        const mapProps: string[] = Object.keys(integrationInfoMap);
        setIntegrationInfoMap(integrationInfoMap);
        setIntegrationInfoMapProperty(mapProps.length > 0 ? mapProps[0] : '');
        setIntegrationInfoMapProperties(mapProps);
      }
    },
    [orgSettings]
  );

  useEffect(() => {
    if (orgSettingsSchema) {
      processSchema(orgSettingsSchema);
    }
  }, [orgSettings, orgSettingsSchema, processSchema]);

  const onIntegrationInfoChange = (
    integrationInfoMap: IntegrationInfoMap,
    isValid: boolean
  ) => {
    // Store the edited integration info map in a separate property.
    // Changing the "data" property of JsonForms every time a field is edited
    // causes the list with detail view to lose focus.
    setEditedIntegrationInfoMap(integrationInfoMap);
    onChange(integrationInfoMap, isValid);
  };

  return (
    <TabPanel value={tabIndexValue} index={tabIndex}>
      <VStack alignItems={'flex-start'} sx={{ paddingTop: '1em' }} fullWidth>
        <Select
          value={integrationInfoMapProperty}
          label={'Property'}
          onChange={(e) => {
            setIntegrationInfoMap(
              Object.assign({}, integrationInfoMap, editedIntegrationInfoMap)
            );
            setIntegrationInfoMapProperty(e.target.value);
          }}
        >
          {integrationInfoMapProperties.map((propName) => (
            <MenuItem key={propName} value={propName}>
              {propName}
            </MenuItem>
          ))}
        </Select>
        {integrationInfoMapProperty !== '' && (
          <OrgSettingsIntegrationInfoView
            integrationInfoMap={integrationInfoMap}
            orgSettingsSchema={orgSettingsSchema}
            integrationInfoMapProperty={integrationInfoMapProperty}
            integrationInfoKeyName={INTEGRATION_INFO_KEYNAME}
            onChange={onIntegrationInfoChange}
          />
        )}
      </VStack>
    </TabPanel>
  );
};

export default OrgSettingsIntegrationInfoTabPanel;
