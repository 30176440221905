import AccountHealth from './pagecomponents/accountintelligence/AccountHealth';
import { Box } from '@mui/material';
import DiscussionThreadSection from './pagecomponents/accountintelligence/DiscussionThreadSection';
import { HStack } from '../features/common/structures/Stacks';
import InternalPeopleCard from '../features/accountintelligence/InternalPeopleCard';
import PeopleAndConnectionsSection from './pagecomponents/accountintelligence/PeopleAndConnectionsSection';
import PeopleConnectionSankeyChart from '../features/accountintelligence/PeopleConnectionSankeyChart';
import SectionHeader from '../features/common/SectionHeader';
import SentimentConversationVolumeSection from './pagecomponents/accountintelligence/SentimentConversationVolumeSection';
import ThirdPartiesInvolvedSection from './pagecomponents/accountintelligence/ThirdPartiesInvolvedSection';

const AccountIntelligence = (): JSX.Element => {
  const getInternalPeopleSection = () => {
    return (
      <>
        <SectionHeader sectionTitle={'Internal People'} side={<></>} />
        <HStack>
          <InternalPeopleCard />
          <InternalPeopleCard />
        </HStack>
      </>
    );
  };

  const getConnectionsBetweenPeopleSection = () => {
    return (
      <>
        <SectionHeader
          sectionTitle={'Connections between People'}
          side={<></>}
        />
        <PeopleConnectionSankeyChart />
      </>
    );
  };

  return (
    <Box>
      <AccountHealth />
      <DiscussionThreadSection />
      <PeopleAndConnectionsSection />
      <SentimentConversationVolumeSection />
      <ThirdPartiesInvolvedSection />
      {getInternalPeopleSection()}
      {getConnectionsBetweenPeopleSection()}
    </Box>
  );
};
export default AccountIntelligence;
