import UnstyledButton, { UnstyledButtonProps } from './UnstyledButton';

const GreyButtonSx = {
  border: 1,
  borderRadius: 1,
  borderColor: '#ededed',
  ml: 1,
  py: 0.2,
  px: 1,
};

const GreyButton = ({
  text,
  prefixIcon,
  sx,
  ...otherProps
}: UnstyledButtonProps) => {
  return (
    <UnstyledButton
      text={text}
      prefixIcon={prefixIcon}
      sx={{ ...GreyButtonSx, ...sx }}
      {...otherProps}
    />
  );
};

export default GreyButton;
