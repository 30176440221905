import { alpha, Box, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { blueBoxBorder } from '../features/common/styles/BoxSx';
import {
  AirbnbSlider,
  AirbnbThumbComponent,
  AvatarMessages,
  ConversationAndVolumeChart,
  TimelineTitle,
} from '../pages/search';
import { ConversationVolumeBarChart } from './ConversationVolumeBarChart';

export type IdValueName = {
  id: string;
  value: number;
  name: string;
};

const TimelineSection = () => {
  const theme = useTheme();

  // temporarily data for the volume chart
  let tmpConversationData: IdValueName[] = [];
  for (let i = 0; i < 365 * 3; ++i) {
    let vv: number = parseInt((i / 10).toString());

    let thirtyMultiple = parseInt((i / 30).toString());
    let isOdd = vv % 2 === 1;

    if (isOdd) {
      vv = 10 - (i % 10);
    } else {
      vv = i % 10;
    } // always be from 0 ~ 10

    vv += Math.random() * 100;

    if (thirtyMultiple % 2 === 0) {
      vv = 2;
    }

    let n = {
      id: 'id' + i,
      value: vv,
      name: 'name' + i,
    };
    tmpConversationData.push(n);
  }
  let defaultDateSliderValue = [90, 95];
  const [conversationVolumeData] = useState<IdValueName[]>(tmpConversationData);

  const sliceConversationVolumeData = (l: number, r: number) => {
    let len = conversationVolumeData.length;
    l = (l / 100) * len;
    r = (r / 100) * len;
    l = parseInt(l.toString());
    r = parseInt(r.toString());
    return conversationVolumeData.slice(l, r);
  };

  const [zoomedConversationVolumeData, setZoomedConversationVolumeData] =
    useState<IdValueName[]>(
      sliceConversationVolumeData(
        defaultDateSliderValue[0],
        defaultDateSliderValue[1]
      )
    );

  return (
    <Box
      sx={{
        ...blueBoxBorder,
        borderColor: alpha(theme.palette.info.light, 0.1),
      }}
    >
      <TimelineTitle />
      <Box sx={{ height: '8px' }}></Box>

      <ConversationVolumeBarChart
        chartData={conversationVolumeData}
        barColor={'#C1CDD4'}
        backgroundColor={'#FFF8F2'}
        chartHeight={40}
      />

      <AirbnbSlider
        sx={{ position: 'relative', top: '-34px' }}
        slots={{ thumb: AirbnbThumbComponent }}
        getAriaLabel={(index) =>
          index === 0 ? 'Minimum price' : 'Maximum price'
        }
        defaultValue={defaultDateSliderValue}
        onChangeCommitted={(_, value) => {
          let [l, r] = value as number[];
          setZoomedConversationVolumeData(sliceConversationVolumeData(l, r));
        }}
      />

      <Box sx={{ border: 4, borderColor: '#79c3e8', marginTop: '-34px' }}>
        <ConversationVolumeBarChart
          chartData={zoomedConversationVolumeData}
          backgroundColor={'#fff'}
          barColor={alpha('#758B96', 0.6)}
          chartHeight={230}
        />
      </Box>

      <Box sx={{ height: '32px' }}></Box>

      <AvatarMessages />

      <Stack direction={'row'} spacing={2}>
        <ConversationAndVolumeChart />
        <ConversationAndVolumeChart />
        <ConversationAndVolumeChart />
      </Stack>
    </Box>
  );
};

export default TimelineSection;
