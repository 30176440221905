import ScheduleIcon from '@mui/icons-material/Schedule';
import { HStack } from '../structures/Stacks';
import { alpha, Box } from '@mui/material';
import { lxColors } from '../../../utils/LxColors';
import { Fragment } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

type RatingIconsProps = {
  rating: number;
  maxRating?: number;

  variant: 'history' | 'mail' | 'calendar_checkmark';
};

const RatingIcons = ({ variant, rating, maxRating = 5 }: RatingIconsProps) => {
  const { color } = lxColors.icons.defaultStyle;

  const getIcon = () => {
    switch (variant) {
      case 'history':
        return {
          filledIcon: <ScheduleIcon sx={{ fontSize: 19, color: color }} />,
          emptyIconStyle: {
            width: 16,
            height: 12,
            border: `2px solid ${alpha(color, 0.4)}`,
          },
        };
      case 'mail':
        return {
          filledIcon: <MailOutlineIcon sx={{ fontSize: 19, color: color }} />,
          emptyIconStyle: {
            width: 16,
            height: 12,
            border: `2px solid ${alpha(color, 0.4)}`,
          },
        };
      case 'calendar_checkmark':
        return {
          filledIcon: (
            <EventAvailableIcon sx={{ fontSize: 19, color: color }} />
          ),
          emptyIconStyle: {
            width: 16,
            height: 12,
            border: `2px solid ${alpha(color, 0.4)}`,
          },
        };
    }
  };

  const { filledIcon, emptyIconStyle } = getIcon();
  return (
    <HStack spacing={0.35}>
      <>
        {new Array(rating).fill(0).map((_, idx) => (
          <Fragment key={idx}>{filledIcon}</Fragment>
        ))}
        {new Array(maxRating - rating).fill(0).map((_, idx) => (
          <Box key={idx} sx={emptyIconStyle} />
        ))}
      </>
    </HStack>
  );
};
export default RatingIcons;
