import { Grid, Typography } from '@mui/material';
import { DocumentTypesData, MonthData } from './CalendarDataTypes';
import moment from 'moment';
import DayBox from './DayBox';

type MonthChartProps = {
  monthName: string;
  monthNumber: number;
  year: number;
  monthData: MonthData;
};

const configureDayBoxColor = (
  i: number,
  firstDateIndex: number,
  lastDateIndex: number,
  dayData: DocumentTypesData | undefined
): JSX.Element => {
  return dayData ? (
    <DayBox color={'primary.dark'} />
  ) : (
    <DayBox
      color={i >= firstDateIndex && i <= lastDateIndex ? '#dce3f7' : '#f2f7ff'}
    />
  );
};

const MonthChart = ({
  monthName,
  monthNumber,
  year,
  monthData,
}: MonthChartProps): JSX.Element => {
  const momentDate = moment(`${year}-${monthNumber}-01`);
  let firstDateIndex = momentDate.day() - 1;
  firstDateIndex = firstDateIndex < 0 ? 6 : firstDateIndex;
  const lastDateIndex = momentDate.daysInMonth() - 1 + firstDateIndex;

  return (
    <>
      <Typography
        fontSize={'10px'}
        sx={{
          mr: 'auto',
          ml: '2px',
          color: '#858585',
          textDecoration: 'none solid rgb(133, 133, 133);',
          letterSpacing: '0.15px',
        }}
      >
        {monthName}
      </Typography>
      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        columns={7}
        spacing={0.2}
      >
        {Array.from({ length: lastDateIndex >= 35 ? 42 : 35 }).map((_, i) => {
          return (
            <Grid item xs={1} key={i}>
              {configureDayBoxColor(
                i,
                firstDateIndex,
                lastDateIndex,
                monthData[i + 1 - firstDateIndex]
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MonthChart;
