import { Button, CircularProgress } from '@mui/material';
import {
  BaseDefaultColumnStyle,
  MetricsTableCol,
} from '../../../components/metricstable/MetricsTableTypes';
import { VStack } from '../../../features/common/structures/Stacks';
import {
  ActorRoleMap,
  numGoogleIntegrationInfos,
  numMsftIntegrationInfos,
  numPrivacyRules,
  numSlackIntegrationInfos,
  Org,
  orgEnabled,
  OrgSettings,
} from '../../../utils/types/Entities';
import { UpdatedMetricsTableCol } from './tablecolumn/UpdatedGridColumn';
import LxChip from '../../../features/common/chips/LxChip';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import useFetch from '../../../utils/data/useFetch';

const ORG_SETTINGS_URL = '/api/debug/orgcrud/orgsettings';
const ORG_ACTORS_URL = '/api/debug/org/actors';
const MAX_ACTORS_SHOWN = 3;

type OrgCallback = (org: Org) => any;

const OrgGridColumn = ({
  orgid,
  orghandle,
}: {
  orgid: number;
  orghandle: string;
}) => {
  return (
    <LxTypography variant={LxTypographyTypes.DATA_1}>
      {`${orgid}: ${orghandle}`}
    </LxTypography>
  );
};

const IntegrationsGridColumn = ({
  orgid,
  refresh,
}: {
  orgid: number;
  refresh: number;
}) => {
  const { data: orgSettings, isLoading } = useFetch<OrgSettings>(
    `${ORG_SETTINGS_URL}/${orgid}`,
    [refresh]
  );

  return isLoading ? (
    <LxTypography variant={LxTypographyTypes.DATA_1}>
      {'Loading integrations ...'}
      <CircularProgress size={15} />
    </LxTypography>
  ) : (
    <VStack>
      <LxTypography variant={LxTypographyTypes.DATA_1}>
        {`${orgSettings ? numMsftIntegrationInfos(orgSettings) : 0} msft, ${
          orgSettings ? numSlackIntegrationInfos(orgSettings) : 0
        } slack, ${
          orgSettings ? numGoogleIntegrationInfos(orgSettings) : 0
        } google`}
      </LxTypography>
      <LxTypography variant={LxTypographyTypes.DATA_1}>
        {`${orgSettings ? numPrivacyRules(orgSettings) : 0} privacy rules`}
      </LxTypography>
    </VStack>
  );
};

const truncatedActorsString = (actors: string[]) => {
  const numActors = actors.length;
  let actorsString = actors.slice(0, MAX_ACTORS_SHOWN).join(', ');
  if (numActors > MAX_ACTORS_SHOWN) {
    actorsString += ` and ${numActors - MAX_ACTORS_SHOWN} more`;
  }
  return actorsString;
};

const ActorsGridColumn = ({
  orgid,
  refresh,
}: {
  orgid: number;
  refresh: number;
}) => {
  const { data: actorRoleMap, isLoading } = useFetch<ActorRoleMap>(
    `${ORG_ACTORS_URL}?orgid=${orgid}`,
    [refresh]
  );
  const actors = Object.keys(actorRoleMap ?? {});

  return isLoading ? (
    <LxTypography variant={LxTypographyTypes.DATA_1}>
      {'Loading actors ...'}
      <CircularProgress size={15} />
    </LxTypography>
  ) : (
    <VStack>
      <LxTypography variant={LxTypographyTypes.DATA_1}>
        {`${actors.length} actors have access`}
      </LxTypography>
      <LxTypography
        variant={LxTypographyTypes.DATA_1}
        sx={{ whiteSpace: 'normal' }}
      >
        {truncatedActorsString(actors)}
      </LxTypography>
    </VStack>
  );
};

const StatusGridColumn = ({
  enabled,
  dcRegion,
}: {
  enabled: boolean;
  dcRegion: string;
}) => {
  return (
    <VStack>
      <LxChip
        variant={'primary'}
        label={enabled ? 'ENABLED' : 'DISABLED'}
        size={'small'}
        outlined
      />
      <LxTypography
        variant={LxTypographyTypes.DATA_1}
      >{`${dcRegion}`}</LxTypography>
    </VStack>
  );
};

const EditGridColumn = ({
  org,
  onEdit,
  onEditSettings,
}: {
  org: Org;
  onEdit: OrgCallback;
  onEditSettings: OrgCallback;
}) => {
  return (
    <>
      <VStack>
        <Button variant="contained" onClick={() => onEdit(org)} fullWidth>
          Edit
        </Button>
        <Button
          variant="contained"
          onClick={() => onEditSettings(org)}
          fullWidth
        >
          Edit Settings
        </Button>
      </VStack>
    </>
  );
};

export type AdminOrgsGridColumnProps = {
  refresh: number;
  onEdit: OrgCallback;
  onEditSettings: OrgCallback;
};

export const AdminOrgsGridColumns = ({
  refresh,
  onEdit,
  onEditSettings,
}: AdminOrgsGridColumnProps): MetricsTableCol[] => [
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Org',
    field: 'orgid',
    sortable: true,
    renderCell: ({ row: org }: { row: Org }) => {
      return <OrgGridColumn orgid={org.orgid} orghandle={org.orghandle} />;
    },
    maxWidth: 150,
  },
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Integrations',
    field: 'integrations',
    renderCell: ({ row: org }: { row: Org }) => {
      return <IntegrationsGridColumn orgid={org.orgid} refresh={refresh} />;
    },
    maxWidth: 175,
    align: 'center',
  },
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Actors',
    field: 'actors',
    renderCell: ({ row: org }: { row: Org }) => {
      return <ActorsGridColumn orgid={org.orgid} refresh={refresh} />;
    },
    maxWidth: 175,
    align: 'center',
  },
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ row: org }: { row: Org }) => {
      const enabled = orgEnabled(org);
      return <StatusGridColumn enabled={enabled} dcRegion={org.dcRegion} />;
    },
    maxWidth: 100,
    align: 'center',
  },
  UpdatedMetricsTableCol,
  {
    ...BaseDefaultColumnStyle,
    headerName: 'Edit',
    field: 'edit',
    renderHeader: () => <></>,
    renderCell: ({ row: org }: { row: Org }) => {
      return (
        <EditGridColumn
          org={org}
          onEdit={onEdit}
          onEditSettings={onEditSettings}
        />
      );
    },
    maxWidth: 175,
    align: 'center',
  },
];
