import { ResponsiveLine, Serie } from '@nivo/line';

const getMonth = (value: number) => {
  switch (value) {
    case 1:
      return 'J';
    case 2:
      return 'F';
    case 3:
      return 'M';
    case 4:
      return 'A';
    case 5:
      return 'M';
    case 6:
      return 'J';
    case 7:
      return 'J';
    case 8:
      return 'A';
    case 9:
      return 'S';
    case 10:
      return 'O';
    case 11:
      return 'N';
    case 12:
      return 'D';
  }
  return 'N/A';
};

type SparkLineChartProps = {
  data: Serie[];
  lineColor: string;
};

export const SparkLineChart = ({ data, lineColor }: SparkLineChartProps) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 5,
        right: 20,
        bottom: 20,
        left: 20,
      }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (value) => {
          return getMonth(value);
        },
        tickSize: 0,
        tickPadding: 5,
      }}
      colors={lineColor}
      axisLeft={null}
      pointSize={10}
      enableGridX={false}
      enableGridY={false}
      isInteractive={false}
      enablePoints={false}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
    />
  );
};
