import { cloneDeep } from 'lodash';
import {
  ControlElement,
  JsonSchema,
  JsonSchema4,
  JsonSchema7,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';

type JsonSchema4Properties = {
  [property: string]: JsonSchema4;
};

type JsonSchema7Properties = {
  [property: string]: JsonSchema7;
};

type JsonSchemaProperties = JsonSchema4Properties | JsonSchema7Properties;

type JsonSchemaItemProperties = {
  [property: string]: JsonSchema4;
};

type ObjectValue = boolean | number | string | object;

type StringToObjectMap = {
  [key: string]: StringToObjectMap | ObjectValue | ObjectValue[];
};

type StringToObjectArrayMap = {
  [key: string]: StringToObjectMap[];
};

export const EmptyJsonSchema: JsonSchema = { type: 'object', properties: {} };

export const AUX_COLUMN_FIELDS: string[] = [
  'extraShort',
  'extraInt',
  'extraBin',
  'data',
  'dbUpdateBy',
  'dbInsertTimeEpochMs',
  'enabled',
];

/**
 * Pre-process JSON schema (see: https://json-schema.org/specification.html)
 * for use by JsonForms.
 */
export function preProcessSchema(
  schema: JsonSchema,
  removeSchemaFields: string[] | undefined = []
): JsonSchema {
  let preprocessed = cloneDeep(schema);
  // Remove the $schema key which JsonForms doesn't ignore.
  !('$schema' in preprocessed) || delete preprocessed['$schema'];
  // Field labels (for frontend controls) will be the same as property names.
  const properties = preprocessed?.properties;
  if (properties) {
    setPropertyTitles(properties);
    removeProperties(properties, removeSchemaFields);
  }
  return preprocessed;
}

export function forEachSchemaProperty(
  callbackFn: (key: string, value: any) => any,
  schema?: JsonSchema
) {
  for (let [key, value] of Object.entries(schema?.properties ?? {})) {
    callbackFn(key, value);
  }
  if (schema?.additionalProperties) {
    const additionalProperties = schema?.additionalProperties as JsonSchema;
    for (let [key, value] of Object.entries(
      additionalProperties?.properties ?? {}
    )) {
      callbackFn(key, value);
    }
  }
}

function addAllProperties(
  properties: JsonSchemaProperties,
  schema: JsonSchema
) {
  forEachSchemaProperty((key, value) => {
    properties[key] = value;
  }, schema);
  return properties;
}

function setPropertyTitles(properties: JsonSchemaProperties) {
  for (let key of Object.keys(properties)) {
    properties[key].title = key;
  }
}

function removeProperties(properties: JsonSchemaProperties, fields: string[]) {
  for (let key of Object.keys(properties)) {
    if (fields.includes(key)) {
      delete properties[key];
    }
  }
}

export function extractStringProperties(
  initialData: StringToObjectMap,
  schema: JsonSchema,
  extraRemoveProps: string[] = []
): [StringToObjectMap, JsonSchema] {
  const removeProps: string[] = extraRemoveProps.slice();
  const data: StringToObjectMap = {};
  // Only add string properties to the data object, all other properties are
  // removed from the schema;
  forEachSchemaProperty((key, value: JsonSchema) => {
    if (value?.type !== 'string') {
      removeProps.push(key);
    }

    if (initialData && initialData[key]) {
      data[key] = initialData[key];
    }
  }, schema);
  return [data, preProcessSchema(schema, removeProps)];
}

/**
 * Convert JsonSchema for use by JsonForms as a list with detail view.
 * See: https://jsonforms.io/examples/list-with-detail
 */
export class ListWithDetailSchemaUtils {
  static schema(
    attributeName: string,
    keyName: string,
    schema?: JsonSchema
  ): JsonSchema {
    if (schema?.properties) {
      if (schema?.properties[attributeName]) {
        let attributeProps: JsonSchemaItemProperties = {};
        attributeProps[keyName] = {
          type: 'string',
        };
        addAllProperties(attributeProps, schema?.properties[attributeName]);
        setPropertyTitles(attributeProps);
        const attributeSchema: JsonSchema = {
          type: 'array',
          title: attributeName,
          items: {
            type: 'object',
            properties: attributeProps,
            required: [keyName],
          },
        };
        let properties: JsonSchema4Properties = {};
        properties[attributeName] = attributeSchema;
        return {
          type: 'object',
          properties,
        };
      }
    }
    return EmptyJsonSchema;
  }

  static uiSchema(
    attributeName: string,
    keyName: string,
    schema?: JsonSchema
  ): UISchemaElement {
    const layout: VerticalLayout = {
      type: 'VerticalLayout',
      elements: [],
    };

    // Add key field first.
    layout.elements.push({
      type: 'Control',
      scope: `#/properties/${keyName}`,
      label: keyName,
      options: {
        focus: true,
      },
    } as ControlElement);

    if (schema?.properties && schema?.properties[attributeName]) {
      this.addControls(schema?.properties[attributeName], keyName, layout);
    }

    return {
      type: 'ListWithDetail',
      scope: `#/properties/${attributeName}`,
      options: {
        detail: layout,
      },
    } as UISchemaElement;
  }

  private static addControls(
    schema: JsonSchema,
    keyName: string,
    layout: VerticalLayout
  ) {
    forEachSchemaProperty((key, value) => {
      if (key !== keyName) {
        layout.elements.push({
          type: 'Control',
          scope: `#/properties/${key}`,
          label: key,
        } as ControlElement);
      }
    }, schema);
  }

  /**
   * Convert data from object to array for display in list with detail view.
   * Ex.
   * {
   *  "attribute": {
   *    "key1": { ...object1 }
   *    "key2": { ...object2 }
   *  }
   * }
   *  =>
   *  {
   *    "attribute": [
   *      { "key": "key1", ...object1 }
   *      { "key": "key2", ...object2 }
   *    ]
   *  }
   */
  static toJsonFormsData(
    attributeName: string,
    keyName: string,
    obj?: StringToObjectMap
  ): StringToObjectArrayMap {
    const data: StringToObjectArrayMap = {};
    if (obj && obj[attributeName]) {
      data[attributeName] = this.objectToArray(
        obj[attributeName] as StringToObjectMap,
        keyName
      );
    } else {
      data[attributeName] = [];
    }
    return data;
  }

  private static objectToArray(obj: StringToObjectMap, keyName: string) {
    const dataObjs: StringToObjectMap[] = [];
    for (let [key, value] of Object.entries(obj)) {
      const dataObj = cloneDeep(value as StringToObjectMap);
      dataObj[keyName] = key;
      dataObjs.push(dataObj);
    }
    return dataObjs;
  }

  /**
   * Convert data back from array (for JsonForms) to object for sending back to API.
   * Additionally returns a flag indicating whether there are missing or duplicate keys.
   * Ex.
   * {
   *    "attribute": [
   *      { "key": "key1", ...object1 }
   *      { "key": "key2", ...object2 }
   *    ]
   *  }
   * =>
   * {
   *  "attribute": {
   *    "key1": { ...object1 }
   *    "key2": { ...object2 }
   *  }
   * }
   */
  static fromJsonFormsData(
    attributeName: string,
    keyName: string,
    data?: StringToObjectArrayMap
  ): [StringToObjectMap, boolean] {
    const obj: StringToObjectMap = {};
    obj[attributeName] = {};
    if (data && data[attributeName]) {
      const [dataObj, hasErrors] = this.arrayToObject(
        data[attributeName],
        keyName
      );
      obj[attributeName] = dataObj;
      return [obj, hasErrors];
    }
    return [obj, false];
  }

  private static arrayToObject(
    array: StringToObjectMap[],
    keyName: string
  ): [StringToObjectMap, boolean] {
    const obj: StringToObjectMap = {};
    let hasErrors = false;
    for (let dataObj of array) {
      const key = dataObj[keyName] as string;
      if (key || key === '') {
        if (!(key in obj)) {
          const dataObjCopy: StringToObjectMap = cloneDeep(dataObj);
          delete dataObjCopy[keyName];
          obj[key] = dataObjCopy;
        } else {
          // Duplicate key.
          hasErrors = true;
        }
      } else {
        // Missing key.
        hasErrors = true;
      }
    }
    return [obj, hasErrors];
  }
}

/**
 * JsonForms schema types.
 */

export type ActorSchema = {
  properties: {
    settings: JsonSchema;
  };
};

export type OrgSchema = JsonSchema;

export type OrgSettingsSchema = JsonSchema;
