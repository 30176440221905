import { Box, SxProps, Theme, Typography } from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import LxCard from '../../features/common/card/LxCard';
import { lxFonts } from '../../utils/LxFonts';
import { MetricsTableCol, rowData } from './MetricsTableTypes';
import CustomPopper from '../../features/common/buttons/CustomPopper';
import useMetricsTablePopper from './MetricsTablePopper';
import Error from '../error/Error';

//TODO add pagination for v6
type MetricsTableProps = {
  loading: boolean;
  colDef: MetricsTableCol[];
  rows: rowData[] | undefined | null;
  rowCount: number | undefined | null;
  initialSort?: GridSortModel;
  rowHeight?: number;
  getRowId?: (row: any) => any;
  error: boolean;
  errorMessage: string;
  page?: number;
  setPage?: (page: number) => void;
  pageSize?: number;
  setPageSize?: (size: number) => void;
  containerSx?: SxProps<Theme>;
  gridSx?: SxProps<Theme>;
};

const MetricsTable = ({
  loading,
  colDef,
  rows,
  rowCount,
  initialSort,
  rowHeight,
  getRowId,
  error,
  errorMessage,
  containerSx,
  gridSx,
}: MetricsTableProps): JSX.Element => {
  const {
    anchorEl,
    headerName,
    value,
    usePre,
    handlePopperOpen,
    handlePopperClose,
  } = useMetricsTablePopper(colDef, rows);
  const popperValue = value === '' ? 'Empty String' : value ?? 'Null';

  return (
    <LxCard
      centerChildren={true}
      sx={containerSx ?? { height: 386.5, width: 1 }}
    >
      {error ? (
        <Error message={errorMessage} />
      ) : (
        <>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: {
                sortModel: initialSort ?? [],
              },
            }}
            rowHeight={rowHeight ?? 48}
            rows={rows ?? []}
            rowCount={rowCount ? rows?.length : 0}
            getRowId={getRowId}
            loading={loading}
            pageSizeOptions={[5, 10, 25]}
            columns={colDef}
            slotProps={{
              cell: {
                onMouseEnter: handlePopperOpen,
                onMouseLeave: handlePopperClose,
              },
            }}
            sx={gridSx}
            autoHeight
          />
          <CustomPopper
            anchorEl={anchorEl}
            onClickAway={handlePopperClose}
            placement={'top'}
          >
            <Typography
              fontFamily={lxFonts.SYSTEM}
              fontSize={16}
              fontWeight={'500'}
              sx={{ mr: 'auto' }}
            >
              {headerName}:
            </Typography>
            {usePre ? (
              <Box component={'pre'} textAlign={'left'}>
                {popperValue}
              </Box>
            ) : (
              <Typography
                fontFamily={lxFonts.SYSTEM}
                textAlign={'center'}
                p={1}
              >
                {popperValue}
              </Typography>
            )}
          </CustomPopper>
        </>
      )}
    </LxCard>
  );
};

export default MetricsTable;
