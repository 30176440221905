// how to use generics in a fetch call
import { DependencyList, useEffect, useState } from 'react';

type UseFetch<T> = {
  data: T | undefined;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  isError: boolean;
  errorMessage: string;
};

const useFetch = <T,>(
  endPoint: string,
  dependencies: DependencyList = [],
  pageOptions: [number, number] = [0, 5]
): UseFetch<T> => {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(pageOptions[0]);
  const [pageSize, setPageSize] = useState(pageOptions[1]);

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, ...pageOptions]);

  useEffect(() => {
    isLoading &&
      (async () => {
        await fetch(endPoint)
          .then(async (res) => {
            if (res.ok) {
              const jsonData = await res.json();
              setData(jsonData);
            } else {
              setIsError(true);
              setErrorMessage(`The response has status of ${res.status}`);
            }
          })
          .catch((error: Error) => {
            setIsError(true);
            setErrorMessage(error.message);
          });
        setIsLoading(false);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return {
    data,
    isLoading,
    isError,
    errorMessage,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export default useFetch;
