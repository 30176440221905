import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { Avatar, Box } from '@mui/material';
import { HStack } from '../structures/Stacks';
import { lxColorsNew } from '../../../utils/LxColors';
import LxTypography from '../../../components/lxTypography/LxTypography';
import { LxTypographyTypes } from '../../../components/lxTypography/lxTypographyStyles';
import { LxSize } from '../../../utils/LxGenericTypes';
import { SvgIconComponent } from '@mui/icons-material';

type Variant = 'title' | 'bordered' | LxSize;

type LxAvatarProps = {
  label: string;
  variant: Variant;
  avatar: string | SvgIconComponent;
  preLabel?: string;
  postLabel?: string;
  filled?: boolean;
  sizePercentage?: number;
  sx?: SxProps<Theme>;
};

type AvatarStyles = {
  typography: LxTypographyTypes;
  boxStyles: SxProps<Theme>;
  avatarStyles: SxProps<Theme>;
};

const getAvatarStyles = (lxAvatarProps: LxAvatarProps): AvatarStyles => {
  const { variant, sizePercentage } = lxAvatarProps;
  switch (variant) {
    case 'title':
      return {
        typography: LxTypographyTypes.AVATAR_TITLE,
        boxStyles: {
          height: 36,
          width: 36,
          border: 1,
          borderRadius: '100%',
          color: lxColorsNew.grey.light,
        },
        avatarStyles: {
          height: 0.75,
          width: 0.75,
        },
      };
    case 'large':
      return {
        typography: LxTypographyTypes.AVATAR_LARGE,
        boxStyles: {
          height: 68,
          width: 68,
          border: 1,
          borderRadius: '100%',
          color: lxColorsNew.grey.light,
        },
        avatarStyles: {
          height: 0.75,
          width: 0.75,
        },
      };
    case 'medium':
      return {
        typography: LxTypographyTypes.AVATAR_MEDIUM,
        boxStyles: {
          height: 55,
          width: 55,
          //Credits: https://dev.to/afif/border-with-gradient-and-radius-387f
          position: 'relative',
          '::before': {
            borderRadius: '100%',
            content: '""',
            position: 'absolute',
            inset: 0,
            border: '4px solid transparent',
            background: lxColorsNew.chip.secondary.backgroundImage,
            mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            maskComposite: 'xor',
          },
        },
        avatarStyles: {
          height: 0.7,
          width: 0.7,
          color: lxColorsNew.blue.dark,
        },
      };
    case 'small':
      return {
        typography: LxTypographyTypes.AVATAR_SMALL,
        boxStyles: {
          height: 15,
          width: 15,
        },
        avatarStyles: {
          height: 1,
          width: 1,
        },
      };
    case 'bordered':
      return {
        typography: LxTypographyTypes.AVATAR_SMALL,
        boxStyles: {
          height: 15,
          width: 15,
          borderRadius: '100%',
          backgroundColor: lxColorsNew.orange.regular,
        },
        avatarStyles: {
          height: sizePercentage,
          width: sizePercentage,
        },
      };
  }
};

// All avatars should just have an src or svg component
export const LxAvatar = (lxAvatarProps: LxAvatarProps) => {
  const {
    label,
    variant,
    preLabel,
    postLabel,
    avatar: AvatarIcon,
    filled = false,
    sx,
  } = lxAvatarProps;

  const styles = getAvatarStyles(lxAvatarProps);

  const showPreLabel = preLabel && variant === 'small';
  const showPostLabel =
    postLabel && (variant === 'small' || variant === 'bordered');

  return (
    <HStack
      spacing={0.5}
      sx={{
        width: 'fit-content',
        backgroundImage: filled
          ? lxColorsNew.chip.secondary.backgroundImage
          : undefined,
        ...sx,
      }}
    >
      {showPreLabel && (
        <LxTypography variant={LxTypographyTypes.AVATAR_LABEL}>
          {preLabel}
        </LxTypography>
      )}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={styles.boxStyles}
      >
        {typeof AvatarIcon === 'string' ? (
          // src link
          <Avatar src={AvatarIcon} sx={styles.avatarStyles} />
        ) : (
          // svg component
          <AvatarIcon sx={styles.avatarStyles} />
        )}
      </Box>
      <LxTypography variant={styles.typography}>{label}</LxTypography>
      {showPostLabel && (
        <LxTypography variant={LxTypographyTypes.AVATAR_LABEL}>
          {postLabel}
        </LxTypography>
      )}
    </HStack>
  );
};

export default LxAvatar;
