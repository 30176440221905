import { Stack, Typography } from '@mui/material';
import {
  ChurnRiskChip,
  GainSightChip,
  OrangeChip,
} from '../../features/common/LxChips';
import { HStack } from '../../features/common/structures/Stacks';
import { Employee } from '../../utils/types/Entities';
import { lxFonts } from '../../utils/LxFonts';
import { LxAvatar } from '../../features/common/avatars/LxAvatar';

type CompanyHeaderProps = {
  companyName: string;
  companyLogo: string;
  companyWebsite: string;
  churnRisk: string;
  salesForce?: boolean;
  gainSight?: boolean;
  quickBooks?: boolean;
  contacts?: Employee[];
};

const CompanyHeader = ({
  companyName,
  companyLogo,
  companyWebsite,
  churnRisk,
  salesForce = false,
  gainSight = false,
  quickBooks = false,
  contacts = [],
}: CompanyHeaderProps): JSX.Element => {
  return (
    <Stack direction={'column'} mt={2}>
      <HStack alignItems={'center'}>
        <Stack
          border={1}
          borderRadius={'100%'}
          borderColor={'#858585'}
          p={0.5}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img src={companyLogo} height={28} width={28} alt="Company Logo" />
        </Stack>
        <Typography
          fontSize={30}
          textTransform={'capitalize'}
          sx={{ ml: 1, fontColor: '#424242', fontFamily: lxFonts.SYSTEM }}
        >
          {companyName}
        </Typography>
      </HStack>
      <HStack sx={{ ml: 6 }} alignItems={'center'}>
        <OrangeChip
          sx={{
            border: 2,
            borderColor: 'primary.main',
            borderRadius: 1.5,
          }}
          label="Customer"
          variant="filled"
        />
        <ChurnRiskChip churnRisk={churnRisk} />
        {gainSight ? <GainSightChip /> : <></>}
        {salesForce ? <GainSightChip /> : <></>}
        {quickBooks ? <GainSightChip /> : <></>}
        {contacts.map((contact) => {
          return (
            <LxAvatar
              variant={'small'}
              label={contact.name}
              avatar={contact.avatarSrc}
            />
          );
        })}
        <Typography
          sx={{ color: 'secondary.main', fontSize: 14, ml: 1 }}
          textTransform={'lowercase'}
          variant={'subtitle1'}
        >
          {companyWebsite}
        </Typography>
      </HStack>
    </Stack>
  );
};

export default CompanyHeader;
