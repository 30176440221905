import { SankeyChart } from '../common/charts/SankeyChart';
import { Box, Button, Modal } from '@mui/material';
import { useRef, useState } from 'react';
import SankeyModal from '../../pages/pagecomponents/accountintelligence/SankeyModal';

type PeopleConnectionSankeyChartProps = {};

const PeopleConnectionSankeyChart = (
  props: PeopleConnectionSankeyChartProps
) => {
  const [openModal, setOpenModal] = useState(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const boxRef = useRef<HTMLDivElement>(null);
  return (
    <div id={'mydiv'} ref={boxRef} style={{ position: 'relative' }}>
      <Button onClick={() => setOpenModal(true)}>Click me</Button>
      <Box
        sx={{
          height: 500,
          position: 'relative',
        }}
      >
        {/*<Button onClick={handleOpen}>Open modal</Button>*/}
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          container={() => boxRef.current!}
          sx={{ position: 'absolute' }}
          slotProps={{ backdrop: { style: { position: 'absolute' } } }}
        >
          <Box sx={style}>
            <SankeyModal />
          </Box>
        </Modal>

        <SankeyChart
          data={{
            nodes: [
              {
                id: 'John',
                nodeColor: 'hsl(67, 70%, 50%)',
              },
              {
                id: 'Jane',
                nodeColor: 'hsl(246, 70%, 50%)',
              },
              {
                id: 'Junko',
                nodeColor: 'hsl(345, 70%, 50%)',
              },
              {
                id: 'Raoul',
                nodeColor: 'hsl(202, 70%, 50%)',
              },
              {
                id: 'Marcel',
                nodeColor: 'hsl(220, 70%, 50%)',
              },
              {
                id: 'Ibrahim',
                nodeColor: 'hsl(139, 70%, 50%)',
              },
            ],
            links: [
              {
                source: 'John',
                target: 'Ibrahim',
                value: 180,
              },
              {
                source: 'John',
                target: 'Raoul',
                value: 56,
              },
              {
                source: 'John',
                target: 'Marcel',
                value: 200,
              },
              {
                source: 'Jane',
                target: 'Raoul',
                value: 17,
              },
              {
                source: 'Jane',
                target: 'Marcel',
                value: 37,
              },
              {
                source: 'Jane',
                target: 'Ibrahim',
                value: 21,
              },
              {
                source: 'Junko',
                target: 'Raoul',
                value: 144,
              },
              {
                source: 'Junko',
                target: 'Marcel',
                value: 104,
              },
            ],
          }}
        />
      </Box>
    </div>
  );
};
export default PeopleConnectionSankeyChart;
