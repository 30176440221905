import { Box, Chip, styled, SxProps, Theme, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import CloudIcon from '@mui/icons-material/Cloud';
import { HStack } from './structures/Stacks';
import HeartPulseIcon from '../../assets/heartPulse.svg';
import { PlatformType } from '../../utils/types/Entities';
import { lxColors, lxColorsNew } from '../../utils/LxColors';

export const OrangeChip = styled(Chip)(() => ({
  color: lxColorsNew.orange.regular,
  borderRadius: 3,
  backgroundColor: '#fff8f2',
}));

export const PurpleToBlueChip = styled(Chip)(() => ({
  background:
    'linear-gradient(to right, #f0f4ff, #eff6ff, #eef8ff, #effaff, #f0fcff)',
  borderRadius: 2,
  marginLeft: 2,
}));

type PlatformChipBaseProps = {
  platformName: string;
  Icon: SvgIconComponent;
  sx?: SxProps<Theme>;
};

export const PlatformChipBase = ({
  platformName,
  Icon,
  sx,
}: PlatformChipBaseProps): JSX.Element => {
  return (
    <HStack
      justifyContent={'center'}
      sx={{
        backgroundImage: 'linear-gradient(90deg, #f0f4ff, #f0fcff)',
        ...sx,
      }}
    >
      <Icon sx={{ color: lxColors.icons.label.color }} />
      <Typography color={lxColors.icons.label.color} fontSize={13}>
        {platformName}
      </Typography>
    </HStack>
  );
};

type PlatformChipProps = {
  platform: PlatformType;
  sx?: SxProps<Theme>;
};

export const PlatformChip = ({ platform, sx }: PlatformChipProps) => {
  return (
    <PlatformChipBase
      platformName={platform.name}
      Icon={platform.icon}
      sx={sx}
    />
  );
};

export const GainSightChip = (): JSX.Element => {
  return (
    <PurpleToBlueChip
      icon={<CloudIcon color="info" />}
      label={'View on GainSight'}
    />
  );
};

type ChurnRiskProps = {
  churnRisk: string;
};

export const ChurnRiskChip = ({ churnRisk }: ChurnRiskProps): JSX.Element => {
  return (
    <HStack sx={{ mx: 0.5 }}>
      <Box
        component="img"
        src={HeartPulseIcon}
        alt={'pulse heart'}
        sx={{
          aspectRatio: '1/1',
          height: 20,
        }}
      />
      <Typography
        sx={{ color: 'primary.main', fontSize: 14, ml: 1 }}
        variant={'subtitle1'}
      >
        Churn Risk: {churnRisk}
      </Typography>
    </HStack>
  );
};
