import { GridAlignment, GridColDef } from '@mui/x-data-grid';

export enum ColumnType {
  NUMBER,
  STRING,
  EPOCH_TIME,
  SERIALIZED_JSON,
}

export type DefaultColumnStyleType = {
  hideable: boolean;
  sortable: boolean;
  filterable: boolean;
  headerAlign: GridAlignment;
  align: GridAlignment;
  showHover: boolean;
  flex: number;
  columnType: ColumnType;
};

export const BaseDefaultColumnStyle: DefaultColumnStyleType = {
  hideable: false,
  sortable: false,
  filterable: false,
  showHover: false,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  columnType: ColumnType.STRING,
};

export const DefaultColumnStyle: DefaultColumnStyleType = {
  ...BaseDefaultColumnStyle,
  filterable: true,
  showHover: true,
};

// standard by the backend when returning a deserialized JSON
export const DESERIALIZED_DATA_KEY = 'deserializedJson';

export type rowData = {
  id: number | string;
  [key: string]: any;
};

export type MetricsTableCol = GridColDef & DefaultColumnStyleType;

export type UseMetricsTableData = {
  colDef: MetricsTableCol[];
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
};
