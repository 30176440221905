import { useCallback, useEffect, useState } from 'react';
import {
  OrgSchema,
  extractStringProperties,
} from '../../../../../utils/data/jsonSchema';
import { OrgSettings } from '../../../../../utils/types/Entities';
import { JsonSchema } from '@jsonforms/core';
import TabPanel from '../../../../../components/TabPanel';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';
import { CircularProgress } from '@mui/material';

// Don't show the "exp" field of SettingsJson which is only for testing.
const REMOVE_SCHEMA_FIELDS = ['exp'];

export type OrgSettingsChangeCallback = (
  orgSettings: OrgSettings,
  isValid: boolean
) => any;

export type OrgSettingsTabPanelProps = {
  orgSettings?: OrgSettings;
  orgSettingsSchema?: OrgSchema;
  tabIndex: number;
  tabIndexValue: number;
  onChange: OrgSettingsChangeCallback;
};

/**
 * Tab for editing simple "string" type properties in SettingsJson.
 */
const OrgSettingsTabPanel = ({
  orgSettings: initialOrgSettings,
  orgSettingsSchema,
  tabIndex,
  tabIndexValue,
  onChange,
}: OrgSettingsTabPanelProps) => {
  // TODO: Refactor processing of orgSettings & schema into a hook.
  const [orgSettings, setOrgSettings] = useState<OrgSettings>();
  const [schema, setSchema] = useState<JsonSchema>();

  const processSchema = useCallback(
    (schema: JsonSchema) => {
      if (initialOrgSettings) {
        const [data, processedSchema] = extractStringProperties(
          initialOrgSettings,
          schema,
          REMOVE_SCHEMA_FIELDS
        );
        setSchema(processedSchema);
        setOrgSettings(data as OrgSettings);
      }
    },
    [initialOrgSettings]
  );

  useEffect(() => {
    if (orgSettingsSchema) {
      processSchema(orgSettingsSchema);
    }
  }, [initialOrgSettings, orgSettingsSchema, processSchema]);

  return (
    <TabPanel value={tabIndexValue} index={tabIndex}>
      {schema ? (
        <JsonForms
          schema={schema}
          data={orgSettings}
          renderers={materialRenderers}
          onChange={({ data, errors }) => {
            const hasErrors = errors ? errors.length > 0 : false;
            setOrgSettings(data);
            onChange(data, !hasErrors);
          }}
        />
      ) : (
        <CircularProgress size={50} />
      )}
    </TabPanel>
  );
};

export default OrgSettingsTabPanel;
