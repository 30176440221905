import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import './App.css';
import Error from './pages/error';
import Root from './pages/roots/root';
import SignUpRoot from './pages/signup/signUpRoot';
import AppRoot from './pages/roots/appRoot';
import { AppPages, SignUpPages } from './routes/Pages';
import { LicenseInfo } from '@mui/x-license-pro';

// see react router 6.6: https://reactrouter.com/en/main/utils/create-routes-from-elements
// MUI Pro licence key
LicenseInfo.setLicenseKey(
  '96160f86f1468d6ccdda0dbdc5910b0dTz02NDczOSxFPTE3MTM0NjE0ODY5NjcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route element={<AppRoot />}>
        {AppPages.map((appPage, i) => (
          <Route
            index={i === 0}
            key={appPage.path}
            path={appPage.path}
            element={appPage.element}
          />
        ))}
      </Route>
      <Route element={<SignUpRoot />}>
        {SignUpPages.map((signUpPage, i) => (
          <Route
            path={signUpPage.path}
            key={signUpPage.path}
            element={signUpPage.element}
          />
        ))}
      </Route>
    </Route>
  )
);

const App = (): JSX.Element => {
  return <RouterProvider router={appRouter} />;
};

export default App;
