import { lxColorsNew } from '../../../utils/LxColors';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

export enum LxCardTypes {
  Navigation = 'Navigation',
  SearchResult = 'SearchResult',
  Data = 'Data',
  DataGreen = 'DataGreen',
  DataRed = 'DataRed',
  Orange = 'Orange',
  ModalOverlay = 'ModalOverlay',
  // Redacted = 'Redacted',
}

export const getLxCardStyle = (lxCardType: LxCardTypes): SxProps<Theme> => {
  switch (lxCardType) {
    case LxCardTypes.Navigation:
      return {
        boxShadow: lxColorsNew.box.navigation.boxShadow,
        borderRadius: '12px',
      };
    case LxCardTypes.SearchResult:
      return {
        border: 1,
        borderColor: lxColorsNew.box.searchResult.border,
        boxShadow: lxColorsNew.box.searchResult.boxShadow,
        borderRadius: '12px',
      };
    case LxCardTypes.Data:
      return {
        border: 1,
        borderColor: lxColorsNew.box.data.border,
        boxShadow: lxColorsNew.box.data.boxShadow,
      };
    case LxCardTypes.DataGreen:
      return {
        border: 1,
        borderColor: lxColorsNew.box.dataGreen.border,
        borderRadius: '2px',
        backgroundColor: lxColorsNew.box.dataGreen.backgroundColor,
      };
    case LxCardTypes.DataRed:
      return {
        border: 1,
        borderColor: lxColorsNew.box.dataRed.border,
        borderRadius: '2px',
        backgroundColor: lxColorsNew.box.dataRed.backgroundColor,
      };
    case LxCardTypes.Orange:
      return {
        borderRadius: '2px',
        backgroundColor: lxColorsNew.box.orange.backgroundColor,
      };
    case LxCardTypes.ModalOverlay:
      return {
        border: 1,
        borderColor: lxColorsNew.box.modalOverlay.border,
        boxShadow: lxColorsNew.box.modalOverlay.boxShadow,
        borderRadius: '4px',
      };
  }
};
