import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { lxColors } from '../utils/LxColors';
import { Employee } from '../utils/types/Entities';

type GroupAvatarProps = {
  people: Employee[];
  sx?: SxProps<Theme>;
};

const AvatarGroupStyled = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatar-colorDefault': {
    border: 0,
    color: theme.palette.primary.main,
    backgroundColor: lxColors.icons.background.color,
  },
}));

const GroupAvatars = ({ people, sx }: GroupAvatarProps): JSX.Element => {
  return (
    <AvatarGroupStyled max={11} sx={sx}>
      {people.map((person, i) => (
        <Avatar key={i} src={person.avatarSrc} alt={person.name} />
      ))}
    </AvatarGroupStyled>
  );
};

export default GroupAvatars;
