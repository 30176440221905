import { Employee } from '../../utils/types/Entities';
import LxCard from '../../features/common/card/LxCard';
import PeopleDescription from '../../features/common/PeopleDescription';
import { BadgedAvatar } from '../../features/common/avatars/LxAvatars';
import { ShareLevelEnum } from '../sharelevel/ShareLevelConstants';
import ConnectionCardDescription, {
  ConnectionCardDescriptionProps,
} from './ConnectionCardDescription';

export type ConnectionCardProps = {
  person: Employee;
  shareLevel: ShareLevelEnum;
  connectionCardDescriptionProps: ConnectionCardDescriptionProps;
};

const ConnectionCard = ({
  person,
  shareLevel,
  connectionCardDescriptionProps,
}: ConnectionCardProps): JSX.Element => {
  return (
    <LxCard sx={{ flexGrow: 1 }}>
      <PeopleDescription
        peopleInfo={{
          title: person.name,
          subtitle: person.title || '',
          byline: person.subTitle || '',
        }}
        avatar={<BadgedAvatar variant={shareLevel} person={person} />}
        infoBottom={
          <ConnectionCardDescription
            {...connectionCardDescriptionProps}
            sx={{ mt: 1 }}
          />
        }
      />
    </LxCard>
  );
};

export default ConnectionCard;
