import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type ProcessTelemetryInfo = {
  pid: number;
  host: string;
  javaVM: string;
  javaVMVersion: string;
  branch: string;
  commitHash: string;
  commitHashAbbrev: string;
  commitTime: number;
  buildHost: string;
  buildTime: number;
};
const unknownPi: ProcessTelemetryInfo = {
  pid: -1,
  host: 'unknown',
  javaVM: 'unknown',
  javaVMVersion: 'unknown',
  branch: 'unknown',
  commitHash: 'unknown',
  commitHashAbbrev: 'unknown',
  commitTime: 0,
  buildHost: 'unknown',
  buildTime: 0,
};

const RootFooter = (): JSX.Element => {
  const [piInfo, setPiInfo] = useState<ProcessTelemetryInfo>(unknownPi);
  const initFcn = async () => {
    let f = await fetch('/api/processtelemetryinfo');
    let o: ProcessTelemetryInfo = await f.json();

    setPiInfo(o);
  };
  useEffect(() => {
    initFcn();
  }, []);

  const getPrintInfo =
    piInfo == null ? null : (
      <>
        <div>
          {piInfo.branch}({piInfo.commitHashAbbrev}) built on{' '}
          {new Date(piInfo.buildTime) + ''}
        </div>
        <div>Rendered by {piInfo.host}</div>
      </>
    );

  return (
    <Typography
      sx={{ color: 'text.disabled', py: 2 }}
      fontSize={'small'}
      align={'center'}
    >
      {getPrintInfo}
    </Typography>
  );
};
export default RootFooter;
